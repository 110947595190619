import { DialogTitle, IconButton } from '@mui/material'
import { IconX } from '@tabler/icons-react'
import classes from '../style.module.scss'

type Props = {
  handleClose: () => unknown
  title: string | JSX.Element
}

function DialogHeader({ handleClose, title }: Props) {
  // TODO: Create header picker depending of needs, separate diferent headers to its own components
  return (
    <div className={classes.header}>
      <div className={classes.title}>
        <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
        {/* TODO: Ask what is going on here in desing */}
      </div>

      <IconButton
        size="medium"
        edge="start"
        color="inherit"
        onClick={handleClose}
        aria-label="close"
        sx={{
          padding: '16px',
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
          },
        }}
      >
        <IconX size="25px" color="gray" />
      </IconButton>
    </div>
  )
}

export default DialogHeader
