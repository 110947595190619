import { Backdrop, Box, Button, Fade, Modal, Typography } from '@mui/material'
import { IconCircleCheck } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
}

interface IpropsSuccessModal {
  successModalOpen: boolean
  setSuccessModalOpen: (value: boolean) => void
  handleClose: () => void
  message: string | JSX.Element
}

const SuccessModal: React.FC<IpropsSuccessModal> = ({
  handleClose,
  setSuccessModalOpen,
  successModalOpen,
  message,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={successModalOpen}
      onClose={() => {
        setSuccessModalOpen(false)
        handleClose()
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={successModalOpen}>
        <Box sx={modalStyle}>
          <IconCircleCheck color="green" />
          <Typography className="successMsg" variant="h2" component="h2">
            {message}
          </Typography>

          <Button
            variant="contained"
            onClick={() => {
              setSuccessModalOpen(false)
              handleClose()
            }}
            sx={{ mt: 2 }}
            color="primary"
          >
            <FormattedMessage id="close" />
          </Button>
        </Box>
      </Fade>
    </Modal>
  )
}

export default SuccessModal
