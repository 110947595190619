import React from 'react'
import { Paper } from '@mui/material'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import './styles.scss'

type FlexDirection =
  | 'column'
  | 'inherit'
  | '-moz-initial'
  | 'initial'
  | 'revert'
  | 'unset'
  | 'column-reverse'
  | 'row'
  | 'row-reverse'
  | undefined

type BoxWrapperProps = {
  children: string | JSX.Element | JSX.Element[]
  height?: string
  maxHeight?: { xs?: string; md?: string; xl?: string }
  header?: boolean
  title?: string | JSX.Element
  value?: number
  currency?: string
  padding?: string
  flexDirection?: FlexDirection
  sx?: any
}

interface GrowthIconProps {
  hasGrowth: boolean
  value?: number
}

const GrowthIcon: React.FC<GrowthIconProps> = ({ hasGrowth, value }) => {
  if (!value) {
    return <p style={{ fontSize: 14, color: '#757575', fontWeight: 500 }}>N/A</p>
  }
  return hasGrowth ? (
    <TrendingUpIcon className="stockArrowUp" />
  ) : (
    <TrendingDownIcon className="stockArrowDown" />
  )
}

const BoxWrapper = (props: BoxWrapperProps) => {
  const {
    children,
    title,
    header = false,
    value = 0,
    currency = '',
    height = 'initial',
    maxHeight = { xs: 'initial', md: 'initial', xl: 'initial' },
    padding = '16px 16px 20px 16px',
    flexDirection,
    sx,
  } = props
  const setFormat = value ? value.toLocaleString('de-DE') : '0,00'
  const formatNumber = currency ? setFormat : value
  const hasGrowth = true

  return (
    <Paper
      sx={{
        width: '100%',
        padding,
        height,
        maxHeight: { xs: maxHeight.xs, md: maxHeight.md, xl: maxHeight.xl },
        ...sx,
      }}
      className="boxWrapper"
    >
      {header && (
        <div
          className="boxHeader"
          style={{ flexDirection, alignItems: flexDirection && 'flex-start' }}
        >
          <h3>{title}</h3>
          <div className="growth">
            {!!currency && (
              <>
                <GrowthIcon hasGrowth={hasGrowth} value={value} />
                <p>{`${formatNumber} ${currency}`}</p>
              </>
            )}
          </div>
          {!currency && flexDirection && <p className="total">{value}</p>}
        </div>
      )}

      {children}
    </Paper>
  )
}

export default BoxWrapper
