import { useSelector } from '../../../store/store'
import CampaignRow from './Rows/campaignRow'
import CustomersRow from './Rows/customersRow'
import LeadsRow from './Rows/leadsRow'
import OrdersRow from './Rows/ordersRow'

interface RowRenderProps {
  rows: unknown[] | never[]
  checkbox: boolean
  isSelected: (id: string) => boolean
}

const RowRender = ({ rows = [], isSelected = () => false, checkbox = false }: RowRenderProps) => {
  const { type } = useSelector((state) => state.table)

  const Row = ({ labelId, row, isItemSelected, checkbox }) => {
    switch (type) {
      case 'campaign':
        return (
          <CampaignRow
            row={row}
            labelId={labelId}
            isItemSelected={isItemSelected}
            checkbox={checkbox}
          />
        )
      case 'lead':
        return (
          <LeadsRow
            row={row}
            labelId={labelId}
            isItemSelected={isItemSelected}
            checkbox={checkbox}
          />
        )
      case 'order':
        return (
          <OrdersRow
            row={row}
            labelId={labelId}
            isItemSelected={isItemSelected}
            checkbox={checkbox}
          />
        )
      case 'customer':
        return (
          <CustomersRow
            row={row}
            labelId={labelId}
            isItemSelected={isItemSelected}
            checkbox={checkbox}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {rows.map((row: any, index: number) => {
        const isItemSelected = isSelected(row.id)
        const labelId = `enhanced-table-checkbox-${index}`

        return (
          <Row
            key={index}
            row={row}
            labelId={labelId}
            isItemSelected={isItemSelected}
            checkbox={checkbox}
          />
        )
      })}
    </>
  )
}

export default RowRender
