import React, { useEffect, useState } from 'react'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded'
import GoogleIcon from '@mui/icons-material/Google'
import InstagramIcon from '@mui/icons-material/Instagram'
import RedditIcon from '@mui/icons-material/Reddit'
import './styles.scss'
import { useQuery } from '@apollo/client'
import queries from '../../../graphQL/query'

interface Socials {
  instagram: number
  facebook: number
}
const SocialStats = () => {
  const [socials, setSocials] = useState<Socials>({ instagram: 0, facebook: 0 })
  const { data } = useQuery(queries.instagram)

  const percentage = (value1: number, value2: number) => {
    return Math.round((100 * value1) / value2)
  }

  useEffect(() => {
    const instagram = data?.metaLeads.filter((item) => item.platform === 'ig').length
    const facebook = data?.metaLeads.filter((item) => item.platform === 'fb').length
    const total = data?.metaLeads.length

    setSocials({
      instagram: percentage(instagram, total),
      facebook: percentage(facebook, total),
    })
  }, [data])

  return (
    <div className="socialsStatsWrapper">
      <div className="social">
        <FacebookRoundedIcon className="icon" />
        <div className="stats">
          <span>{socials.facebook || 0} %</span>
          <p>Facebook</p>
        </div>
      </div>
      <div className="social">
        <GoogleIcon className="icon" />
        <div className="stats">
          <span>0 %</span>
          <p>Google</p>
        </div>
      </div>
      <div className="social">
        <InstagramIcon className="icon" />
        <div className="stats">
          <span>{socials.instagram || 0} %</span>
          <p>Instagram</p>
        </div>
      </div>
      <div className="social">
        <img src="/socials/spotify.svg" className="icon" />
        <div className="stats">
          <span>0 %</span>
          <p>Spotify</p>
        </div>
      </div>
      <div className="social">
        <img src="/socials/tiktok-monochrome.svg" className="icon" />
        <div className="stats">
          <span>0 %</span>
          <p>TikTok</p>
        </div>
      </div>
      <div className="social">
        <RedditIcon className="icon" />
        <div className="stats">
          <span>0 %</span>
          <p>Reddit</p>
        </div>
      </div>
    </div>
  )
}

export default SocialStats
