import { Chip, Typography, useTheme } from '@mui/material'
import commonCodebase from 'common-recruspin-codebase'
import './style.scss'

import { Ipermission } from 'common-recruspin-codebase/types/permission'
import { useIntl } from 'react-intl'

const { customerTableFieldsInDb, orderTableFieldsInDb, leadsAndTalentsTableFieldsInDb } =
  commonCodebase.permission

interface Iprops {
  permissions: Ipermission
}

const ReadPermissions = ({ permissions }: Iprops) => {
  const theme = useTheme()

  const Row = ({ label, type = 'default' }: { label?: string; type?: 'default' | 'subField' }) => {
    const isSubField = type === 'subField'

    if (!permissions || !label) return null
    return (
      <div className="permissionRow">
        <Typography
          style={{ marginLeft: isSubField ? '3%' : 'inherit' }}
          fontWeight={isSubField ? 300 : 500}
        >
          {label}
        </Typography>
        <Chip
          size="small"
          label={intl.formatMessage({ id: 'enabled' })}
          sx={{
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.secondary.dark,
            fontWeight: 'bold',
          }}
        />
      </div>
    )
  }
  const intl = useIntl()
  const instructionText = intl.formatMessage({ id: 'please-press-edit' })

  return (
    <div className="PermissionSettings">
      {permissions?.dashboard && (
        <>
          <div className="optionsContainer">
            <Typography className="desc" variant="body1">
              {intl.formatMessage({ id: 'dashboard-accessible-user' })}
              {instructionText}
            </Typography>
            <Row label={intl.formatMessage({ id: 'dashboard' })} />
          </div>
        </>
      )}
      {permissions?.orders && (
        <div className="optionsContainer">
          <Typography className="desc" variant="body1">
            {intl.formatMessage({ id: 'orders-for-user' })}
            {instructionText}
          </Typography>
          <Row label={intl.formatMessage({ id: 'orders' })} />
          {permissions.orders && permissions.ordersTable && (
            <Row label={intl.formatMessage({ id: 'orders-table' })} />
          )}
          {permissions.orders && permissions.ordersDetails && (
            <Row label={intl.formatMessage({ id: 'orders-details' })} />
          )}
          {permissions.orders &&
            permissions.ordersDetails &&
            permissions.orderDetailsFields?.length && (
              <div>
                {permissions.orderDetailsFields.map((field) => {
                  return (
                    <Row
                      type="subField"
                      key={field}
                      label={orderTableFieldsInDb.find((item) => item.value === field)?.label || ''}
                    />
                  )
                })}
              </div>
            )}
        </div>
      )}

      {permissions?.customers && (
        <div className="optionsContainer">
          <Typography className="desc" variant="body1">
            {intl.formatMessage({ id: 'customers-for-user' })}
            {instructionText}
          </Typography>
          <Row label={intl.formatMessage({ id: 'customers' })} />
          {permissions.customers && permissions.customersTable && (
            <Row label={intl.formatMessage({ id: 'customers-table' })} />
          )}
          {permissions.customers &&
            permissions.customersDetails &&
            permissions.customersDetailsFields?.length && (
              <Row label={intl.formatMessage({ id: 'customers-details' })} />
            )}
          {permissions.customers && permissions.customersDetails && (
            <div>
              {permissions.customersDetailsFields.map((field) => {
                return (
                  <Row
                    type="subField"
                    key={field}
                    label={customerTableFieldsInDb.find((item) => item.value === field)?.label}
                  />
                )
              })}
            </div>
          )}
        </div>
      )}
      {permissions?.campaigns && (
        <div className="optionsContainer">
          <Typography className="desc" variant="body1">
            {intl.formatMessage({ id: 'campaigns-for-user' })}
            {instructionText}
          </Typography>
          <Row label={intl.formatMessage({ id: 'campaigns' })} />
          {permissions.campaigns && permissions.campaignsTable && (
            <Row label={intl.formatMessage({ id: 'campaigns-table' })} />
          )}
          {permissions.campaigns && permissions.campaignsTable && (
            <Row label={intl.formatMessage({ id: 'campaigns-details' })} />
          )}
          <div>
            {permissions.campaignsDetailsFields.map((field) => {
              return (
                <Row
                  type="subField"
                  key={field}
                  label={customerTableFieldsInDb.find((item) => item.value === field)?.label}
                />
              )
            })}
          </div>
        </div>
      )}
      {permissions?.leadsAndTalents && (
        <div className="optionsContainer">
          <Typography className="desc" variant="body1">
            {intl.formatMessage({ id: 'leads-n-talents-for-user' })}
            {instructionText}
          </Typography>
          <Row label={intl.formatMessage({ id: 'leads-n-talents' })} />
          {permissions.leadsAndTalents && permissions.leadsAndTalentsDetailsFields?.length && (
            <div>
              {permissions.leadsAndTalentsDetailsFields.map((field) => {
                return (
                  <Row
                    type="subField"
                    key={field}
                    label={
                      leadsAndTalentsTableFieldsInDb.find((item) => item.value === field)?.label ||
                      ''
                    }
                  />
                )
              })}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ReadPermissions
