import { IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { IconTrash } from '@tabler/icons-react'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface Iprops {
  emails: string[]
  email: string
  setEmails: (value: string[]) => void

  defaultInvoiceEmail?: string
  setDefaultInvoiceEmail: (value: string) => void

  defaultPreviewEmail?: string
  setDefaultPreviewEmail: (value: string) => void

  defaultEmail?: string
  setDefaultEmail: (value: string) => void
}

const EditEmailRow: React.FC<Iprops> = ({
  email,
  emails,
  setEmails,
  defaultInvoiceEmail,
  setDefaultInvoiceEmail,
  setDefaultEmail,
  setDefaultPreviewEmail,
  defaultEmail,
  defaultPreviewEmail,
}) => {
  const theme = useTheme()

  const isInvoiceMail = email === defaultInvoiceEmail
  const isPreviewMail = email === defaultPreviewEmail
  const isDefaultMail = email === defaultEmail

  const removeItem = () => {
    const newEmails = emails.filter((i: string) => i !== email)
    setEmails(newEmails)
  }

  const handleDefaultMail = () => {
    if (defaultEmail !== email) {
      setDefaultEmail(email)
    } else {
      setDefaultEmail('')
    }
  }

  const handleDefaultInvoiceMail = () => {
    if (defaultInvoiceEmail !== email) {
      setDefaultInvoiceEmail(email)
    } else {
      setDefaultInvoiceEmail('')
    }
  }

  const handleDefaultPreviewMail = () => {
    if (defaultPreviewEmail !== email) {
      setDefaultPreviewEmail(email)
    } else {
      setDefaultPreviewEmail('')
    }
  }

  return (
    <div className="EditEmailRow">
      <div className="row">
        <div className="email">{email}</div>
        <div className="options">
          <div className="invoice rightBorder" onClick={handleDefaultInvoiceMail}>
            <Typography
              className="setItem"
              sx={isInvoiceMail ? { backgroundColor: theme.palette.info.dark } : {}}
              color={{ color: isInvoiceMail ? 'white' : theme.palette.text.primary }}
            >
              <FormattedMessage id="set-as-invoice" />
            </Typography>
          </div>
          <div className="preview rightBorder" onClick={handleDefaultPreviewMail}>
            <Typography
              sx={isPreviewMail ? { backgroundColor: theme.palette.info.dark } : {}}
              color={{ color: isPreviewMail ? 'white' : theme.palette.error.light }}
              className="setItem"
            >
              <FormattedMessage id="set-as-preview" />
            </Typography>
          </div>
          <div className="default rightBorder" onClick={handleDefaultMail}>
            <Typography
              sx={isDefaultMail ? { backgroundColor: theme.palette.info.dark } : {}}
              color={{ color: isDefaultMail ? 'white' : theme.palette.info.dark }}
              className="setItem"
            >
              <FormattedMessage id="set-as-default" />
            </Typography>
          </div>
          <Tooltip className="remove" title="Remove" onClick={removeItem}>
            <IconButton>
              <IconTrash stroke="none" color={theme.palette.error.dark} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default EditEmailRow
