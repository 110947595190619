import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react'
import useSWR from 'swr'
import { useMutation, useQuery } from '@apollo/client'
import * as yup from 'yup'
import { useFormik } from 'formik'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  AppBar,
  Autocomplete,
  Button,
  Chip,
  Container,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
  Link,
  Box,
  Tooltip,
  Menu,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { useParams, Link as RouterLink } from 'react-router-dom'
import commonCodebase from 'common-recruspin-codebase'
import { IconX } from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import FormErrorsModal from '../../../../ui-component/FormErrorsModal'
import SuccessModal from '../../../../ui-component/SuccessModal'
import { setTriggerOrdersRefetch } from '../../../../store/slices/general'
import queries from '../../../../graphQL/query'
import ReadOrder from '../ReadOrder'
import NoProvidedCustomerEmail from '../../../../ui-component/NoProvidedCustomerEmail'
import MultiImageEdit from '../../../../ui-component/multiImageEdit/MultiImageEdit'
import {
  Iassignee,
  Iselect,
  genders,
  allowedDocumentFileTypes,
  allowedImageFileTypes,
  allowedPreviewFileTypes,
  allowedVideoFileTypes,
} from '../../../../constants'
import { IdataSourceItem, IfileItem } from '../../../../types/other'
import { handleFileDeletions, setFilesDataSources, setFormikFileFields } from './funcs'
import Loading from '../../../../ui-component/loading/Loading'
import { useDispatch, useSelector } from '../../../../store/store'
import { hasPermission, makeMultipartFormData } from '../../../../utils/tools'
import axiosInstance from '../../../../utils/axiosInstance'
import { TunsolvedAny } from '../../../../types/unsolved'
import CopyLinkBtn from '../CopyLinkBtn'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TresolvedAny } from '../../../../types'
import NotificationDialog from './NotificationDialog'
import useInternalUserPermissions from '../../../../hooks/useInternalUserPermissions'

const { statuses, channels, types } = commonCodebase.orders

interface Campaign {
  id: string
  name: string
}

const ReadEditTask: FC = () => {
  const { id } = useParams()
  const intl = useIntl()
  const [open, setOpen] = useState(true)
  const [assignees, setAssignees] = useState([{ firstName: '', lastName: '', id: '' }])
  const [customers, setCustomers] = useState([{ name: '', id: '', emails: null }])
  const [formikErrors, setFormikErrors] = useState<string[]>([])
  const [errorsModalOpen, setErrorsModalOpen] = useState(false)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [isEditActive, setIsEditActive] = useState(false)
  const [previewFiles, setPreviewFiles] = useState<IfileItem[]>([])
  const [previewIdsToDelete, setPreviewIdsToDelete] = useState<string[]>([])
  const [previewIdsToCreate, setPreviewIdsToCreate] = useState<string[]>([])
  const [previewsDataSources, setPreviewsDataSources] = useState<IdataSourceItem[]>([])
  const [assetsFiles, setAssetsFiles] = useState<IfileItem[]>([])
  const [assetsIdsToDelete, setAssetsIdsToDelete] = useState<string[]>([])
  const [assetsIdsToCreate, setAssetsIdsToCreate] = useState<string[]>([])
  const [assetsDataSources, setAssetsDataSources] = useState<IdataSourceItem[]>([])
  const [invoiceFiles, setInvoiceFiles] = useState<IfileItem[]>([])
  const [invoiceIdsToDelete, setInvoiceIdsToDelete] = useState<string[]>([])
  const [invoiceIdsToCreate, setInvoiceIdsToCreate] = useState<string[]>([])
  const [invoiceDataSources, setInvoiceDataSources] = useState<IdataSourceItem[]>([])
  const [videoAssets, setVideoAssets] = useState<IfileItem[]>([])
  const [videoAssetsIdsToDelete, setVideoAssetsIdsToDelete] = useState<string[]>([])
  const [videoAssetsIdsToCreate, setVideoAssetsIdsToCreate] = useState<string[]>([])
  const [videoAssetsDataSources, setVideoAssetsDataSources] = useState<IdataSourceItem[]>([])
  const [loadingMutation, setLoadingMutation] = useState<boolean>(false)
  const [mutationError, setMutationError] = useState<[string] | null>(null)
  const [notificationModalOpen, setNotificationModalOpen] = useState<boolean>(false)

  const [existingTypebotCampaign, setExistingTypebotCampaign] = useState<boolean>(false)
  const [existingTypebotCampaignPath, setExistingTypebotCampaignPath] = useState<string | null>(
    null
  )

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openCampaign = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCampaignClose = () => {
    setAnchorEl(null)
  }
  const redirectToCampaign = () => {
    handleCampaignClose()
    window.open(`/campaigns/${formik.values.metaCampaign}`, '_blank')
  }
  const { permissions } = useSelector((state) => state.user)
  const permission = hasPermission(permissions?.orderDetailsFields)

  const handleClose = useCallback(() => {
    setOpen(false)
    window.history.back()
  }, [])

  const { data: fetchedAssignees, loading: loadingAssignees } = useQuery(queries.assignees)
  const { data: fetchedCustomers, loading: loadingCustomerNames } = useQuery(queries.customerNames)
  const { data: fetchedOrderDetails, loading: loadingTaskDetails } = useQuery(
    queries.orderDetails,
    {
      variables: { where: { id } },
      fetchPolicy: 'no-cache',
    }
  )
  const { data: fetchedCampaigns, isLoading: loadingCampaigns } = useSWR(
    '/campaignIdsAndNames',
    axiosInstance
  )
  const campaigns = fetchedCampaigns?.data.campaigns

  const [deleteImagePreview] = useMutation(queries.deletePreviewsImage)
  const [deleteVideoPreview] = useMutation(queries.deletePreviewsVideo)
  const [deleteAudioPreview] = useMutation(queries.deletePreviewsAudio)
  const [deleteAsset] = useMutation(queries.deleteImageAsset)
  const [deleteInvoice] = useMutation(queries.deleteInvoice)
  const [deleteVideoAsset] = useMutation(queries.deleteVideoAsset)
  const { isInternalUser } = useInternalUserPermissions()

  const deletePreviewImage = useCallback(
    async (id: string) => {
      await deleteImagePreview({
        variables: {
          where: { id },
        },
      })
    },
    [deleteImagePreview]
  )

  const deletePreviewAudio = useCallback(
    async (id: string) => {
      await deleteAudioPreview({
        variables: {
          where: { id },
        },
      })
    },
    [deleteAudioPreview]
  )

  const deletePreviewVideo = useCallback(
    async (id: string) => {
      await deleteVideoPreview({
        variables: {
          where: { id },
        },
      })
    },
    [deleteVideoPreview]
  )

  const deleteAssetImage = useCallback(
    async (id: string) => {
      await deleteAsset({
        variables: {
          where: { id },
        },
      })
    },
    [deleteAsset]
  )

  const deleteInvoiceFile = useCallback(
    async (id: string) => {
      await deleteInvoice({
        variables: {
          where: { id },
        },
      })
    },
    [deleteInvoice]
  )

  const deleteFileAssetFile = useCallback(
    async (id: string) => {
      await deleteVideoAsset({
        variables: {
          where: { id },
        },
      })
    },
    [deleteVideoAsset]
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (fetchedAssignees) setAssignees(fetchedAssignees.appUsers)
    if (fetchedCustomers) setCustomers(fetchedCustomers.customers)
  }, [fetchedAssignees, fetchedCustomers])
  useEffect(() => {
    if (mutationError) {
      setFormikErrors(mutationError)
      setErrorsModalOpen(true)
    }
  }, [mutationError])

  const validationSchema = yup.object({
    customer: yup
      .string()
      .required()
      .oneOf(customers.map((i) => i?.id)),
    taskType: yup.string().required(),
    place: yup.string().required().min(2, 'Place should be of minimum 2 characters length'),
    assignee: yup
      .string()
      .oneOf(assignees.map((i) => i?.id))
      .default(null),
    jobName: yup.string().required().min(2, 'Job title should be of minimum 2 characters length'),
    status: yup
      .string()
      .required()
      .oneOf(statuses.map((i) => i.value))
      .default(''),
    genders: yup.string().required().oneOf(genders).required().default(genders[0]),
    channel: yup.array().required(),
    jobAdLink: yup.string().required().min(5, 'Link should be of minimum 5 characters length'),
    keywords: yup.string().nullable(),
    externalImportId: yup.string().nullable(),
    metaCampaign: yup
      .string()
      .oneOf(campaigns ? campaigns?.map((i: { id: string; name: string }) => i?.id) : [])
      .nullable(),
    comments: yup.string().max(1000, 'Maximum 1000 Charachters allowed'),
    budget: yup.number().min(0).required(),
    price: yup.number().min(0).required(),
    runtime: yup.number().min(0).required(),
    previews: yup.object().nullable(),
    imageAssets: yup.object().nullable(),
    invoiceFiles: yup.object().nullable(),
    videoAssets: yup.object().nullable(),
  })

  useEffect(() => {
    if (fetchedOrderDetails?.task) {
      setFilesDataSources(
        fetchedOrderDetails?.task,
        setPreviewsDataSources,
        setAssetsDataSources,
        setInvoiceDataSources,
        setVideoAssetsDataSources
      )
    }
  }, [fetchedOrderDetails, fetchedOrderDetails?.task])

  const formik = useFormik({
    initialValues: {
      customer: fetchedOrderDetails?.task.customer?.id || fetchedCustomers?.customers[0]?.id || '',
      assignee: fetchedOrderDetails?.task.assignee?.id || fetchedAssignees?.appUsers[0]?.id || '',
      jobName: fetchedOrderDetails?.task.jobName || '',
      status: fetchedOrderDetails?.task.status || '',
      gender: fetchedOrderDetails?.task.gender || '',
      place: fetchedOrderDetails?.task.place || '',
      channel: fetchedOrderDetails?.task.channel || [],
      taskType: fetchedOrderDetails?.task.jobType || '',
      jobAdLink: fetchedOrderDetails?.task.jobAdLink || '',
      keywords: fetchedOrderDetails?.task.keywords || '',
      externalImportId: fetchedOrderDetails?.task.externalImportId || '',
      metaCampaign: fetchedOrderDetails?.task.metaCampaign?.id || '',
      comments: fetchedOrderDetails?.task.comments || '',
      budget: fetchedOrderDetails?.task.budget || '',
      price: fetchedOrderDetails?.task.price || '',
      runtime: fetchedOrderDetails?.task.runtime || '',
      invoiceFiles: null,
      previews: null,
      previewsVideo: null,
      previewsAudio: null,
      imageAssets: null,
      videoAssets: null,
      notifyUser: false,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      const newFormData: TunsolvedAny = {
        jobName: values.jobName,
        jobType: values.taskType,
        gender: values.gender,
        place: values.place,
        channel: values.channel,
        budget: String(values.budget),
        price: String(values.price),
        runtime: values.runtime,
        jobAdLink: values.jobAdLink,
        keywords: values.keywords,
        externalImportId: values.externalImportId,
        comments: values.comments,
        status: values.status,
        assignee: {
          connect: { id: values.assignee },
        },
        customer: {
          connect: { id: values.customer },
        },
        invoices: values.invoiceFiles,
        previews: values.previews,
        previewsAudio: values.previewsAudio,
        previewsVideo: values.previewsVideo,
        imageAssets: values.imageAssets,
        videoAssets: values.videoAssets,
      }

      if (values.metaCampaign) {
        newFormData.metaCampaign = {
          connect: { id: values.metaCampaign },
        }
      } else if (fetchedOrderDetails?.task.metaCampaign && !values.metaCampaign) {
        newFormData.metaCampaign = {
          disconnect: true,
        }
      } else {
        newFormData.metaCampaign = undefined
      }

      const mapFileFields = [
        { name: 'previews', source: 'image' },
        { name: 'previewsAudio', source: 'image' },
        { name: 'previewsVideo', source: 'image' },
        { name: 'imageAssets', source: 'image' },
        { name: 'invoices', source: 'file' },
        { name: 'videoAssets', source: 'file' },
      ]

      const formData = makeMultipartFormData(mapFileFields, newFormData)
      if (existingTypebotCampaign) {
        if (!existingTypebotCampaignPath) {
          setMutationError(['Please provide existing Typebot campaign path'])
          return
        }
        formData.append('existingTypebotCampaignPath', existingTypebotCampaignPath)
      }

      try {
        setLoadingMutation(true)
        await axiosInstance.post(`/orders/edit/${id}`, formData, {
          params: {
            notifyUser: values.notifyUser ? 1 : undefined,
          },
          headers: {
            'Content-Type': 'multipart/form-data;',
          },
        })
        setSuccessModalOpen(true)
        dispatch(setTriggerOrdersRefetch(true))
      } catch (error: any) {
        setMutationError([error.response.statusText])
      } finally {
        setLoadingMutation(false)
      }
    },
  })

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const errors = Object.values(formik.errors)
    if (errors.length) {
      setErrorsModalOpen(true)
      setFormikErrors(errors as string[])
      return
    }

    await setFormikFileFields(previewFiles, formik, assetsFiles, invoiceFiles, videoAssets)
    setLoadingMutation(true)
    try {
      await handleFileDeletions(
        previewIdsToDelete,
        deletePreviewImage,
        deletePreviewAudio,
        deletePreviewVideo,
        assetsIdsToDelete,
        deleteAssetImage,
        invoiceIdsToDelete,
        deleteInvoiceFile,
        videoAssetsIdsToDelete,
        deleteFileAssetFile,
        fetchedOrderDetails
      )
      formik.handleSubmit()
    } catch (err: TresolvedAny) {
      setErrorsModalOpen(true)
      setFormikErrors([err?.message])
      setLoadingMutation(false)
    }
  }

  if (loadingMutation || loadingTaskDetails || loadingAssignees || loadingCustomerNames)
    return <Loading />

  if (!fetchedOrderDetails?.task)
    return (
      <div>
        <FormattedMessage id="order-not-found" />
      </div>
    )

  const shouldAskNotification = fetchedOrderDetails.task.status !== formik.values.status

  return (
    <>
      <Dialog fullScreen open={open} className="ReadEditOrder">
        <form onSubmit={(e) => handleSubmit(e)}>
          <AppBar sx={{ position: 'sticky' }} color="default">
            <Toolbar>
              <IconButton
                size="medium"
                edge="start"
                color="inherit"
                onClick={() => handleClose()}
                aria-label="close"
              >
                <IconX size="50px" color="gray" />
              </IconButton>
              <Typography sx={{ ml: 3, flex: 1, textAlign: 'center' }} variant="h3" component="div">
                <FormattedMessage id="view-edit-order" />
              </Typography>
              {isInternalUser && isEditActive && (
                <Button
                  variant="contained"
                  color="secondary"
                  autoFocus
                  size="large"
                  type={shouldAskNotification ? 'button' : 'submit'}
                  onClick={() => (shouldAskNotification ? setNotificationModalOpen(true) : null)}
                >
                  <FormattedMessage id="save" />
                </Button>
              )}
              {isInternalUser && !isEditActive && (
                <Button
                  color="secondary"
                  autoFocus
                  size="large"
                  onClick={(e) => {
                    e.preventDefault()
                    setIsEditActive(true)
                  }}
                >
                  <FormattedMessage id="edit" />
                </Button>
              )}
            </Toolbar>
          </AppBar>
          {isEditActive ? (
            <div>
              <div className="formContainer">
                {permission('customer') && (
                  <Container maxWidth="md" className="customerDetails subFormContainer">
                    <div className="header">
                      <FormattedMessage id="customer-details" />
                    </div>
                    <Grid container className="content">
                      <FormControl fullWidth>
                        <InputLabel id="customer">
                          <FormattedMessage id="customer" />
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="customer"
                          id="customer"
                          name="customer"
                          label={intl.formatMessage({ id: 'customer' })}
                          onChange={formik.handleChange}
                          defaultValue={fetchedOrderDetails?.task.customer?.id}
                        >
                          {customers.map(
                            (i: { id: string; name: string; emails: string | null }) => {
                              const customerHasEmail = i.emails ? JSON.parse(i.emails).length : null
                              return (
                                <MenuItem key={i?.id} value={i?.id}>
                                  {i.name}
                                  {!customerHasEmail ? (
                                    <NoProvidedCustomerEmail iconSize="16px" buttonSize="small" />
                                  ) : null}
                                </MenuItem>
                              )
                            }
                          )}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel id="assignee">Assignee</InputLabel>
                        <Select
                          fullWidth
                          labelId="assignee"
                          id="assignee"
                          name="assignee"
                          label={intl.formatMessage({ id: 'assignee' })}
                          onChange={formik.handleChange}
                          defaultValue={fetchedOrderDetails?.task?.assignee?.id}
                        >
                          {assignees.map((i: Iassignee) => (
                            <MenuItem key={i?.id} value={i?.id}>
                              {i.firstName} {i.lastName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Container>
                )}
                <Container maxWidth="md" className="jobDetails subFormContainer">
                  <div className="header">
                    <FormattedMessage id="job-details" />
                  </div>
                  <Grid container className="content">
                    {permission('jobName') && (
                      <TextField
                        fullWidth
                        label={intl.formatMessage({ id: 'job-title' })}
                        id="jobName"
                        name="jobName"
                        placeholder="Job title"
                        type="jobName"
                        onChange={formik.handleChange}
                        error={formik.touched.jobName && Boolean(formik.errors.jobName)}
                        defaultValue={fetchedOrderDetails?.task.jobName}
                      />
                    )}
                    {permission('status') && (
                      <FormControl fullWidth>
                        <InputLabel id="status">Status</InputLabel>
                        <Select
                          fullWidth
                          labelId="status"
                          id="status"
                          name="status"
                          label={intl.formatMessage({ id: 'status' })}
                          onChange={formik.handleChange}
                          defaultValue={fetchedOrderDetails?.task.status}
                        >
                          {statuses.map((i: Iselect) => (
                            <MenuItem key={i.value} value={i.value}>
                              {i.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    {permission('jobType') && (
                      <FormControl fullWidth>
                        <InputLabel id="taskType">
                          <FormattedMessage id="order-type" />
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="taskType"
                          id="taskType"
                          name="taskType"
                          label={<FormattedMessage id="order-type" />}
                          onChange={formik.handleChange}
                          defaultValue={fetchedOrderDetails?.task.jobType}
                        >
                          {types.map((i: Iselect) => (
                            <MenuItem key={i.value} value={i.value}>
                              {i.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {permission('gender') && (
                      <FormControl fullWidth>
                        <InputLabel id="gender">{<FormattedMessage id="gender" />}</InputLabel>
                        <Select
                          fullWidth
                          labelId="gender"
                          id="gender"
                          name="gender"
                          label={<FormattedMessage id="gender" />}
                          onChange={formik.handleChange}
                          defaultValue={fetchedOrderDetails?.task.gender}
                        >
                          {genders.map((i: string) => (
                            <MenuItem key={i} value={i}>
                              {i}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {permission('place') && (
                      <TextField
                        fullWidth
                        label={<FormattedMessage id="place" />}
                        id="place"
                        name="place"
                        placeholder="Place"
                        type="place"
                        onChange={formik.handleChange}
                        error={formik.touched.place && Boolean(formik.errors.place)}
                        value={formik.values.place}
                      />
                    )}
                    {permission('channel') && (
                      <FormControl fullWidth>
                        <InputLabel id="channel">
                          <FormattedMessage id="portal" />
                        </InputLabel>
                        <Select
                          fullWidth
                          multiple
                          labelId="channel"
                          id="channel"
                          name="channel"
                          label={<FormattedMessage id="portal" />}
                          onChange={formik.handleChange}
                          defaultValue={fetchedOrderDetails?.task.channel}
                        >
                          {channels.map((i: Iselect) => (
                            <MenuItem key={i.value} value={i.value}>
                              <Chip variant="outlined" key={i.value} label={i.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {permission('jobAdLink') && (
                      <TextField
                        fullWidth
                        label={intl.formatMessage({ id: 'job-ad-link' })}
                        id="jobAdLink"
                        name="jobAdLink"
                        placeholder="Link to job ad"
                        type="text"
                        onChange={formik.handleChange}
                        error={formik.touched.jobAdLink && Boolean(formik.errors.jobAdLink)}
                        defaultValue={fetchedOrderDetails?.task.jobAdLink}
                      />
                    )}
                    {permission('keywords') && (
                      <TextField
                        fullWidth
                        label={<FormattedMessage id="keywords-comma" />}
                        id="keywords"
                        name="keywords"
                        placeholder="Keywords"
                        type="text"
                        onChange={formik.handleChange}
                        error={formik.touched.keywords && Boolean(formik.errors.keywords)}
                        defaultValue={fetchedOrderDetails?.task.keywords}
                      />
                    )}
                    {permission('externalImportId') && (
                      <TextField
                        fullWidth
                        label={intl.formatMessage({ id: 'external-import-id' })}
                        id="externalImportId"
                        name="externalImportId"
                        placeholder="externalImportId"
                        type="text"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.externalImportId && Boolean(formik.errors.externalImportId)
                        }
                        defaultValue={fetchedOrderDetails?.task.externalImportId}
                      />
                    )}
                    {permission('metaCampaign') &&
                      (loadingCampaigns ? (
                        <Loading />
                      ) : (
                        <div className="campaignRow">
                          <Autocomplete
                            fullWidth
                            options={campaigns}
                            getOptionLabel={(option: Campaign) => option.name}
                            onChange={(e, value) => {
                              formik.setFieldValue('metaCampaign', value?.id)
                            }}
                            defaultValue={fetchedOrderDetails?.task?.metaCampaign}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={<FormattedMessage id="metaCampaign" />}
                                id="metaCampaign"
                                name="metaCampaign"
                                placeholder="Meta Campaign"
                                type="text"
                                value={formik.values.metaCampaign}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.metaCampaign && Boolean(formik.errors.metaCampaign)
                                }
                              />
                            )}
                          />

                          <div>
                            <Tooltip title="options" className="icon">
                              <IconButton
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </Tooltip>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={openCampaign}
                              onClose={handleCampaignClose}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                            >
                              <MenuItem onClick={() => redirectToCampaign()}>
                                <FormattedMessage id="Open Campaign" />
                              </MenuItem>
                            </Menu>
                          </div>
                        </div>
                      ))}
                    {permission('typebotCampaign') &&
                      !fetchedOrderDetails?.task?.typebotCampaign && (
                        <div className="typebot">
                          <FormControlLabel
                            className="form"
                            value="start"
                            control={
                              <Checkbox
                                checked={existingTypebotCampaign}
                                onChange={() =>
                                  setExistingTypebotCampaign(!existingTypebotCampaign)
                                }
                              />
                            }
                            label="Existing Typbot campaign"
                            labelPlacement="start"
                          />

                          {existingTypebotCampaign && (
                            <FormControl fullWidth>
                              {/* path */}
                              <TextField
                                fullWidth
                                label={intl.formatMessage({ id: 'existing-typebot-campaign-path' })}
                                id="existingTypebotCampainPath"
                                name="existingTypebotCampainPath"
                                placeholder="existingTypebotCampainPath"
                                type="text"
                                onChange={(e) => setExistingTypebotCampaignPath(e.target.value)}
                              />
                            </FormControl>
                          )}
                          <div></div>
                        </div>
                      )}
                  </Grid>
                </Container>
                {permission('comments') && (
                  <Container maxWidth="md" className="comments subFormContainer">
                    <div className="header">
                      <FormattedMessage id="comments" />
                    </div>
                    <Grid container className="content">
                      <TextField
                        fullWidth
                        id="comments"
                        name="comments"
                        placeholder="comments"
                        rows={3}
                        multiline
                        type="text"
                        onChange={formik.handleChange}
                        error={formik.touched.comments && Boolean(formik.errors.comments)}
                        defaultValue={fetchedOrderDetails?.task.comments}
                      />
                    </Grid>
                  </Container>
                )}
                <Container maxWidth="md" className="budgetAndInvoice subFormContainer">
                  <div className="header">
                    <FormattedMessage id="budgets-invoice" />
                  </div>
                  <Grid container className="content">
                    {permission('budget') && (
                      <TextField
                        fullWidth
                        label={intl.formatMessage({ id: 'budget' })}
                        id="budget"
                        name="budget"
                        placeholder="budget"
                        type="number"
                        onChange={formik.handleChange}
                        error={formik.touched.budget && Boolean(formik.errors.budget)}
                        value={formik.values.budget}
                      />
                    )}
                    {permission('price') && (
                      <TextField
                        fullWidth
                        label={<FormattedMessage id="price" />}
                        id="price"
                        name="price"
                        placeholder="Price"
                        type="number"
                        onChange={formik.handleChange}
                        error={formik.touched.price && Boolean(formik.errors.price)}
                        value={formik.values.price}
                      />
                    )}
                    {permission('runtime') && (
                      <TextField
                        fullWidth
                        label={<FormattedMessage id="runtime" />}
                        id="runtime"
                        name="runtime"
                        placeholder="Runtime"
                        type="number"
                        onChange={formik.handleChange}
                        error={formik.touched.runtime && Boolean(formik.errors.runtime)}
                        value={fetchedOrderDetails?.task.runtime}
                        InputProps={{
                          endAdornment: <InputAdornment position="start">days</InputAdornment>,
                        }}
                      />
                    )}
                  </Grid>
                </Container>
                {permission('invoices') && (
                  <Container maxWidth="md" className="previewFiles subFormContainer">
                    <div className="header">
                      <FormattedMessage id="invoice-files" />
                    </div>
                    <div className="content content-files">
                      <div>
                        <MultiImageEdit
                          title="Invoices"
                          setFiles={setInvoiceFiles}
                          setDataSources={setInvoiceDataSources}
                          dataSources={invoiceDataSources}
                          idsToCreate={invoiceIdsToCreate}
                          idsToDelete={invoiceIdsToDelete}
                          setIdsToDelete={setInvoiceIdsToDelete}
                          setIdsToCreate={setInvoiceIdsToCreate}
                          hasPreview={false}
                          isMultiType
                          allowedTypes={allowedDocumentFileTypes.document}
                        />
                      </div>
                    </div>
                  </Container>
                )}
                {permission('previews') && (
                  <Container maxWidth="md" className="previewFiles subFormContainer">
                    <div className="header">
                      <FormattedMessage id="preview" />
                    </div>

                    <div className="content content-files">
                      <MultiImageEdit
                        fileAmountLimit={3}
                        setFiles={setPreviewFiles}
                        setDataSources={setPreviewsDataSources}
                        title="Previews 111"
                        dataSources={previewsDataSources}
                        idsToDelete={previewIdsToDelete}
                        setIdsToDelete={setPreviewIdsToDelete}
                        idsToCreate={previewIdsToCreate}
                        setIdsToCreate={setPreviewIdsToCreate}
                        hasPreview
                        allowedTypes={allowedPreviewFileTypes.file}
                        headerSlot={() => {
                          if (
                            fetchedOrderDetails?.task &&
                            ![
                              ...fetchedOrderDetails.task.previews,
                              ...fetchedOrderDetails.task.previewsAudio,
                              ...fetchedOrderDetails.task.previewsVideo,
                            ].length
                          ) {
                            return null
                          }
                          return (
                            <div className="sub-header sub-header-slot">
                              <Typography variant="h5">
                                <FormattedMessage id="uploaded-files" />
                              </Typography>
                              <Box display="flex" alignItems="center" gap="4px">
                                <Link
                                  component={RouterLink}
                                  target="_blank"
                                  to={`/order-previews?id=${fetchedOrderDetails?.task?.id}`}
                                >
                                  <FormattedMessage id="preview-all-link" />
                                </Link>
                                <CopyLinkBtn
                                  str={`/order-previews?id=${fetchedOrderDetails?.task?.id}`}
                                />
                              </Box>
                            </div>
                          )
                        }}
                      />
                    </div>
                  </Container>
                )}
                {permission('imageAssets') && (
                  <Container maxWidth="md" className="previewFiles subFormContainer">
                    <div className="header">
                      <FormattedMessage id="image-assets" />
                    </div>
                    <div className="content content-files">
                      <div>
                        <MultiImageEdit
                          title="Image assets"
                          setFiles={setAssetsFiles}
                          setDataSources={setAssetsDataSources}
                          dataSources={assetsDataSources}
                          idsToCreate={assetsIdsToCreate}
                          idsToDelete={assetsIdsToDelete}
                          setIdsToDelete={setAssetsIdsToDelete}
                          setIdsToCreate={setAssetsIdsToCreate}
                          hasPreview
                          allowedTypes={allowedImageFileTypes.image}
                        />
                      </div>
                    </div>
                  </Container>
                )}
                {permission('videoAssets') && (
                  <Container maxWidth="md" className="previewFiles subFormContainer">
                    <div className="header">
                      <FormattedMessage id="video-assets" />
                    </div>
                    <div className="content content-files">
                      <div>
                        <MultiImageEdit
                          title="Video assets"
                          setFiles={setVideoAssets}
                          setDataSources={setVideoAssetsDataSources}
                          dataSources={videoAssetsDataSources}
                          idsToCreate={videoAssetsIdsToCreate}
                          idsToDelete={videoAssetsIdsToDelete}
                          setIdsToDelete={setVideoAssetsIdsToDelete}
                          setIdsToCreate={setVideoAssetsIdsToCreate}
                          hasPreview={false}
                          allowedTypes={allowedVideoFileTypes.video}
                        />
                      </div>
                    </div>
                  </Container>
                )}
              </div>

              <FormErrorsModal
                errorsModalOpen={errorsModalOpen}
                formikErrors={formikErrors}
                setErrorsModalOpen={setErrorsModalOpen}
              />
              <SuccessModal
                successModalOpen={successModalOpen}
                handleClose={() => handleClose()}
                setSuccessModalOpen={setSuccessModalOpen}
                message={<FormattedMessage id="edited-successfully" />}
              />
            </div>
          ) : (
            <ReadOrder order={fetchedOrderDetails?.task} />
          )}
        </form>
      </Dialog>
      <NotificationDialog
        setFieldValue={formik.setFieldValue}
        submit={formik.handleSubmit}
        open={notificationModalOpen}
        setOpen={setNotificationModalOpen}
      />
    </>
  )
}

export default ReadEditTask
