import commonCodebase from 'common-recruspin-codebase'
import { useEffect, useState } from 'react'
import {
  Button,
  Container,
  Drawer,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { IconX } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { setCustomersFilterQueryParams } from '../../../../../store/slices/filters/filters'
import { useDispatch } from '../../../../../store/store'
import { Iselect } from '../../../../../constants'

interface Iprops {
  drawerOpen: boolean
  setDrawerOpen: (drawerOpen: boolean) => void
}

const customerTypes = commonCodebase.customer.customers

export function CustomerFilterDrawer({ drawerOpen = false, setDrawerOpen }: Iprops) {
  return (
    <Drawer
      className="filterDrawer"
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <Container disableGutters className="drawerContainer" style={{ padding: 16 }}>
        <Container className="header" style={{ padding: 0 }}>
          <Typography variant="h3">Filter</Typography>
          <IconButton
            size="medium"
            edge="start"
            color="inherit"
            onClick={() => setDrawerOpen(false)}
            aria-label="close"
          >
            <IconX />
          </IconButton>
        </Container>
        <Container style={{ padding: 0 }}>
          <Filters />
        </Container>
      </Container>
    </Drawer>
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const [customerType, setCustomerType] = useState<string | null>(null)
  const [email, setEmail] = useState<string | null>(null)

  useEffect(() => {
    const params = {
      customerType,
      email,
    }
    dispatch(setCustomersFilterQueryParams(params))
  }, [dispatch, customerType, email])

  function clearFilters() {
    setCustomerType(null)
    setEmail('')
  }

  return (
    <form>
      <FormControl fullWidth>
        <Typography>
          <FormattedMessage id="customer-type" />
        </Typography>
        <Select
          fullWidth
          labelId="type"
          id="type"
          name="type"
          onChange={(e) => {
            setCustomerType(e.target.value)
          }}
          value={customerType}
          label="customerType"
        >
          {customerTypes.map((i: Iselect) => (
            <MenuItem key={i.value} value={i.value}>
              {i.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <Typography>
          <FormattedMessage id="email" />
        </Typography>
        <TextField
          fullWidth
          id="email"
          name="email"
          placeholder="Customer email"
          type="emails"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>
      <div className="clear">
        <Button onClick={() => clearFilters()} className="clearButt" fullWidth variant="outlined">
          Clear all
        </Button>
      </div>
    </form>
  )
}
