import { Button, Container, Dialog, DialogActions, DialogContent } from '@mui/material'
import DialogHeader from './header'

type Props = {
  children: any
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  title: string
  handleSubmit: () => unknown
}

export default function DialogBox({
  children,
  open = false,
  setOpen,
  title = '',
  isLoading = false,
  handleSubmit,
}: Props) {
  const handleClose = () => {
    setOpen((old) => !old)
  }

  return (
    <>
      <Dialog open={open} className="ReadEditOrder" fullWidth maxWidth="md">
        <Container disableGutters>
          <DialogHeader title={title} handleClose={handleClose} />
          <DialogContent sx={{ overflow: 'hidden' }}>{children}</DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleSubmit()
              }}
              disabled={isLoading}
            >
              Save
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </>
  )
}
