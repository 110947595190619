import React, { useEffect, useState } from 'react'
import { LineChart, Line, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { Stack } from '@mui/material'
import PlaceHolderRender from '../../../components/PlaceHolderRender'
import './styles.scss'
import { Iorder } from '../InternalDashboard'

interface YearlyProps {
  name: string
  orders: number
}

// TODO: Add real world data
const YearlyGraph = ({ data }) => {
  const [dataChart, setDatachart] = useState<YearlyProps[]>()

  const countItemsByMonth = (items: Iorder[]) => {
    if (!items?.length) {
      return
    }

    const countsByMonth: YearlyProps[] = []
    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const date = new Date(item.createdAt)
      const month = date.toLocaleString('de-DE', { month: 'short' })
      const index = countsByMonth.length
        ? countsByMonth.findIndex((mont) => mont.name === month)
        : -1

      if (index >= 0) {
        // eslint-disable-next-line no-plusplus
        countsByMonth[index] = { ...countsByMonth[index], orders: countsByMonth[index].orders + 1 }
      } else {
        countsByMonth.push({ name: month, orders: 1 })
      }
    }

    return countsByMonth
  }

  const RenderMonths = () => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    return (
      <div className="monthWrapper">
        {months.map((month) => {
          return <p key={month}>{month}</p>
        })}
      </div>
    )
  }

  useEffect(() => {
    setDatachart(countItemsByMonth(data))
  }, [data])

  return (
    <>
      {data.length ? (
        <div className="yearlyGraphWrap">
          <ResponsiveContainer width="100%" height={400}>
            <LineChart width={1200} height={474} data={dataChart}>
              <CartesianGrid vertical={false} horizontal />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="orders" stroke="#8884d8" strokeWidth={2} dot={false} />
              {/* <Line type="monotone" dataKey="tasks" stroke="#82ca9d" strokeWidth={2} dot={false} /> */}
            </LineChart>
          </ResponsiveContainer>
          <RenderMonths />
        </div>
      ) : (
        <>
          <PlaceHolderRender
            box
            text="No data available"
            minBoxHeight="315px"
            marginBottom="16px"
          />
          <Stack direction="column" alignItems={'center'} flexWrap="wrap">
            <PlaceHolderRender width={'300px'} height={'10px'} marginBottom="16px" />
            <PlaceHolderRender width={'100%'} height={'10px'} marginBottom="0px" />
          </Stack>
        </>
      )}
    </>
  )
}

export default YearlyGraph
