import { IconButton, Tooltip, useTheme } from '@mui/material'
import { IconTrash } from '@tabler/icons-react'
import React from 'react'

interface Iprops {
  phone: string
  setPhones: (value: string[]) => void
}

const EditPhoneRow: React.FC<Iprops> = ({ phone, setPhones }) => {
  const theme = useTheme()

  const removeItem = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setPhones((prev: string[]) => prev.filter((i: string) => i !== email))
  }

  return (
    <div className="EditPhoneRow EditEmailRow">
      <div className="row">
        <div className="email">{phone}</div>
        <div className="options">
          <div style={{ borderRight: 'none' }}></div>
          <div style={{ borderRight: 'none' }}></div>
          <div style={{ borderRight: 'none' }}></div>
          <Tooltip className="remove" title="Remove" onClick={removeItem}>
            <IconButton>
              <IconTrash
                stroke="none"
                // fill={theme.palette.primary.main}
                color={theme.palette.error.dark}
              />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default EditPhoneRow
