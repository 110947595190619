import { useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import BoxWrapper from '../../../components/BoxWrapper'
import { CustomTabPanel } from '../../../components/CustomerTabPanel/CustomTabPanel'
import ExternalUsers from './externalUsers/ExternalUsers'
import InternalUsers from './internalUsers/InternalUsers'
import { SpaceDivider } from './accounts.styles'

const Accounts = () => {
  const [value, setValue] = useState(0)
  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  return (
    <div>
      <BoxWrapper>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={(_e, v) => setValue(v)}>
            <Tab label="ّInternal Users" {...a11yProps(0)} />
            <Tab label="External Users" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <SpaceDivider />

        <CustomTabPanel value={value} index={0}>
          <InternalUsers />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ExternalUsers />
        </CustomTabPanel>
      </BoxWrapper>
    </div>
  )
}

export default Accounts
