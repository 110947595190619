import { store } from '../../../../store/store'
import { HeadCell } from '../../../../types'
import { hasPermission } from '../../../../utils/tools'

export const headCellsCustomers: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'name',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'type',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'email',
  },
  {
    id: 'phoneNumber',
    numeric: false,
    disablePadding: false,
    label: 'phone-number',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'address',
  },
]

store.subscribe(() => {
  const { permissions } = store.getState().user
  const permission = hasPermission(permissions?.customersDetailsFields)
  if (permissions) {
    !permission('name') && delete headCellsCustomers[0]
    !permission('type') && delete headCellsCustomers[1]
    !permission('emails') && delete headCellsCustomers[2]
    !permission('phoneNumbers') && delete headCellsCustomers[3]
    !permission('address') && delete headCellsCustomers[4]
  }
})
