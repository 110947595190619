import { useTheme } from '@mui/material/styles'
import { Avatar, Box, useMediaQuery } from '@mui/material'
import { IconMenu2 } from '@tabler/icons-react'
import { useState } from 'react'
import LogoSection from '../LogoSection'
import MobileSection from './MobileSection'
import ProfileSection from './ProfileSection'
import LocalizationSection from './LocalizationSection'
import NotificationSection from './NotificationSection'
import { useDispatch, useSelector } from '../../../store/store'
import { openDrawer } from '../../../store/slices/menu'
import useConfig from '../../../hooks/useConfig'
import { LAYOUT_CONST } from '../../../constants'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const [isMenuHover, setMenuHover] = useState(false)

  const theme = useTheme()

  const dispatch = useDispatch()
  const { drawerOpen } = useSelector((state) => state.menu)

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const { layout } = useConfig()

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto',
          },
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <div
          onMouseEnter={() => setMenuHover(true)}
          onMouseLeave={() => setMenuHover(false)}
          className="hoverBox"
        >
          {(layout === LAYOUT_CONST.VERTICAL_LAYOUT ||
            (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd)) && (
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                overflow: 'hidden',
                transition: 'all .2s ease-in-out',
                background:
                  theme.palette.mode === 'dark'
                    ? theme.palette.dark.main
                    : theme.palette.secondary.light,
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.dark,
                '&:hover': {
                  background:
                    theme.palette.mode === 'dark'
                      ? theme.palette.secondary.main
                      : theme.palette.secondary.dark,
                  color:
                    theme.palette.mode === 'dark'
                      ? theme.palette.secondary.light
                      : theme.palette.secondary.light,
                },
              }}
              onClick={() => dispatch(openDrawer(!drawerOpen))}
              color="inherit"
            >
              <IconMenu2
                color={isMenuHover ? theme.palette.primary.light : theme.palette.primary.main}
                stroke={1.5}
                size="20px"
              />
            </Avatar>
          )}
        </div>
      </Box>

      {/* header search */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* live customization & localization */}
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <LocalizationSection />
      </Box>

      {/* notification & profile */}
      <NotificationSection />
      <ProfileSection />

      {/* mobile header */}
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <MobileSection />
      </Box>
    </>
  )
}

export default Header
