// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.77.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.77.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper__RMvVz {\n  overflow-y: auto;\n  max-height: 410px;\n  padding-right: 16px;\n}\n.style_wrapper__RMvVz::-webkit-scrollbar {\n  width: 4px;\n  background-color: #fff;\n}\n.style_wrapper__RMvVz::-webkit-scrollbar-thumb {\n  background-color: #a4a8b3;\n  border-radius: 25px;\n}\n.style_wrapper__RMvVz::-webkit-scrollbar-thumb:hover {\n  background-color: #a4a8b3;\n}", "",{"version":3,"sources":["webpack://./src/views/leads/leadsList/leadDetail/style.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,mBAAA;AACF;AACE;EACE,UAAA;EACA,sBAAA;AACJ;AAEE;EACE,yBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,yBAAA;AADJ","sourcesContent":[".wrapper {\n  overflow-y: auto;\n  max-height: 410px;\n  padding-right: 16px;\n\n  &::-webkit-scrollbar {\n    width: 4px;\n    background-color: #fff;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: #a4a8b3;\n    border-radius: 25px;\n  }\n\n  &::-webkit-scrollbar-thumb:hover {\n    background-color: #a4a8b3;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper__RMvVz"
};
export default ___CSS_LOADER_EXPORT___;
