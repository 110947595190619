import React, { useCallback } from 'react'
import { Checkbox, Grid, TableRow, Typography } from '@mui/material'
import dayjs from 'dayjs'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom'
import classes from './style.module.scss'
import EnhancedTableCell from '../EnhancedTableCell/EnhancedTableCell'
import { leadStatuses } from '../../../../constants'
import { dialogToggle } from '../../../../store/slices/dialog'
import { useDispatch, useSelector } from '../../../../store/store'
import { hasPermission } from '../../../../utils/tools'

const LeadsRow = ({ labelId = '', row, isItemSelected = false, checkbox = false }) => {
  const { id, status, createdAt, firstName, lastName, phone, email, portal } = row // Add chanel when implemented
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { permissions } = useSelector((state) => state.user)
  const permission = hasPermission(permissions?.leadsAndTalentsDetailsFields)

  const handleClick = useCallback(() => {
    dispatch(dialogToggle(true))
    navigate({ search: `lead=${id}` })
  }, [dispatch, id, navigate])

  const handleDelete = () => {
    // TODO: Add delete E.P
  }

  const TableCellLead = (
    props: JSX.IntrinsicAttributes & {
      children: React.ReactNode
      action?: boolean | undefined
      checkbox?: boolean | undefined
      handleClick?: (() => void) | undefined
    }
  ) => {
    return (
      <EnhancedTableCell id={id} handleClick={handleClick} {...props}>
        {props.children}
      </EnhancedTableCell>
    )
  }

  const statusValueToLabel = React.useCallback((value) => {
    return leadStatuses.find((status) => status.value === value)?.label
  }, [])

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={id}
      selected={isItemSelected}
      sx={{ cursor: 'pointer' }}
    >
      {checkbox && (
        <TableCellLead checkbox action>
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelId,
            }}
          />
        </TableCellLead>
      )}
      {permission('status') && (
        <TableCellLead>
          <p className={`${classes.status} ${classes[status]}`}>{statusValueToLabel(status)}</p>
        </TableCellLead>
      )}
      {permission('createdTime') && (
        <TableCellLead>{dayjs(createdAt).format('DD/MM/YYYY')}</TableCellLead>
      )}
      {permission('firstName') && <TableCellLead>{firstName}</TableCellLead>}
      {permission('lastName') && <TableCellLead>{lastName}</TableCellLead>}
      {permission('phone') && <TableCellLead>{phone}</TableCellLead>}
      {permission('email') && <TableCellLead>{email}</TableCellLead>}
      {permission('platform') && (
        <TableCellLead>{<img src={`/socials/${portal}.svg`} alt="Social icon" />}</TableCellLead>
      )}
      {permission('channel') && (
        <TableCellLead>
          <Grid
            container
            alignItems="center"
            sx={{
              display: 'flex',
              padding: '4px 8px',
              alignItems: 'center',
              borderRadius: '100px',
              background: '#E9E7F2',
              width: 'fit-content',
              flexWrap: 'nowrap',
            }}
          >
            <img src={`/socials/${portal}.svg`} alt="Social icon" />
            <Typography style={{ margin: 0, marginLeft: '4px', whiteSpace: 'pre' }}>
              Lead Form
            </Typography>
          </Grid>
        </TableCellLead>
      )}
      <TableCellLead action>
        <DeleteIcon onClick={handleDelete} />
      </TableCellLead>
    </TableRow>
  )
}

export default LeadsRow
