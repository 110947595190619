import { createSlice } from '@reduxjs/toolkit'
import {
  AnalyticsFilterParams,
  CampaignsFilterQueryParams,
  CustomersFilterQueryParams,
  ExternalUsersFilterQueryParams,
  Filter,
  InternalUsersFilterQueryParams,
  OrdersFilterParams,
} from './type'

const initialState: Filter = {
  filters: {},
  config: [],
  options: [{ label: '', value: '' }],
}

const filters = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setOrdersFilterQueryParams(state, { payload }: { payload: OrdersFilterParams }) {
      state.ordersFilterQueryParams = payload
    },
    setordersFilterQuerycustomerSearch(state, { payload }) {
      state.ordersFilterQuerycustomerSearch = payload
    },
    setCustomersFilterQueryParams(state, { payload }: { payload: CustomersFilterQueryParams }) {
      state.customersFilterQueryParams = payload
    },
    setCustomerNameFilterQuerySearch(state, { payload }) {
      state.customerNameFilterQuerySearch = payload
    },
    setCampaignsFilterQueryParams(state, { payload }: { payload: CampaignsFilterQueryParams }) {
      state.campaignsFilterQueryParams = payload
    },
    setCampaignNameFilterQuerySearch(state, { payload }) {
      state.campaignNameFilterQuerySearch = payload
    },
    setInternalUsersFilterQueryParams(
      state,
      { payload }: { payload: InternalUsersFilterQueryParams }
    ) {
      state.internalUsersFilterQueryParams = payload
    },
    setExternalUsersFilterQueryParams(
      state,
      { payload }: { payload: ExternalUsersFilterQueryParams }
    ) {
      state.externalUsersFilterQueryParams = payload
    },
    setInternalUsersFilterQuerySearch(state, { payload }) {
      state.internalUsersFilterQuerySearch = payload
    },
    setExternalUsersFilterQuerySearch(state, { payload }) {
      state.externalUsersFilterQuerySearch = payload
    },
    setAnalyticsFilterQueryParams(state, { payload }: { payload: AnalyticsFilterParams }) {
      state.analyticsFilterQueryParams = payload
    },
    setFilters(state, { payload: { name, value } }) {
      state.filters[name] = value || ''
    },
    setFilterConfig(state, { payload }) {
      state.config = payload
    },

    setFilterOptions(state, { payload }) {
      state.options = payload
    },
    removeFilters(state) {
      state.filters = initialState.filters
      state.config = initialState.config
      state.options = initialState.options
    },
    clearFilters(state) {
      state.filters = { search: '' }
    },
  },
})

export default filters.reducer

export const {
  setFilterConfig,
  setFilters,
  setFilterOptions,
  removeFilters,
  clearFilters,
  setOrdersFilterQueryParams,
  setCustomerNameFilterQuerySearch,
  setCustomersFilterQueryParams,
  setCampaignsFilterQueryParams,
  setCampaignNameFilterQuerySearch,
  setordersFilterQuerycustomerSearch,
  setInternalUsersFilterQueryParams,
  setInternalUsersFilterQuerySearch,
  setExternalUsersFilterQueryParams,
  setExternalUsersFilterQuerySearch,
  setAnalyticsFilterQueryParams,
} = filters.actions
