import './styles.scss'

type Props = {
  width?: string
  backgroundColor?: string
  marginBottom?: string
  marginLeft?: string
  marginRight?: string
  height?: string
  minBoxHeight?: string
  box?: boolean
  text?: string | JSX.Element
}

const PlaceHolderRender: React.FC<Props> = ({
  width = '100%',
  backgroundColor = '#eee',
  marginBottom = '4px',
  marginLeft = '0',
  marginRight = '0',
  height = '16px',
  minBoxHeight = '141px',
  box = false,
  text = '',
}) => {
  return (
    <>
      {box ? (
        <div
          className="noOpenOrders"
          style={{
            minHeight: minBoxHeight,
            marginBottom,
          }}
        >
          <p>{text}</p>
        </div>
      ) : (
        <span
          className="placeHolderRender"
          style={{
            width,
            height,
            backgroundColor,
            marginBottom,
            marginLeft,
            marginRight,
          }}
        />
      )}
    </>
  )
}

export default PlaceHolderRender
