import type { FC } from 'react'
import { Card } from '@mui/material'
import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import { COLUMNS } from './AdsetsKPIs.constants'
import { AdSetsTable } from './components'
import axiosInstance from '../../../../utils/axiosInstance'

const AdsetsKPIs: FC = () => {
  const { id: campaignId } = useParams()
  const { data, isLoading } = useSWR(`/meta/adsets/${campaignId}`, axiosInstance)

  return (
    <Card sx={{ mt: '20px' }}>
      <AdSetsTable isLoading={isLoading} columns={COLUMNS} data={data?.data} />
    </Card>
  )
}

export default AdsetsKPIs
