import React, { useState, useEffect } from 'react'

// third-party
import { IntlProvider, MessageFormatElement } from 'react-intl'
import { useSelector } from '../../store/store'
import { english } from '../../utils/locales/en'
import { german } from '../../utils/locales/de'

// load locales files
const loadLocaleData = (locale: string) => {
  switch (locale) {
    case 'de':
      return german
    default:
      return english
  }
}

// ==============================|| LOCALIZATION ||============================== //

interface LocalsProps {
  children: React.ReactNode
}

const Locales = ({ children }: LocalsProps) => {
  const locateState = useSelector((state) => state.general.language)
  const [messages, setMessages] = useState<
    Record<string, string> | Record<string, MessageFormatElement[]> | undefined
  >()

  useEffect(() => {
    const data: {
      default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined
    } = loadLocaleData(locateState)
    setMessages(data.default)
  }, [locateState])
  return (
    <>
      {messages && (
        <IntlProvider locale={locateState} defaultLocale="en" messages={messages}>
          {children}
        </IntlProvider>
      )}
    </>
  )
}

export default Locales
