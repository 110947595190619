import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { headCellsCampaigns } from './HeadCells/campaigns'
import { HeadCell } from '../../../types'
import { headCellsLeads } from './HeadCells/leads'
import { useSelector } from '../../../store/store'
import { headCellsOrders } from './HeadCells/orders'
import { headCellsCustomers } from './HeadCells/customers'

export type Order = 'asc' | 'desc'
interface EnhancedTableProps {
  checkbox: boolean
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { checkbox, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = (property) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }
  const [headCells, setHeadCells] = useState<HeadCell[]>()
  const { type } = useSelector((state) => state.table)

  // We need to follow request values and add them to id or sort option wont work
  useEffect(() => {
    switch (type) {
      case 'campaign':
        setHeadCells(headCellsCampaigns)
        break
      case 'lead':
        setHeadCells(headCellsLeads)
        break
      case 'order':
        setHeadCells(headCellsOrders)
        break
      case 'customer':
        setHeadCells(headCellsCustomers)
        break
      default:
        break
    }
  }, [type])

  return (
    <TableHead>
      <TableRow>
        {checkbox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        )}
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{ whiteSpace: 'pre' }}
            >
              <FormattedMessage id={headCell.label} />
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
