// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.77.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.77.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_timeline__XiUHO {\n  margin: 16px 0;\n  display: flex;\n}\n.style_timeline__XiUHO h2,\n.style_timeline__XiUHO h3,\n.style_timeline__XiUHO h4,\n.style_timeline__XiUHO h5,\n.style_timeline__XiUHO p {\n  margin: 0;\n}\n.style_timeline__XiUHO h3 {\n  font-size: 16px;\n  margin-bottom: 12px;\n}\n\n.style_current__blEVC {\n  border: 2px solid #220d77;\n  padding: 8px;\n  box-sizing: content-box;\n}\n\n.style_dot__B9507 {\n  height: 10px;\n  width: 10px;\n  border-radius: 100px;\n  color: #220d77;\n}\n\n.style_line__BbIQp {\n  border-left: 2px solid #220d77;\n  height: 100px;\n  width: -moz-fit-content;\n  width: fit-content;\n  display: block;\n  position: relative;\n  left: 14px;\n  top: -5px;\n}\n\n.style_activity__tWYDz {\n  margin-left: 12px;\n}", "",{"version":3,"sources":["webpack://./src/views/leads/leadsList/leadDetail/timeline/style.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;AACF;AACE;;;;;EAKE,SAAA;AACJ;AACE;EACE,eAAA;EACA,mBAAA;AACJ;;AAIA;EACE,yBAAA;EACA,YAAA;EACA,uBAAA;AADF;;AAGA;EACE,YAAA;EACA,WAAA;EACA,oBAAA;EACA,cAAA;AAAF;;AAEA;EACE,8BAAA;EACA,aAAA;EACA,uBAAA;EAAA,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,UAAA;EACA,SAAA;AACF;;AACA;EACE,iBAAA;AAEF","sourcesContent":[".timeline {\n  margin: 16px 0;\n  display: flex;\n\n  h2,\n  h3,\n  h4,\n  h5,\n  p {\n    margin: 0;\n  }\n  h3 {\n    font-size: 16px;\n    margin-bottom: 12px;\n  }\n}\n.timelineLine {\n}\n.current {\n  border: 2px solid #220d77;\n  padding: 8px;\n  box-sizing: content-box;\n}\n.dot {\n  height: 10px;\n  width: 10px;\n  border-radius: 100px;\n  color: #220d77;\n}\n.line {\n  border-left: 2px solid #220d77;\n  height: 100px;\n  width: fit-content;\n  display: block;\n  position: relative;\n  left: 14px;\n  top: -5px;\n}\n.activity {\n  margin-left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timeline": "style_timeline__XiUHO",
	"current": "style_current__blEVC",
	"dot": "style_dot__B9507",
	"line": "style_line__BbIQp",
	"activity": "style_activity__tWYDz"
};
export default ___CSS_LOADER_EXPORT___;
