import React from 'react'

function cleanDate(date: string) {
  const cleaned = date.replace('T', ' ')
  return cleaned.slice(0, -8)
}

function fixImgSrc(url: string) {
  if (!url) return ''
  const env = process.env.REACT_APP_ENV
  if (env === 'production') return url.replace('minio:9000', 'storage.recruspin.com')

  return url
}

function useDidMount() {
  const mountRef = React.useRef(false)

  React.useEffect(() => {
    mountRef.current = true
  }, [])

  return () => mountRef.current
}

function multipleSelectConvertValuesToLabels(
  refrenceArray: { label: string; value: string }[],
  values: string[]
) {
  const labels: string[] = []
  values.forEach((value) => {
    const label = refrenceArray.find((item) => item.value === value)?.label
    if (label) labels.push(label)
  })
  return labels.join(', ')
}

function convertValuesToLabels(refrenceArray: { label: string; value: string }[], value: string) {
  const label = refrenceArray.find((item) => item.value === value)?.label
  if (label) return label
  return ''
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Ttype = {
  [key: string]: number | string
}

function getComparator<Key extends keyof Ttype>(
  order: 'asc' | 'desc',
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[] | unknown[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export function shortenFileName(name: string, length = 23) {
  const ext = name.split('.').pop()
  const fileName = name.split('.').slice(0, -1).join('.')
  if (fileName.length > length) {
    return `${fileName.substring(0, length)}...${ext}`
  }
  return name
}

export function getObjectUrl(file: File) {
  return URL.createObjectURL(file)
}

export function firstNameWithFirstLetterOfLastName(assignee: {
  firstName?: string
  lastName?: string
}): string {
  if (!assignee) return ''
  if (!assignee.firstName && !assignee.lastName) return ''
  if (!assignee.firstName && assignee.lastName) return assignee.lastName
  if (!assignee.lastName && assignee.firstName) return assignee.firstName
  if (!assignee.lastName) return ''
  return `${assignee.firstName} ${assignee.lastName[0]}.`
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function generateDefaultDense(key: string) {
  const value = localStorage.getItem(key)
  if (value === 'true') return true
  if (value === 'false') return false
  return false
}

export function isValidUUID(uuid: string): boolean {
  // Simple UUID validation, adjust as needed based on your requirements
  const uuidRegex = /^[a-f0-9]{8}-[a-f0-9]{4}-[4][a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}$/i
  return uuidRegex.test(uuid)
}

export function hasPermission(permissionDetailsFields: string[] | undefined) {
  if (!permissionDetailsFields) return () => false
  return (field: string) => {
    if (permissionDetailsFields.includes(field)) return true
    return false
  }
}

const makeMultipartFormData = (mapFileFields, formValues) => {
  const formData = new FormData()

  let mapIndex = 1
  const map = {}

  mapFileFields.forEach((fileField) => {
    if (!formValues[fileField.name]?.create) return
    formValues[fileField.name].create.forEach((_, fileIndex: number) => {
      formData.append(
        'files',
        formValues[fileField.name].create[fileIndex][fileField.source].upload
      )
      formValues[fileField.name].create[fileIndex][fileField.source].upload = null
      map[mapIndex] = [
        `variables.data.${fileField.name}.create.${fileIndex}.${fileField.source}.upload`,
      ]
      mapIndex += 1
    })
  })

  formData.append('variables', JSON.stringify({ data: formValues }))
  formData.append('map', JSON.stringify(map))

  return formData
}

export {
  cleanDate,
  getComparator,
  stableSort,
  fixImgSrc,
  useDidMount,
  multipleSelectConvertValuesToLabels,
  convertValuesToLabels,
  makeMultipartFormData,
}
