/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import NorthEastIcon from '@mui/icons-material/NorthEast'
import SouthWestIcon from '@mui/icons-material/SouthWest'
import './styles.scss'
import { Line, LineChart, ResponsiveContainer } from 'recharts'

interface InfoCardProps {
  Icon: React.ElementType
  graph?: boolean
  currency?: string
  size?: string
  subtitle: string | JSX.Element
  data?: any
  value: number
  onFilterChange?: (value: string) => void
}

interface GrowthIconProps {
  hasGrowth: boolean
}

interface InfoProps {
  number: number
  subtitle: string | JSX.Element
  currency?: string
  hasGrowth?: boolean
}

interface MonthProps {
  name: string
  value: number
}

const GrowthIcon: React.FC<GrowthIconProps> = ({ hasGrowth }) => {
  return hasGrowth ? (
    <NorthEastIcon className="growthArrow" />
  ) : (
    <SouthWestIcon className="growthArrow" />
  )
}

// TODO: Rremove hardcoded logic when real data is present
const InfoCard: React.FC<InfoCardProps> = ({
  Icon,
  graph = false,
  currency = '',
  size = 'xl',
  subtitle = '',
  data = [],
  value,
  onFilterChange,
}) => {
  const defaultFilter = 'Y'
  const [filter, setFilter] = useState(defaultFilter)
  const [dataChart, setDataChart] = useState<MonthProps[]>()
  const hasGrowth = true
  const isYearTab = filter === 'Y'
  const bigSize = size === 'xl'
  const smallSize = size === 's'
  const isEmpty = value <= 0

  const handleFilter = () => {
    const toggle = isYearTab ? 'M' : 'Y'
    setFilter(toggle)
    onFilterChange && onFilterChange(toggle)
  }

  const countItemsByMonth = (items) => {
    if (!items) {
      return
    }

    const countsByMonth: MonthProps[] = []
    for (const item of items) {
      const date = new Date(item.createdAt)
      const month = date.toLocaleString('de-DE', { month: 'short' })
      const index = countsByMonth.length
        ? countsByMonth.findIndex((mont) => mont.name === month)
        : -1

      if (index >= 0) {
        countsByMonth[index] = { ...countsByMonth[index], value: countsByMonth[index].value + 1 }
      } else {
        countsByMonth.push({ name: month, value: 1 })
      }
    }

    return countsByMonth
  }

  useEffect(() => {
    onFilterChange && onFilterChange(defaultFilter)
  }, [])

  useEffect(() => {
    setDataChart(countItemsByMonth(isYearTab ? data?.yearlyOrders : data.monthlyOrders))
  }, [data.monthlyOrders, data?.yearlyOrders, isYearTab])

  const setTabClass = (type: string): string => {
    const isActive = type === filter

    const tabClassesActive = `tab ${isActive && ' active'}` // check this logic
    const tabClassesDisabled = `tab ${isActive ? 'activeDisable' : 'disableTab'}`
    const tabClasses = isEmpty ? tabClassesDisabled : tabClassesActive

    return tabClasses
  }
  const Info: React.FC<InfoProps> = ({
    number = 0,
    currency = '',
    hasGrowth = false,
    subtitle = '',
  }) => {
    const setFormat = number ? number.toLocaleString('de-DE') : '0,00'
    const formatNumber = currency ? setFormat : number

    return (
      <div className="info" style={{ marginLeft: smallSize ? '16px' : 0 }}>
        <div className={`numberWrap ${bigSize ? 'big' : 'small'}`}>
          <p
            className="number"
            style={{ fontSize: smallSize ? '16px' : '' }}
          >{`${formatNumber} ${currency}`}</p>
          {hasGrowth && number ? (
            <GrowthIcon hasGrowth={hasGrowth} />
          ) : (
            <p className="notAssigned">{smallSize ? '' : 'N/A'}</p>
          )}
        </div>
        <span
          className="subtitle"
          style={{ marginTop: smallSize ? '4px' : 0, fontSize: smallSize ? '12px' : 'inherit' }}
        >
          {subtitle}
        </span>
      </div>
    )
  }

  return (
    <>
      <div
        className="cardHeader"
        style={{ justifyContent: bigSize ? 'space-between' : 'flex-start' }}
      >
        <Icon className={`icon ${isEmpty && 'disable'}`} />
        {smallSize && <Info number={value} subtitle={subtitle} />}
        {graph && bigSize && (
          <div className="cardGraphTabs">
            <p className={setTabClass('M')} onClick={handleFilter}>
              Month
            </p>
            <p className={setTabClass('Y')} onClick={handleFilter}>
              Year
            </p>
          </div>
        )}
      </div>
      {bigSize && (
        <div className="cardBody">
          <Grid item container>
            <Grid item xs={graph ? 5 : 6}>
              <Info number={value} currency={currency} hasGrowth={hasGrowth} subtitle={subtitle} />
            </Grid>

            <Grid item xs={graph ? 7 : 8}>
              {graph &&
                (value > 0 ? (
                  <ResponsiveContainer width="100%" height="100%" maxHeight={68}>
                    <LineChart width={300} height={100} data={dataChart}>
                      <Line
                        type="monotone"
                        dataKey="value"
                        stroke="#220d77"
                        strokeWidth={2}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="defaultGraph">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="205"
                      height="52"
                      viewBox="0 0 205 52"
                      fill="none"
                    >
                      <path
                        d="M1.42285 50C11.4941 50 193.352 50 203.423 50"
                        stroke="#A4A8B3"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                ))}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default InfoCard
