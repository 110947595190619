// project import
import { NavItemType } from '../types'
import other from './other'
// import pages from './pages'

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [
    other,
    //  pages
  ],
}

export default menuItems
