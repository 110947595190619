import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { FormikErrors } from 'formik'

interface Props {
  open: boolean
  setOpen: (state: boolean) => void
  submit: () => void
  setFieldValue: (field: string, value: any) => Promise<void> | Promise<FormikErrors<any>>
}

const NotificationDialog: React.FC<Props> = ({ open, setOpen, submit, setFieldValue }) => {
  const onSubmit = async (notification: boolean) => {
    await setFieldValue('notifyUser', notification)
    submit()
    setOpen(false)
  }
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <FormattedMessage id="automatic-mail-msg" />
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flexBasis="100%">
          <Box>
            <Button variant="text" onClick={() => setOpen(false)}>
              Go back to editing
            </Button>
          </Box>
          <Box display="flex" gap="8px">
            <Button variant="outlined" onClick={() => onSubmit(false)}>
              Proceed without sending the mail
            </Button>
            <Button variant="contained" onClick={() => onSubmit(true)}>
              Proceed
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default NotificationDialog
