import { FC } from 'react'
import { Stack, Typography } from '@mui/material'
import RiseIcon from '../../../../../../assets/images/icons/rise.svg'
import FallIcon from '../../../../../../assets/images/icons/fall.svg'
import type { InfoCardProps } from './InfoCard.types'
import { IconWrapper, StyledCard } from './InfoCard.styles'

const InfoCard: FC<InfoCardProps> = ({ icon, title, value, growth }) => (
  <StyledCard>
    <Stack gap="10px">
      <IconWrapper>{icon}</IconWrapper>
      <Stack direction="row" gap="10px" alignItems="center">
        <Typography variant="h2">{value}</Typography>
        <img src={growth ? RiseIcon : FallIcon} />
      </Stack>
      <Typography variant="body2">{title}</Typography>
    </Stack>
  </StyledCard>
)

export default InfoCard
