import type { FC } from 'react'
import CustomerDashboard from './CustomerDashboard'
import InternalDashboard from './InternalDashboard'
import { useSelector } from '../../store/store'
import { USER_ROLES } from '../../constants'

const Dashboard: FC = () => {
  const role = useSelector((state) => state.user.userInfo.role)
  if (!role) throw new Error('Role is not defined')
  let Component: FC | null = () => null

  switch (true) {
    case [USER_ROLES.ADMIN.value, USER_ROLES.INTERNAL_USER.value].includes(role || ''):
      Component = InternalDashboard
      break

    case [
      USER_ROLES.EXTERNAL_DIRECT_CUSTOMER.value,
      USER_ROLES.EXTERNAL_RESELLER.value,
      USER_ROLES.EXTERNAL_RESELLER_CUSTOMER.value,
      USER_ROLES.EXTERNAL_USER_WHITE_LABEL.value,
    ].includes(role || ''):
      Component = CustomerDashboard
      break

    default:
      break
  }

  return <Component />
}

export default Dashboard
