import type { FC } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import type { PrivateRouteProps } from './PrivateRoute.types'
import { useSelector } from '../../store/store'
import { PAGE } from '../../constants'

const PrivateRoute: FC<PrivateRouteProps> = ({ allowedRoles, children }) => {
  const { role } = useSelector((state) => state.user.userInfo)
  const location = useLocation()

  if (role && allowedRoles.includes(role)) {
    return <>{children}</>
  }

  return <Navigate to={PAGE.DASHBOARD.PATH} state={{ from: location }} replace />
}

export default PrivateRoute
