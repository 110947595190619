import { Box, Container, Modal } from '@mui/material'

const imageFormats = ['gif', 'png', 'jpg', 'jpeg']
const audioFormats = ['mp3']
const videoFormats = ['mp4']

const ImageComponent = ({ src }) => {
  return <img src={src} alt="preview" />
}

const AudioComponent = ({ src }) => {
  return <audio controls src={src}></audio>
}

const VideoComponent = ({ src }) => {
  return (
    <video controls>
      <source src={src} type="video/mp4" />
    </video>
  )
}

const FilePreviewModal = ({ open, setOpen, fixedFileSource, fileType }) => {
  let Component

  switch (true) {
    case imageFormats.includes(fileType):
      Component = ImageComponent
      break

    case videoFormats.includes(fileType):
      Component = VideoComponent
      break

    case audioFormats.includes(fileType):
      Component = AudioComponent
      break

    default:
      Component = null
      break
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container maxWidth="md" sx={{ height: '100%' }}>
        <Box
          className="mediaFiles"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          {Component ? <Component src={fixedFileSource} /> : null}
        </Box>
      </Container>
    </Modal>
  )
}

export default FilePreviewModal
