import { FormEvent, useEffect, useState } from 'react'
import { Ipermission } from 'common-recruspin-codebase/types/permission'
import useSWR from 'swr'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import BoxWrapper from '../../components/BoxWrapper'
import axiosInstance from '../../utils/axiosInstance'
import Loading from '../../ui-component/loading/Loading'
import { PermissionSettings } from './permissionSettings/PermissionSettings'
import './style.scss'
import { CustomTabPanel, a11yProps } from '../../components/CustomerTabPanel/CustomTabPanel'
import SuccessModal from '../../ui-component/SuccessModal'
import FormErrorsModal from '../../ui-component/FormErrorsModal'

export type Permissions = {
  reseller: Ipermission
  resellerCustomer: Ipermission
  directCustomer: Ipermission
}

const AccountsAndPermissions = () => {
  const intl = useIntl()
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [errorsModalOpen, setErrorsModalOpen] = useState(false)
  const [formikErrors, setFormikErrors] = useState<string[]>([])
  const [mutationLoading, setMutationLoading] = useState(false)

  const [value, setValue] = useState(0)
  const { data: permissions, isLoading, error } = useSWR('/universalPermissions', axiosInstance)
  const data: Permissions = permissions?.data.permissions

  const [resellerPermissions, setResellerPermissions] = useState<Ipermission>(data?.reseller)
  const [resellerCustomerPermissions, setResellerCustomerPermissions] = useState<Ipermission>(
    data?.resellerCustomer
  )
  const [directCustomerPermissions, setDirectCustomerPermissions] = useState<Ipermission>(
    data?.directCustomer
  )

  useEffect(() => {
    if (data && !resellerPermissions) {
      setResellerPermissions(data.reseller)
      setResellerCustomerPermissions(data.resellerCustomer)
      setDirectCustomerPermissions(data.directCustomer)
    }
  }, [data, resellerPermissions])

  const saveSettings = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    delete resellerPermissions.id
    delete resellerCustomerPermissions.id
    delete directCustomerPermissions.id

    const newData = {
      reseller: resellerPermissions,
      resellerCustomer: resellerCustomerPermissions,
      directCustomer: directCustomerPermissions,
    }
    const isChanged = JSON.stringify(newData) !== JSON.stringify(data)
    if (!isChanged) return

    try {
      setMutationLoading(true)
      await axiosInstance.post('/universalPermissions', newData)
      setSuccessModalOpen(true)
    } catch (error) {
      setErrorsModalOpen(true)
      setFormikErrors([String(error)])
    } finally {
      setMutationLoading(false)
    }
  }

  if (isLoading || mutationLoading) return <Loading />
  if (error) return <div>{error.message}</div>
  if (!resellerPermissions) return <div>no data</div>

  return (
    <div className="AccountsAndPermissions">
      <form onSubmit={saveSettings}>
        <BoxWrapper>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={(_e, v) => setValue(v)} aria-label="basic tabs example">
              <Tab label="Reseller" {...a11yProps(0)} />
              <Tab label="Reseller Customer" {...a11yProps(1)} />
              <Tab label="Direct Customer" {...a11yProps(2)} />
              <div className="buttons">
                <Button className="save" variant="outlined" type="submit">
                  <FormattedMessage id="cancel" />
                </Button>
                <Button className="save" variant="contained" type="submit">
                  <FormattedMessage id="Save" />
                </Button>
              </div>
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <PermissionSettings data={resellerPermissions} setData={setResellerPermissions} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <PermissionSettings
              data={resellerCustomerPermissions}
              setData={setResellerCustomerPermissions}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <PermissionSettings
              data={directCustomerPermissions}
              setData={setDirectCustomerPermissions}
            />
          </CustomTabPanel>
        </BoxWrapper>
      </form>
      <SuccessModal
        successModalOpen={successModalOpen}
        handleClose={() => setSuccessModalOpen(false)}
        setSuccessModalOpen={setSuccessModalOpen}
        message={intl.formatMessage({ id: 'updated-successfully' })}
      />
      <FormErrorsModal
        errorsModalOpen={errorsModalOpen}
        formikErrors={formikErrors}
        setErrorsModalOpen={setErrorsModalOpen}
      />
    </div>
  )
}

export default AccountsAndPermissions
