import { FC, useState } from 'react'
import { Bar, BarChart, LabelList, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { Typography } from '@mui/material'
import { StyledCard } from './InfoChart.styles'
import { InfoChartProps } from './InfoChart.types'

export const InfoChart: FC<InfoChartProps> = ({ title, data }) => {
  const [chartWidth, setChartWidth] = useState(0)

  return (
    <StyledCard style={{ height: '240px' }}>
      <Typography sx={{ mb: '20px' }} variant="h3">
        {title}
      </Typography>
      <ResponsiveContainer onResize={(width) => setChartWidth(width - 20)}>
        <BarChart
          margin={{
            top: 0,
            right: 30,
            left: -50,
            bottom: 0,
          }}
          data={data}
          layout="vertical"
        >
          <XAxis type="number" axisLine={false} tick={false} />
          <YAxis type="category" axisLine={false} tick={false} orientation="left" />
          <Bar dataKey="value" fill="#220D77" background={{ fill: '#D3CFE4' }} barSize={5}>
            <LabelList dataKey="value" position="left" offset={-chartWidth} />
            <LabelList dataKey="name" position="insideBottomLeft" offset={10} />
          </Bar>
          <Legend />
        </BarChart>
      </ResponsiveContainer>
    </StyledCard>
  )
}

export default InfoChart
