import React from 'react'
import { signOut } from 'supertokens-auth-react/recipe/emailpassword'
import './style.scss'
import { Button, Container, Typography } from '@mui/material'

type Props = {
  message: string
}

export default function Unauthorized({ message }: Props) {
  return (
    <Container className="unauthorized">
      <div className="wrapper">
        <Typography variant="h4">{message}</Typography>
        <Button
          variant="contained"
          color="secondary"
          autoFocus
          size="large"
          type="submit"
          sx={{ width: '120px', marginTop: '16px' }}
          onClick={async () => {
            await signOut()
            window.location.reload()
          }}
        >
          Sign out
        </Button>
      </div>
    </Container>
  )
}
