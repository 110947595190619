import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import './styles.scss'
import { FormattedMessage } from 'react-intl'
import PlaceHolderRender from '../../../components/PlaceHolderRender'
import { Customer, Iorder } from '../InternalDashboard'
import { customerTypes } from '../../customers/create/CreateCustomer'

function createData(
  id: string,
  price: string,
  jobType: string,
  jobName: string,
  channel: Array<string>,
  budget: string,
  runtime: number,
  createdAt: string,
  customer: Customer
): Iorder {
  return {
    id,
    price,
    jobType,
    jobName,
    channel,
    budget,
    runtime,
    createdAt,
    customer,
  }
}
interface MonthlyOrdersProps {
  data: Iorder[]
}

const MonthlyTasks: React.FunctionComponent<MonthlyOrdersProps> = ({ data }) => {
  const [rows, setRows] = React.useState<Iorder[]>()
  const newArray = Array.from({ length: 7 }, (_, index) => index * 2)

  React.useEffect(() => {
    if (data.length) {
      const dataRows = data?.map((item) => {
        const { id, customer, price, jobName, jobType, channel, budget, runtime, createdAt } = item

        return createData(
          id,
          price,
          jobType,
          jobName,
          channel,
          budget,
          runtime,
          createdAt,
          customer
        )
      })
      setRows(dataRows)
    }
  }, [data])

  return (
    <TableContainer component={Paper}>
      {rows?.length ? (
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="customer" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="order" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="portal" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="budget" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="runtime" />
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ overflowY: 'auto' }}>
            {rows?.map(({ id, jobName, jobType, customer, channel, budget, runtime }) => (
              <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  <div className="monthlyTask">
                    <div className="company">
                      {customer?.name ? customer?.name?.charAt(0) : 'U'}
                    </div>
                    <div className="taskInfo">
                      <p className="taskTitle">{customer?.name || 'N/A'}</p>
                      <p className="taskType">
                        {customerTypes.find((i) => i.value === customer.type)?.label}
                      </p>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="taskInfo">
                    <p className="taskTitle">{jobName}</p>
                    <p
                      className="taskType"
                      style={{
                        maxWidth: '200px',
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <span key={id} style={{ marginRight: '5px' }}>
                        {jobType}
                      </span>
                    </p>
                  </div>
                </TableCell>
                <TableCell>
                  <div style={{ maxWidth: '150px', display: 'flex', flexWrap: 'wrap' }}>
                    {channel?.map((item) => (
                      <span key={item} style={{ marginRight: '5px' }}>
                        {item}
                      </span>
                    ))}
                  </div>
                </TableCell>
                <TableCell>{budget}</TableCell>
                <TableCell>{runtime} days</TableCell>
                <TableCell>
                  <MoreHorizIcon />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <PlaceHolderRender height="12px" />
              </TableCell>
              <TableCell>
                <PlaceHolderRender height="12px" />
              </TableCell>
              <TableCell>
                <PlaceHolderRender height="12px" />
              </TableCell>
              <TableCell>
                <PlaceHolderRender height="12px" />
              </TableCell>
              <TableCell>
                <PlaceHolderRender height="12px" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ overflowY: 'auto' }}>
            {newArray?.map((row) => (
              <TableRow key={row} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  <PlaceHolderRender width="32px" height="32px" />
                </TableCell>
                <TableCell>
                  <PlaceHolderRender height="12px" />
                </TableCell>
                <TableCell>
                  <PlaceHolderRender height="12px" />
                </TableCell>
                <TableCell>
                  <PlaceHolderRender height="12px" />
                </TableCell>
                <TableCell>
                  <PlaceHolderRender height="12px" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  )
}

export default MonthlyTasks
