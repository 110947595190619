import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Collapse, IconButton, TableCell, TableRow } from '@mui/material'
import { FC, useState } from 'react'
import type { RowProps } from './Row.types'
import { AdsTable } from './components'

const Row: FC<RowProps> = ({
  id,
  name,
  clicks,
  ctr,
  linkCTR,
  linkClicks,
  avgPlaybackTime,
  devices,
  impressions,
  frequency,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{clicks}</TableCell>
        <TableCell>{linkClicks}</TableCell>
        <TableCell>{linkCTR}</TableCell>
        <TableCell>{impressions}</TableCell>
        <TableCell>{ctr}</TableCell>
        <TableCell>{frequency}</TableCell>
        <TableCell>{devices.join(', ')}</TableCell>
        <TableCell>{avgPlaybackTime}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <AdsTable adSetId={id} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default Row
