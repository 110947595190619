import {
  ResetPasswordUsingToken,
  SignInAndUp,
} from 'supertokens-auth-react/recipe/emailpassword/prebuiltui'
import './style.scss'
import Logo from '../../../ui-component/Logo'

export const SignInSignUp = () => {
  return (
    <div className="SignInSignUp">
      <div className="supertokens">
        <div className="brand">
          <Logo />
        </div>
        <SignInAndUp />
      </div>
    </div>
  )
}

export const ResetPassword = () => {
  return (
    <div className="SignInSignUp">
      <div className="supertokens">
        <div className="brand">
          <Logo />
        </div>
        <ResetPasswordUsingToken />
      </div>
    </div>
  )
}
