// material-ui
// import { useTheme } from "@mui/material/styles"

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

interface Props {
  width?: number
  height?: number
}

const Logo = ({ width = 160, height = 32 }: Props) => {
  // const theme = useTheme()

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
     *
     */
    <svg
      width={width}
      height={height}
      viewBox="0 0 855 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M163.995 106.934V35.6969C165.457 34.7575 168.014 33.505 171.667 31.9393C175.425 30.3737 179.756 29.0168 184.662 27.8686C189.568 26.6161 194.526 25.9898 199.536 25.9898C203.293 25.9898 206.268 26.303 208.46 26.9292C210.756 27.4511 212.531 28.1295 213.783 28.9646V43.9948C211.696 43.6817 209.295 43.4208 206.581 43.212C203.972 43.0033 201.258 42.9511 198.44 43.0554C195.726 43.0554 193.064 43.1598 190.455 43.3686C187.95 43.5773 185.758 43.8383 183.879 44.1514V106.934H163.995Z"
        fill="#292A2C"
      />
      <path
        d="M263.042 109.283C255.214 109.283 248.377 107.717 242.532 104.586C236.687 101.35 232.147 96.6529 228.911 90.4947C225.676 84.3365 224.058 76.9257 224.058 68.2624C224.058 54.4847 227.346 43.9948 233.921 36.7928C240.601 29.4864 249.943 25.8333 261.946 25.8333C270.61 25.8333 277.551 27.712 282.77 31.4696C288.093 35.2272 291.955 40.6026 294.355 47.5958C296.861 54.4847 298.061 62.7305 297.956 72.3331H236.896L235.33 58.8685H282.143L278.386 64.5049C278.177 56.2591 276.716 50.3618 274.002 46.813C271.393 43.1598 267.165 41.3332 261.32 41.3332C257.771 41.3332 254.64 42.1682 251.926 43.8383C249.317 45.4039 247.282 48.0655 245.82 51.8231C244.463 55.5807 243.785 60.7473 243.785 67.323C243.785 75.4644 245.716 81.727 249.578 86.1109C253.44 90.3903 259.337 92.53 267.27 92.53C270.505 92.53 273.584 92.2169 276.507 91.5907C279.534 90.86 282.3 90.025 284.805 89.0856C287.414 88.1462 289.606 87.259 291.381 86.424V103.959C287.728 105.525 283.552 106.777 278.856 107.717C274.263 108.761 268.992 109.283 263.042 109.283Z"
        fill="#292A2C"
      />
      <path
        d="M354.42 108.656C349.201 108.656 344.191 107.926 339.39 106.464C334.589 105.003 330.309 102.655 326.552 99.4189C322.794 96.1832 319.819 92.0082 317.627 86.8937C315.436 81.7792 314.34 75.5688 314.34 68.2624C314.34 60.6429 315.383 54.1716 317.471 48.8484C319.558 43.5251 322.429 39.1935 326.082 35.8534C329.735 32.409 333.962 29.9561 338.764 28.4949C343.669 26.9292 348.836 26.1464 354.264 26.1464C358.439 26.1464 362.718 26.5639 367.102 27.3989C371.486 28.2339 375.4 29.4343 378.844 30.9999V48.2221C375.296 46.6564 371.747 45.4039 368.198 44.4645C364.649 43.4208 360.996 42.8989 357.238 42.8989C349.932 42.8989 344.243 44.7255 340.173 48.3787C336.102 52.0318 334.067 58.0335 334.067 66.3837C334.067 74.9426 335.893 81.3095 339.547 85.4846C343.304 89.6597 349.462 91.7472 358.021 91.7472C361.883 91.7472 365.641 91.1731 369.294 90.025C372.947 88.7725 376.183 87.4156 379.001 85.9543V103.333C375.348 105.107 371.538 106.412 367.572 107.247C363.605 108.187 359.222 108.656 354.42 108.656Z"
        fill="#292A2C"
      />
      <path
        d="M399.828 106.934V35.6969C401.289 34.7575 403.846 33.505 407.5 31.9393C411.257 30.3737 415.589 29.0168 420.494 27.8686C425.4 26.6161 430.358 25.9898 435.368 25.9898C439.126 25.9898 442.1 26.303 444.292 26.9292C446.589 27.4511 448.363 28.1295 449.616 28.9646V43.9948C447.528 43.6817 445.127 43.4208 442.414 43.212C439.804 43.0033 437.09 42.9511 434.272 43.0554C431.558 43.0554 428.897 43.1598 426.287 43.3686C423.782 43.5773 421.59 43.8383 419.712 44.1514V106.934H399.828Z"
        fill="#292A2C"
      />
      <path
        d="M503.644 109.283C496.338 109.283 489.971 108.134 484.543 105.838C479.22 103.437 475.045 99.8886 472.018 95.1917C469.095 90.4947 467.634 84.493 467.634 77.1867V28.0252H487.518V75.1513C487.518 80.9964 488.822 85.328 491.432 88.1462C494.041 90.9644 498.112 92.3735 503.644 92.3735C509.385 92.3735 513.508 90.9644 516.013 88.1462C518.622 85.328 519.927 80.9964 519.927 75.1513V28.0252H538.715V77.1867C538.715 88.0418 535.583 96.131 529.321 101.454C523.162 106.673 514.604 109.283 503.644 109.283Z"
        fill="#292A2C"
      />
      <path
        d="M586.898 109.283C581.261 109.283 576.46 108.656 572.494 107.404C568.527 106.256 564.717 104.847 561.064 103.176V85.328C563.465 86.8937 565.97 88.4072 568.579 89.8684C571.293 91.2253 574.164 92.3213 577.191 93.1563C580.322 93.9913 583.662 94.4088 587.211 94.4088C591.386 94.4088 594.465 93.626 596.448 92.0603C598.536 90.3903 599.579 88.4072 599.579 86.1109C599.579 83.919 598.797 82.2489 597.231 81.1008C595.665 79.8483 593.63 78.8045 591.125 77.9695C588.62 77.1345 585.958 76.2995 583.14 75.4644C580.531 74.8382 577.869 74.0032 575.155 72.9594C572.546 71.9156 570.145 70.5065 567.953 68.7321C565.761 66.9577 563.987 64.7136 562.63 61.9998C561.377 59.1816 560.751 55.7894 560.751 51.8231C560.751 47.1261 561.691 43.1598 563.569 39.9241C565.448 36.6884 567.901 34.079 570.928 32.0959C573.955 30.1127 577.295 28.7036 580.948 27.8686C584.601 26.9292 588.15 26.4595 591.595 26.4595C597.544 26.6683 602.345 27.3989 605.999 28.6514C609.652 29.7996 613.096 31.313 616.332 33.1918L616.488 49.318C612.835 47.3349 609.286 45.5605 605.842 43.9948C602.398 42.3248 598.483 41.4898 594.1 41.4898C590.029 41.4898 586.845 42.2204 584.549 43.6817C582.357 45.0386 581.261 46.9696 581.261 49.4746C581.261 51.6665 581.94 53.4409 583.297 54.7978C584.653 56.1547 586.428 57.1985 588.62 57.9291C590.812 58.6598 593.212 59.3904 595.822 60.121C598.431 60.8517 601.145 61.6867 603.963 62.6261C606.781 63.4611 609.391 64.7136 611.791 66.3837C614.192 68.0537 616.123 70.2978 617.584 73.116C619.15 75.8298 619.933 79.4829 619.933 84.0755C619.828 90.1294 618.211 95.0351 615.079 98.7927C611.948 102.446 607.825 105.107 602.711 106.777C597.701 108.448 592.43 109.283 586.898 109.283Z"
        fill="#292A2C"
      />
      <path
        d="M680.31 109.126C678.535 109.126 676.134 108.917 673.107 108.5C670.185 108.187 667.158 107.508 664.027 106.464C661 105.421 658.338 103.907 656.042 101.924L660.582 95.6613V136.838H641.012V43.9948C641.012 40.6548 642.003 37.8366 643.986 35.5403C646.074 33.244 648.788 31.4174 652.128 30.0605C655.572 28.5993 659.382 27.5555 663.557 26.9292C667.836 26.1986 672.272 25.8333 676.865 25.8333C684.38 25.8333 691.06 27.138 696.905 29.7474C702.75 32.3568 707.395 36.7928 710.84 43.0554C714.284 49.318 716.006 57.8247 716.006 68.5756C716.006 76.7169 714.597 83.8668 711.779 90.025C708.961 96.0788 704.89 100.776 699.567 104.116C694.244 107.456 687.825 109.126 680.31 109.126ZM675.299 92.9997C679.892 92.9997 683.702 92.1125 686.729 90.3381C689.86 88.5637 692.156 85.8499 693.618 82.1967C695.183 78.4392 695.966 73.7422 695.966 68.1059C695.966 63.0958 695.392 58.6598 694.244 54.7978C693.2 50.9359 691.217 47.9611 688.294 45.8736C685.476 43.6817 681.458 42.5857 676.239 42.5857C671.124 42.5857 667.21 43.1076 664.496 44.1514C661.887 45.1952 660.582 46.6564 660.582 48.5352V89.0856C662.879 90.3381 665.331 91.3297 667.941 92.0603C670.55 92.6866 673.003 92.9997 675.299 92.9997Z"
        fill="#292A2C"
      />
      <path
        d="M737.115 106.934V28.808H756.998L757.155 106.934H737.115ZM736.645 16.2828V0H757.468V16.2828H736.645Z"
        fill="#292A2C"
      />
      <path
        d="M782.587 34.6009C784.779 33.5571 787.388 32.5134 790.415 31.4696C793.442 30.4258 796.73 29.5386 800.279 28.808C803.828 27.973 807.376 27.3467 810.925 26.9292C814.578 26.4073 818.023 26.1464 821.259 26.1464C828.565 26.1464 834.619 27.138 839.42 29.1211C844.326 30.9999 847.979 34.0268 850.38 38.2019C852.885 42.2726 854.137 47.648 854.137 54.3281V106.934H834.41V57.7726C834.41 55.7894 834.149 53.9106 833.627 52.1362C833.105 50.2574 832.218 48.6396 830.966 47.2827C829.713 45.8214 827.991 44.7255 825.799 43.9948C823.607 43.1598 820.789 42.7423 817.344 42.7423C814.735 42.7423 812.073 43.0032 809.36 43.5251C806.646 44.047 804.349 44.7255 802.471 45.5605V106.934H782.587V34.6009Z"
        fill="#292A2C"
      />
      <mask id="mask0_35_2" maskUnits="userSpaceOnUse" x="0" y="8" width="115" height="30">
        <path
          d="M114.18 34.6094C114.18 36.3791 112.746 37.8107 110.979 37.8107H3.2013C1.43162 37.8107 0 36.3791 0 34.6094V12.2003C0 10.4306 1.43162 8.99902 3.2013 8.99902H110.979C112.746 8.99902 114.18 10.4306 114.18 12.2003V34.6094Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_35_2)">
        <path d="M55.9414 37.8107L51.7272 8.99902H0V37.8107H55.9414Z" fill="#220D77" />
      </g>
      <mask id="mask1_35_2" maskUnits="userSpaceOnUse" x="0" y="8" width="115" height="30">
        <path
          d="M114.18 34.6094C114.18 36.3791 112.746 37.8107 110.979 37.8107H3.2013C1.43162 37.8107 0 36.3791 0 34.6094V12.2003C0 10.4306 1.43162 8.99902 3.2013 8.99902H110.979C112.746 8.99902 114.18 10.4306 114.18 12.2003V34.6094Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_35_2)">
        <path d="M58.2385 8.99902L62.4527 37.8107H114.177V8.99902H58.2385Z" fill="#CCCCCC" />
      </g>
      <mask id="mask2_35_2" maskUnits="userSpaceOnUse" x="0" y="51" width="115" height="30">
        <path
          d="M114.18 77.2954C114.18 79.0651 112.746 80.4967 110.979 80.4967H3.2013C1.43162 80.4967 0 79.0651 0 77.2954V54.8864C0 53.1167 1.43162 51.6851 3.2013 51.6851H110.979C112.746 51.6851 114.18 53.1167 114.18 54.8864V77.2954Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_35_2)">
        <path d="M78.7756 80.4967L74.5614 51.6851H0V80.4967H78.7756Z" fill="#220D77" />
      </g>
      <mask id="mask3_35_2" maskUnits="userSpaceOnUse" x="0" y="51" width="115" height="30">
        <path
          d="M114.18 77.2954C114.18 79.0651 112.746 80.4967 110.979 80.4967H3.2013C1.43162 80.4967 0 79.0651 0 77.2954V54.8864C0 53.1167 1.43162 51.6851 3.2013 51.6851H110.979C112.746 51.6851 114.18 53.1167 114.18 54.8864V77.2954Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_35_2)">
        <path d="M81.073 51.6851L85.2872 80.4967H114.189V51.6851H81.073Z" fill="#CCCCCC" />
      </g>
      <mask id="mask4_35_2" maskUnits="userSpaceOnUse" x="0" y="94" width="115" height="30">
        <path
          d="M114.18 119.975C114.18 121.745 112.746 123.176 110.979 123.176H3.2013C1.43162 123.176 0 121.745 0 119.975V97.5658C0 95.7961 1.43162 94.3645 3.2013 94.3645H110.979C112.746 94.3645 114.18 95.7961 114.18 97.5658V119.975Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask4_35_2)">
        <path
          d="M33.0379 123.245L28.8237 94.4337L-0.0744629 94.3645V123.176L33.0379 123.245Z"
          fill="#220D77"
        />
      </g>
      <mask id="mask5_35_2" maskUnits="userSpaceOnUse" x="0" y="94" width="115" height="30">
        <path
          d="M114.18 119.975C114.18 121.745 112.746 123.176 110.979 123.176H3.2013C1.43162 123.176 0 121.745 0 119.975V97.5658C0 95.7961 1.43162 94.3645 3.2013 94.3645H110.979C112.746 94.3645 114.18 95.7961 114.18 97.5658V119.975Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask5_35_2)">
        <path
          d="M35.3352 94.4337L39.5494 123.245L114.157 123.176V94.3645L35.3352 94.4337Z"
          fill="#CCCCCC"
        />
      </g>
    </svg>
  )
}

export default Logo
