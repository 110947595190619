/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit'

interface Table {
  selected: string[]
  type: string
}

const initialState: Table = {
  type: '',
  selected: [],
}

const table = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setType(state, action) {
      state.type = action.payload
    },
    setSelected(state, action) {
      state.selected = action.payload
    },

    removeSelected(state) {
      state.selected = initialState.selected
    },
  },
})

export default table.reducer

export const { setType, setSelected, removeSelected } = table.actions
