import { TableTypes } from '../../../AdsetsKPIs/components'

export const COLUMNS: TableTypes.Column[] = [
  {
    id: 'portal',
    name: 'Portal',
  },
  {
    id: 'campaignName',
    name: 'Campaign Name',
  },
  {
    id: 'createdTime',
    name: 'Created',
  },
]
