import { FC, useState } from 'react'
import {
  Button,
  Card,
  Divider,
  IconButton,
  Stack,
  TablePagination,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { Add, Search } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { IconFilter } from '@tabler/icons-react'
import { Table } from './components'
import useCampaignsList from './hooks/useCampaignsList'

const CampaignList: FC = () => {
  const {
    page,
    total,
    isLoading,
    campaigns,
    rowsPerPage,
    searchValue,
    handleSearchChange,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useCampaignsList()

  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <Card>
      <Stack sx={{ p: '30px' }} direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h3">Campaign List</Typography>
        <Button size="large" startIcon={<Add />} variant="outlined">
          <FormattedMessage id="new-campaign" />
        </Button>
      </Stack>
      <Divider />
      <Stack sx={{ p: '30px' }}>
        <Toolbar>
          <TextField
            sx={{ width: '257px' }}
            variant="outlined"
            type="text"
            size="small"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchChange}
            InputProps={{ startAdornment: <Search /> }}
          />
          <Tooltip onClick={() => setDrawerOpen(!drawerOpen)} title="Filter list">
            <IconButton>
              <IconFilter size="20px" />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Table
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          data={campaigns}
          isLoading={isLoading}
        />

        <TablePagination
          component="div"
          count={total}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
    </Card>
  )
}

export default CampaignList
