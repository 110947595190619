import { createSlice } from '@reduxjs/toolkit'

interface Dialog {
  open: boolean
}

const initialState: Dialog = {
  open: false,
}

// Add filters and remove filters
const dialog = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    dialogToggle(state, action) {
      state.open = action.payload
    },
  },
})

export default dialog.reducer

export const { dialogToggle } = dialog.actions
