import { Button } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import axiosInstance from '../../../../../utils/axiosInstance'
import { setTriggerAppUsersRefetch } from '../../../../../store/slices/general'
import { useDispatch } from '../../../../../store/store'

interface AuthenticateButtonProps {
  isAuthenticated: boolean
  id: string
}

const AuthenticateButton = ({ isAuthenticated, id }: AuthenticateButtonProps) => {
  const [defaultValue, setDefaultValue] = React.useState(isAuthenticated)
  const dispatch = useDispatch()
  const submit = async () => {
    const res = await axiosInstance.post(`/app-users/authenticate/${id}`)
    if (res.status === 200) {
      setDefaultValue(true)
      dispatch(setTriggerAppUsersRefetch(true))
    }
  }
  return (
    <div>
      <Button onClick={() => submit()} variant="contained" color="primary" disabled={defaultValue}>
        {defaultValue ? (
          <FormattedMessage id="authenticated" />
        ) : (
          <FormattedMessage id="authenticate" />
        )}
      </Button>
    </div>
  )
}

export default AuthenticateButton
