import OrdersRow from './ordersRow'

interface RowRenderProps {
  rows: unknown[] | never[]
  checkbox: boolean
  isSelected: (id: string) => boolean
}

const RowRender = ({ rows = [], isSelected = () => false, checkbox = false }: RowRenderProps) => {
  return (
    <>
      {rows.map((row: any, index: number) => {
        const isItemSelected = isSelected(row.id)
        const labelId = `enhanced-table-checkbox-${index}`
        return (
          <OrdersRow
            row={row}
            labelId={labelId}
            isItemSelected={isItemSelected}
            checkbox={checkbox}
            key={row.id}
          />
        )
      })}
    </>
  )
}

export default RowRender
