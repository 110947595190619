import { Box, Paper, styled } from '@mui/material'

export const Container = styled(Paper)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: '24px',
  padding: '15px',
  width: 'fit-content',
  maxWidth: '90%',
  outline: 'none',
  borderRadius: '8px',
}))

export const Header = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px',
}))
