import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography, useTheme } from '@mui/material'
import { IconPlus } from '@tabler/icons-react'
import './style.scss'

interface Iprops {
  item: string
  handleAddItem: () => void
}

const AddNewItem: React.FC<Iprops> = ({ item, handleAddItem }) => {
  const theme = useTheme()
  return (
    <div className="AddNewItem">
      <Typography
        className="textField"
        variant="h4"
        sx={{ color: theme.palette.primary.main, p: 3 }}
        onClick={handleAddItem}
      >
        <IconPlus /> &nbsp; <FormattedMessage id="add" /> {item}
      </Typography>
    </div>
  )
}

export default AddNewItem
