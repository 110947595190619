import { useMemo } from 'react'
import { useSelector } from '../store/store'
import { USER_ROLES } from '../constants'

type UseInternalUserPermissions = () => { isInternalUser: boolean }

const useInternalUserPermissions: UseInternalUserPermissions = () => {
  const { role } = useSelector((state) => state?.user.userInfo)

  const isInternalUser = useMemo(
    () =>
      [
        USER_ROLES.ADMIN.value,
        USER_ROLES.INTERNAL_USER.value,
        USER_ROLES.INTERNAL_USER_LIMITED.value,
      ].includes(role || ''),
    [role]
  )

  return { isInternalUser }
}

export default useInternalUserPermissions
