// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.77.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.77.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_header__u4QWF {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.style_title__yck-6 {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding-left: 24px;\n  width: 260px;\n}\n.style_dialogTitle__vDLnQ {\n  padding: 0px;\n  color: #434243;\n  font-size: 20px;\n  font-weight: 700;\n}\n\n.style_status__-B-Sf {\n  color: #ffc107;\n  font-size: 12px;\n  font-weight: 500;\n  margin-left: 10px;\n  display: flex;\n  padding: 4px 8px;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  border-radius: 100px;\n  background: var(--rs_yellow_10, #fff9e7);\n  white-space: pre;\n}", "",{"version":3,"sources":["webpack://./src/components/Dialog/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;AAEF;AAEA;EACE,YAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AAAF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,gBAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,oBAAA;EACA,wCAAA;EACA,gBAAA;AACF","sourcesContent":[".header {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n.title {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding-left: 24px;\n  width: 260px;\n  h3 {\n  }\n}\n.dialogTitle {\n  padding: 0px;\n  color: #434243;\n  font-size: 20px;\n  font-weight: 700;\n}\n.status {\n  color: #ffc107;\n  font-size: 12px;\n  font-weight: 500;\n  margin-left: 10px;\n  display: flex;\n  padding: 4px 8px;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  border-radius: 100px;\n  background: var(--rs_yellow_10, #fff9e7);\n  white-space: pre;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "style_header__u4QWF",
	"title": "style_title__yck-6",
	"dialogTitle": "style_dialogTitle__vDLnQ",
	"status": "style_status__-B-Sf"
};
export default ___CSS_LOADER_EXPORT___;
