import { useEffect, useState } from 'react'
import { Button, Container, Drawer, IconButton, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dayjs } from 'dayjs'
import { IconX } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from '../../../../../../../store/store'
import { AnalyticsFilterParams } from '../../../../../../../store/slices/filters/type'
import { setAnalyticsFilterQueryParams } from '../../../../../../../store/slices/filters/filters'

interface Iprops {
  drawerOpen: boolean
  setDrawerOpen: (drawerOpen: boolean) => void
}

export function AnalyticsFilterDrawer({ drawerOpen = false, setDrawerOpen }: Iprops) {
  return (
    <Drawer
      className="filterDrawer"
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <Container disableGutters className="drawerContainer" style={{ padding: 16 }}>
        <Container className="header" style={{ padding: 0 }}>
          <Typography variant="h3">Filter</Typography>
          <IconButton
            size="medium"
            edge="start"
            color="inherit"
            onClick={() => setDrawerOpen(false)}
            aria-label="close"
          >
            <IconX />
          </IconButton>
        </Container>
        <Container style={{ padding: 0 }}>
          <Filters />
        </Container>
      </Container>
    </Drawer>
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const [date, setDate] = useState<Dayjs | null>(null)

  useEffect(() => {
    const raw = date?.toDate()
    const createdAt = raw?.toDateString()

    const params: AnalyticsFilterParams = {
      createdAt,
    }
    dispatch(setAnalyticsFilterQueryParams(params))
  }, [date, dispatch])

  function clearFilters() {
    setDate(null)
  }

  return (
    <form>
      <Typography>
        <FormattedMessage id="date-created" />
      </Typography>
      <DatePicker
        disableFuture
        sx={{ width: '100%' }}
        format="DD/MM/YYYY"
        value={date}
        onChange={(newValue) => setDate(newValue)}
        slotProps={{
          actionBar: {
            actions: ['clear'],
          },
        }}
      />
      <div className="clear">
        <Button onClick={() => clearFilters()} className="clearButt" fullWidth variant="outlined">
          Clear all
        </Button>
      </div>
    </form>
  )
}
