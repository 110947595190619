export const USER_ROLES = {
  ADMIN: { label: 'Admin', value: 'admin' },
  INTERNAL_USER: { label: 'Internal user', value: 'internalUser' },
  INTERNAL_USER_LIMITED: { label: 'Internal user limited', value: 'internalUserLimited' },
  EXTERNAL_DIRECT_CUSTOMER: { label: 'External Direct Customer', value: 'externalDirectCustomer' },
  EXTERNAL_RESELLER_CUSTOMER: {
    label: 'External Reseller Customer',
    value: 'externalPartnerCustomer',
  }, // reseller customer
  EXTERNAL_RESELLER: { label: 'External Reseller', value: 'externalReseller' },
  EXTERNAL_USER_WHITE_LABEL: {
    label: 'External User White label',
    value: 'externalUserWhiteLabel',
  },
}

export const appUserRoles = [
  { label: 'Admin', value: 'admin' },
  { label: 'Internal user', value: 'internalUser' },
  { label: 'Internal user limited', value: 'internalUserLimited' },
  { label: 'External Direct Customer', value: 'externalDirectCustomer' },
  { label: 'External Reseller Customer', value: ' externalPartnerCustomer' },
  { label: 'External Reseller', value: 'externalReseller' },
  { label: 'External User White label', value: 'externalUserWhiteLabel' },
]

export const isCustomer = (role: string | undefined) => {
  return (
    role === USER_ROLES.EXTERNAL_DIRECT_CUSTOMER.value ||
    role === USER_ROLES.EXTERNAL_RESELLER_CUSTOMER.value ||
    role === USER_ROLES.EXTERNAL_RESELLER.value
  )
}

// todo: from crc
export const isEndCustomer = (role: string | undefined) => {
  return (
    role === USER_ROLES.EXTERNAL_DIRECT_CUSTOMER.value ||
    role === USER_ROLES.EXTERNAL_RESELLER_CUSTOMER.value
  )
}

export const CUSTOMER_TYPES = {
  PARTNER: { label: 'Reseller', value: 'partner' },
  RESELLER_CUSTOMER: { label: 'Reseller customer', value: 'resellerCustomer' },
  DIRECT: { label: 'Direct', value: 'direct' },
  WHITE_LABEL: { label: 'White lable', value: 'whiteLable' },
} // todo: from crc
