import { Button, Card, Stack } from '@mui/material'
import { FC } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { PAGE } from '../../constants'
import { StyledLink } from './Analytics.styled'

const Analytics: FC = () => {
  const { id: campaignId } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const handleGoToCampaignList = () => navigate('/analytics')

  return (
    <Stack>
      {campaignId && (
        <Card sx={{ p: '30px 20px' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap="20px">
            <Stack alignItems="center" direction="row" gap="20px">
              <StyledLink
                active={pathname.includes('dashboard')}
                to={`${PAGE.ANALYTICS.PATH}/dashboard/${campaignId}`}
              >
                <FormattedMessage id="dashboard" />
              </StyledLink>
              <StyledLink
                active={pathname.includes('performance')}
                to={`${PAGE.ANALYTICS.PATH}/performance/${campaignId}`}
              >
                <FormattedMessage id="adsets-kpis" />
              </StyledLink>
            </Stack>
            <Button size="large" type="button" variant="outlined" onClick={handleGoToCampaignList}>
              <FormattedMessage id="back-to-campaigns" />
            </Button>
          </Stack>
        </Card>
      )}
      <Outlet />
    </Stack>
  )
}

export default Analytics
