import commonRecruspinCodebase from 'common-recruspin-codebase'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  Box,
  Container,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { IconX } from '@tabler/icons-react'
import { useQuery } from '@apollo/client'
import { FormattedMessage } from 'react-intl'
import {
  capitalizeFirstLetter,
  cleanDate,
  convertValuesToLabels,
  firstNameWithFirstLetterOfLastName,
  generateDefaultDense,
} from '../../../utils/tools'
import Loading from '../../../ui-component/loading/Loading'
import { historyHeadCells, orderHistoryStyle } from '../../../constants'
import queries from '../../../graphQL/query'

const { orderHistoryEvents } = commonRecruspinCodebase.history.order

interface Props {
  id: string
  handleClose: () => void
}

const defaultDense = generateDefaultDense('tasksHistoryTableDensePadding')

export const History: React.FC<Props> = ({ id, handleClose }) => {
  const [dense, setDense] = React.useState(defaultDense)

  const { data: ordersHistory, loading: loadingOrderDetails } = useQuery(queries.readTaskHistory, {
    variables: { where: { id } },
  })

  const cleanNameCB = React.useCallback((user) => {
    return firstNameWithFirstLetterOfLastName(user)
  }, [])
  const cleanDateCB = React.useCallback((date) => {
    return cleanDate(date)
  }, [])
  const convertValuesToLabelsCB = React.useCallback((event) => {
    return convertValuesToLabels(orderHistoryEvents, event)
  }, [])
  const capitalizeFirstLetterCB = React.useCallback((action) => {
    return capitalizeFirstLetter(action)
  }, [])

  const handleChangeDensCB = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked)
    localStorage.setItem('tasksHistoryTableDensePadding', String(event.target.checked))
  }, [])

  if (loadingOrderDetails) return <Loading />

  return (
    <div className="History">
      <Box className="history" sx={orderHistoryStyle}>
        <Container className="title">
          <Typography id="modal-modal-title" variant="h4">
            History
          </Typography>
          <IconButton
            size="medium"
            edge="start"
            color="inherit"
            onClick={() => handleClose()}
            aria-label="close"
          >
            <IconX color="gray" />
          </IconButton>
        </Container>
        <Divider />
        <Container className="tableContainer">
          <TableContainer style={{ maxHeight: 450 }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              stickyHeader
            >
              <EnhancedTableHead />
              <TableBody>
                {ordersHistory.task.taskHistory.map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={uuidv4()}>
                      <TableCell align="left">{cleanDateCB(row.date)}</TableCell>
                      <TableCell align="left">
                        {row.externalMutator || cleanNameCB(row.user)}
                      </TableCell>
                      <TableCell align="left">{convertValuesToLabelsCB(row.event)}</TableCell>
                      <TableCell align="left">{capitalizeFirstLetterCB(row.action)}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Container className="densePadding">
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDensCB} />}
              label="Dense padding"
            />
          </Container>
        </Container>
      </Box>
    </div>
  )
}

const EnhancedTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        {historyHeadCells.map((headCell) => (
          <TableCell key={headCell.id}>
            <FormattedMessage id={headCell.label} />
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
