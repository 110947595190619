import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { AppBar, Button, Dialog, IconButton, Toolbar, Typography } from '@mui/material'
import { IconX } from '@tabler/icons-react'
import { useEffect } from 'react'
import { useSelector } from '../../store/store'
import {
  setCloseCustomerModal,
  setIsEditActiveForDialog,
  setIsSaveForDialogTriggered,
  setOpenCustomerModal,
} from '../../store/slices/general'
import { isValidUUID } from '../../utils/tools'

interface Iprops {
  children: (id: string) => JSX.Element
  type: 'customer' | 'lead' | 'order' | 'campaign'
}

const DialogWithUrlParams = ({ children, type }: Iprops) => {
  const { pathname } = window.location
  const pathParts = pathname.split('/')
  const idIndex = 2
  const id = pathParts.length > idIndex ? pathParts[idIndex] : null
  const isUsersPath = pathname.includes('/customers/')
  const isRelated = isUsersPath && id && isValidUUID(id)

  const { isCustomerModalOpen, isEditActiveForDialog } = useSelector((state) => state.general)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isRelated) {
      dispatch(setOpenCustomerModal())
    }
  }, [dispatch, isRelated])

  const handleClose = () => {
    const path = `/${type}s`
    const { referrer } = document
    if (referrer) {
      navigate(path)
    }
    dispatch(setCloseCustomerModal({ path }))
  }

  const headerText = `${type.slice(0, 1).toUpperCase() + type.slice(1, type.length)} Details`

  if (!id) return null

  return (
    <Dialog fullScreen open={isCustomerModalOpen} className="ReadEditCustomer">
      <AppBar sx={{ position: 'sticky' }} color="default">
        <Toolbar>
          <IconButton
            size="medium"
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <IconX size={'50px'} color="gray" />
          </IconButton>
          <Typography sx={{ ml: 3, flex: 1, textAlign: 'center' }} variant="h3" component="div">
            {headerText}{' '}
          </Typography>
          {isEditActiveForDialog ? (
            <Button
              color="secondary"
              autoFocus
              size="large"
              onClick={() => dispatch(setIsSaveForDialogTriggered(true))}
            >
              <FormattedMessage id="save" />
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              autoFocus
              size="large"
              onClick={(e) => {
                e.preventDefault()
                dispatch(setIsEditActiveForDialog(true))
              }}
            >
              <FormattedMessage id="edit" />
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {children(id)}
    </Dialog>
  )
}

export default DialogWithUrlParams
