import Axios from 'axios'
import { Button, Container, TextField } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import FormErrorsModal from '../../../../ui-component/FormErrorsModal'
import SuccessModal from '../../../../ui-component/SuccessModal'

const passwordRequirements = yup
  .string()
  .required('Required')
  .min(8, 'Min 8 characters')
  .max(32, 'Max 32 characters')
const validationSchemaSecurity = yup.object({
  currentPassword: yup.string().required('Required'),
  newPassword: passwordRequirements,
  newPasswordConfirm: passwordRequirements,
})

const UserSecurity: FC = () => {
  const [errorsModalOpen, setErrorsModalOpen] = useState(false)
  const [formikErrors, setFormikErrors] = useState<string[]>([])
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const intl = useIntl()

  const formikSecurity = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    validationSchema: validationSchemaSecurity,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const url = `${process.env.REACT_APP_SERVER_URL}/changePassword`
      const res = await Axios.post(url, values)
      if (res.data === 'OK') setSuccessModalOpen(true)

      if (res.data !== 'OK') {
        setFormikErrors([JSON.stringify(res.data)])
        setErrorsModalOpen(true)
      }
    },
  })

  async function handleSubmitSecurity(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const unitedPassword =
      formikSecurity.values.newPassword === formikSecurity.values.newPasswordConfirm
    if (!unitedPassword) {
      formikSecurity.setFieldError('newPasswordConfirm', 'Not match')
      return
    }

    const errors = Object.values(formikSecurity.errors)
    if (errors.length) {
      setErrorsModalOpen(true)
      setFormikErrors(errors as string[])
      return
    }

    formikSecurity.handleSubmit()
  }

  return (
    <div className="security">
      <Container className="fields">
        <form onSubmit={(e) => handleSubmitSecurity(e)}>
          <div className="metadata">
            <TextField
              id="currentPassword"
              name="currentPassword"
              onChange={formikSecurity.handleChange}
              defaultValue={formikSecurity.values.currentPassword}
              fullWidth
              label={intl.formatMessage({ id: 'current-password' })}
              variant="outlined"
              error={!!formikSecurity.errors.currentPassword}
              helperText={formikSecurity.errors.currentPassword}
              type="password"
            />
            <div className="firstLastName">
              <TextField
                onChange={formikSecurity.handleChange}
                defaultValue={formikSecurity.values.newPassword}
                id="newPassword"
                name="newPassword"
                fullWidth
                label={intl.formatMessage({ id: 'new-password' })}
                error={!!formikSecurity.errors.newPassword}
                helperText={formikSecurity.errors.newPassword}
                type="password"
              />
              <TextField
                onChange={formikSecurity.handleChange}
                defaultValue={formikSecurity.values.newPasswordConfirm}
                fullWidth
                label={intl.formatMessage({ id: 'confirm-new-password' })}
                id="newPasswordConfirm"
                name="newPasswordConfirm"
                error={!!formikSecurity.errors.newPasswordConfirm}
                helperText={formikSecurity.errors.newPasswordConfirm}
                type="password"
              />
            </div>
          </div>
          <Container className="submit">
            <Button type="submit" variant="contained">
              <FormattedMessage id="change-password" />
            </Button>
          </Container>
        </form>
      </Container>
      <FormErrorsModal
        errorsModalOpen={errorsModalOpen}
        formikErrors={formikErrors}
        setErrorsModalOpen={setErrorsModalOpen}
      />
      <SuccessModal
        successModalOpen={successModalOpen}
        handleClose={() => setSuccessModalOpen(false)}
        setSuccessModalOpen={setSuccessModalOpen}
        message={intl.formatMessage({ id: 'edited-successfully' })}
      />
    </div>
  )
}

export default UserSecurity
