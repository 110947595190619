import { createSlice } from '@reduxjs/toolkit'

interface IfilterBy {
  where: { createdAt?: string; assignee?: { id: string }; jobType?: string }
}

interface IinitialState {
  language: string
  customerSearchValue: string
  ordersSearchValue: string
  triggerOrdersRefetch: boolean
  triggerCustomersRefetch: boolean
  triggerAppUsersRefetch: boolean
  ordersSearchBy: string
  ordersFilterBy?: IfilterBy | 'clear'
  ordersQueryWhereInput?: unknown
  isCustomerModalOpen: boolean
  isEditActiveForDialog: boolean
  isSaveForDialogTriggered?: boolean
  isOrderTableOverview: boolean
}

const initialState: IinitialState = {
  language: localStorage.getItem('locale') || 'en',
  customerSearchValue: '',
  ordersSearchValue: '',
  triggerOrdersRefetch: false,
  triggerCustomersRefetch: false,
  triggerAppUsersRefetch: false,
  ordersSearchBy: 'order name',
  isCustomerModalOpen: false,
  isEditActiveForDialog: false,
  isSaveForDialogTriggered: false,
  isOrderTableOverview: false,
}

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setLanguageState(state, action) {
      state.language = action.payload
      localStorage.setItem('locale', action.payload)
    },
    setCustomerSearchValue(state, action) {
      state.customerSearchValue = action.payload
    },
    setOrdersSearchValue(state, action) {
      state.ordersSearchValue = action.payload
    },
    setTriggerOrdersRefetch(state, action) {
      state.triggerOrdersRefetch = action.payload
    },
    setTriggerCustomersRefetch(state, action) {
      state.triggerCustomersRefetch = action.payload
    },
    setTriggerAppUsersRefetch(state, action) {
      state.triggerAppUsersRefetch = action.payload
    },
    setOrdersSearchBy(state, action) {
      state.ordersSearchBy = action.payload
    },
    setOrdersFilterBy(state, action) {
      state.ordersFilterBy = action.payload
    },
    setOrdersQueryWhereInput(state, action) {
      state.ordersQueryWhereInput = action.payload
    },
    setCloseCustomerModal(state, action) {
      const { path } = action.payload
      state.isCustomerModalOpen = false
      state.isEditActiveForDialog = false

      window.history.replaceState({}, '', path)
    },
    setOpenCustomerModal(state) {
      state.isCustomerModalOpen = true
    },
    setIsEditActiveForDialog(state, action) {
      state.isEditActiveForDialog = action.payload
    },
    setIsSaveForDialogTriggered(state, action) {
      state.isSaveForDialogTriggered = action.payload
    },
    setIsOrderTableOverview(state, action) {
      state.isOrderTableOverview = action.payload
    },
  },
})

export default generalSlice.reducer
export const {
  setTriggerCustomersRefetch,
  setTriggerAppUsersRefetch,
  setOrdersQueryWhereInput,
  setOrdersFilterBy,
  setLanguageState,
  setCustomerSearchValue,
  setTriggerOrdersRefetch,
  setOrdersSearchBy,
  setOrdersSearchValue,
  setCloseCustomerModal,
  setOpenCustomerModal,
  setIsEditActiveForDialog,
  setIsSaveForDialogTriggered,
  setIsOrderTableOverview,
} = generalSlice.actions
