import { Card, styled } from '@mui/material'

export const StyledCard = styled(Card)(() => ({
  padding: '20px',
}))

export const Legend = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'scroll',
  height: '55%',

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  '-ms-overflow-style': 'none',
  scrollbarWidth: 'none',
}))

export const LegendItem = styled('div')(() => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #eee',
  padding: '12px 0px',

  '.name': {
    color: '#757575',
    margin: 0,
    fontSize: '10px',
  },
  '.number': {
    color: '#757575',
    fontSize: '10px',
    margin: 0,
  },
}))

export const Thumbnail = styled('img')(() => ({
  width: '25px',
  height: 'auto',
  cursor: 'pointer',
}))
