import { styled } from '@mui/material'
import { Link } from 'react-router-dom'

export const StyledLink = styled(Link, { shouldForwardProp: (prop) => prop !== 'active' })<{
  active: boolean
}>(({ active }) => ({
  color: '#616161',
  textDecoration: 'none',
  fontWeight: 500,
  fontSize: '14px',
  height: '25px',
  ...(active && { borderBottom: '2px solid #220D77', color: '#220D77' }),
}))
