import { useState, useEffect } from 'react'
import { AppBar, Dialog, Toolbar, Typography, Container, IconButton } from '@mui/material'
import useSWR from 'swr'
import { useLocation } from 'react-router-dom'
import useEmblaCarousel from 'embla-carousel-react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import { OrderPreview } from '../../types/task'
import Loading from '../../ui-component/loading/Loading'
import Logo from '../../ui-component/Logo'
import axiosInstance from '../../utils/axiosInstance'

import './style.scss'
import Thumb from './Thumb'
import Slide from './Slide'

const Preview = () => {
  const location = useLocation()
  const id = new URLSearchParams(location?.search).get('id')
  const { data, isLoading } = useSWR(`/order-previews?id=${id}`, axiosInstance)
  const [slideIndex, setSlideIndex] = useState<number>(0)

  const [emblaRef, emblaApi] = useEmblaCarousel()
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({ axis: 'y' })

  const images: OrderPreview[] = data?.data?.previews || []
  const audios: OrderPreview[] = data?.data?.previewsAudio || []
  const videos: OrderPreview[] = data?.data?.previewsVideo || []
  const slides: OrderPreview[] = [...images, ...audios, ...videos]

  const next = () => {
    let newIndex = slideIndex + 1
    if (newIndex > slides.length - 1) {
      newIndex = 0
    }
    emblaApi?.scrollNext()
    emblaThumbsApi?.scrollTo(newIndex)
    setSlideIndex(newIndex)
  }
  const prev = () => {
    let newIndex = slideIndex - 1
    if (newIndex < 0) {
      newIndex = slides.length - 1
    }
    emblaApi?.scrollPrev()
    emblaThumbsApi?.scrollTo(newIndex)
    setSlideIndex(newIndex)
  }

  const goToSlide = (index) => {
    emblaApi?.scrollTo(index)
    emblaThumbsApi?.scrollTo(index)
    setSlideIndex(index)
  }

  useEffect(() => {
    if (emblaApi)
      emblaApi.on('select', (api) => {
        const index = api.selectedScrollSnap()
        emblaThumbsApi?.scrollTo(index)
        setSlideIndex(index)
      })
  }, [emblaApi])

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        )
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    })
  }, [])

  return (
    <Dialog fullScreen open>
      <AppBar sx={{ position: 'sticky' }} color="default">
        <Toolbar>
          <Typography sx={{ ml: 3, flex: 1, textAlign: 'center' }} variant="h3" component="div">
            <Logo />
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" className="OrderPreview">
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <div className="embla-thumb-wrap">
              <div className="embla-thumb" ref={emblaThumbsRef}>
                <div className="embla-thumb-container">
                  {slides.map((thumb, index) => {
                    return (
                      <div
                        onClick={() => goToSlide(index)}
                        className={`embla-thumb-slide ${
                          slideIndex === index ? 'embla-thumb-slide-active' : ''
                        }`}
                        key={`thumb-${index}`}
                      >
                        <Thumb thumb={thumb} />
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="embla-thumb-btns">
                <IconButton onClick={prev} disabled={!slideIndex}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
                <IconButton onClick={next} disabled={slideIndex === slides.length - 1}>
                  <KeyboardArrowRightIcon />
                </IconButton>
              </div>
            </div>
            <div className="embla" ref={emblaRef}>
              <div className="embla-container">
                {slides.map((slide, index) => {
                  return (
                    <div className="embla-slide" key={`slide-${index}`}>
                      <Slide slide={slide} />
                    </div>
                  )
                })}
              </div>
            </div>
          </>
        )}
      </Container>
    </Dialog>
  )
}

export default Preview
