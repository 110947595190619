import { gql } from '@apollo/client'

const customers = gql`
  query Query($where: CustomerWhereInput) {
    customers(where: $where) {
      id
      name
      type
      emails
      defaultEmail
      defaultInvoiceEmail
      defaultInvoiceEmail
      defaultPreviewEmail
      phoneNumbers
      address
      logo {
        url
      }
    }
  }
`
const orders = gql`
  query Query($where: TaskWhereInput) {
    tasks(where: $where) {
      id
      customer {
        id
        name
        type
        logo {
          url
        }
      }
      status
      jobType
      jobName
      gender
      place
      price
      createdAt
      channel
      budget
      runtime
      assignee {
        firstName
        lastName
      }
    }
  }
`
const orderDetails = gql`
  query Task($where: TaskWhereUniqueInput!) {
    task(where: $where) {
      id
      customer {
        id
        name
        type
        emails
      }
      jobType
      jobName
      gender
      place
      createdAt
      channel
      budget
      price
      jobAdLink
      externalImportId
      keywords
      comments
      status
      runtime
      externalImportId
      typebotCampaign {
        externalId
        editUrl
        url
        typebotLeadsCount
      }
      metaCampaign {
        id
        name
      }
      assignee {
        id
        firstName
        lastName
      }
      previews {
        id
        name
        image {
          id
          url
        }
      }
      previewsAudio {
        id
        image {
          url
          filename
        }
        name
      }
      previewsVideo {
        id
        image {
          url
          filename
        }
        name
      }
      imageAssets {
        name
        id
        image {
          id
          url
        }
      }
      invoices {
        id
        file {
          url
          filename
        }
        name
      }
      videoAssets {
        id
        file {
          url
          filename
        }
        name
      }
    }
  }
`
const assignees = gql`
  query Query {
    appUsers {
      id
      firstName
      lastName
    }
  }
`
const customerNames = gql`
  query Query {
    customers {
      id
      name
      emails
    }
  }
`
const createTask = gql`
  mutation Mutation($data: TaskCreateInput!) {
    createTask(data: $data) {
      id
      jobName
      jobType
      gender
      place
      createdAt
      channel
      budget
      price
      runtime
      jobAdLink
      externalImportId
      keywords
      comments
      status
      metaCampaign {
        id
        name
      }
      assignee {
        id
      }
      customer {
        id
      }
      invoices {
        id
        name
        file {
          url
          filename
        }
      }
      previews {
        image {
          url
        }
      }
      imageAssets {
        image {
          url
        }
      }
      videoAssets {
        file {
          url
          filename
        }
      }
    }
  }
`

const createPreviews = gql`
  mutation CreatePreviewImages($data: [PreviewImageCreateInput!]!) {
    createPreviewImages(data: $data) {
      image {
        id
        url
      }
    }
  }
`
const createImageAssets = gql`
  mutation CreateImageAssets($data: [ImageAssetsCreateInput!]!) {
    CreateImageAssets(data: $data) {
      image {
        id
        url
      }
    }
  }
`

const uploadPreviews = gql`
  mutation UpdateTask($where: TaskWhereUniqueInput!, $data: TaskUpdateInput!) {
    updateTask(where: $where, data: $data) {
      previews {
        id
      }
    }
  }
`

const uploadImageAssets = gql`
  mutation UpdateTask($where: TaskWhereUniqueInput!, $data: TaskUpdateInput!) {
    updateTask(where: $where, data: $data) {
      imageAssets {
        id
      }
    }
  }
`
const uploadInvoice = gql`
  mutation UpdateTask($where: TaskWhereUniqueInput!, $data: TaskUpdateInput!) {
    updateTask(where: $where, data: $data) {
      invoices {
        id
      }
    }
  }
`

const deletePreviewsImage = gql`
  mutation DeletePreviewImage($where: PreviewImageWhereUniqueInput!) {
    deletePreviewImage(where: $where) {
      image {
        id
      }
    }
  }
`

const deletePreviewsVideo = gql`
  mutation DeletePreviewVideo($where: PreviewVideoWhereUniqueInput!) {
    deletePreviewVideo(where: $where) {
      id
    }
  }
`

const deletePreviewsAudio = gql`
  mutation DeletePreviewAudio($where: PreviewAudioWhereUniqueInput!) {
    deletePreviewAudio(where: $where) {
      id
    }
  }
`

const deleteImageAsset = gql`
  mutation DeleteImageAssets($where: ImageAssetWhereUniqueInput!) {
    deleteImageAsset(where: $where) {
      image {
        id
      }
    }
  }
`

const updateCustomer = gql`
  mutation Mutation($where: CustomerWhereUniqueInput!, $data: CustomerUpdateInput!) {
    updateCustomer(where: $where, data: $data) {
      id
      name
      type
      emails
      phoneNumbers
      address
      logo {
        url
      }
    }
  }
`
const customerDetails = gql`
  query Query($where: CustomerWhereUniqueInput!) {
    customer(where: $where) {
      id
      name
      url
      type
      emails
      phoneNumbers
      automaticTransactionalEmails
      adAccountId
      defaultEmail
      defaultInvoiceEmail
      defaultPreviewEmail
      address
      connectedReseller {
        id
        name
      }
      logo {
        url
      }
    }
  }
`

const updateAppUser = gql`
  mutation UpdateAppUser($where: AppUserWhereUniqueInput!, $data: AppUserUpdateInput!) {
    updateAppUser(where: $where, data: $data) {
      email
      firstName
      lastName
      profileImage {
        url
      }
    }
  }
`

const deleteInvoice = gql`
  mutation DeleteInvoice($where: InvoiceWhereUniqueInput!) {
    deleteInvoice(where: $where) {
      file {
        url
      }
    }
  }
`
const deleteVideoAsset = gql`
  mutation DeleteVideoAsset($where: VideoAssetWhereUniqueInput!) {
    deleteVideoAsset(where: $where) {
      file {
        url
      }
    }
  }
`

const createOrderHistory = gql`
  mutation Mutation($data: TaskHistoryCreateInput!) {
    createTaskHistory(data: $data) {
      action
      event
      task {
        id
      }
      user {
        id
      }
    }
  }
`

const readTaskHistory = gql`
  query Task($where: TaskWhereUniqueInput!) {
    task(where: $where) {
      taskHistory {
        action
        date
        event
        externalMutator
        user {
          firstName
          lastName
        }
      }
    }
  }
`

const readLead = gql`
  query Query($where: MetaLeadWhereInput!) {
    metaLeads(where: $where) {
      fieldData
      platform
      status
      metaNotes
      metaLeadFiles {
        id
        file {
          url
          filename
        }
        name
      }
    }
  }
`
const createLead = gql`
  mutation CreateMetaLead($data: MetaLeadCreateInput!) {
    createMetaLead(data: $data) {
      id
    }
  }
`
const connectCampaignWithLead = gql`
  mutation Mutation($where: MetaCampaignWhereUniqueInput!, $data: MetaCampaignUpdateInput!) {
    updateMetaCampaign(where: $where, data: $data) {
      id
    }
  }
`
const updateLead = gql`
  mutation UpdateMetaLead($where: MetaLeadWhereUniqueInput!, $data: MetaLeadUpdateInput!) {
    updateMetaLead(where: $where, data: $data) {
      fieldData
      id
      metaNotes
      status
      platform
    }
  }
`

const deleteLeadFile = gql`
  mutation Mutation($where: MetaLeadFileWhereUniqueInput!) {
    deleteMetaLeadFile(where: $where) {
      file {
        url
      }
    }
  }
`
const instagram = gql`
  query Query {
    metaLeads {
      campaignName
      platform
    }
  }
`

const connectAppUserWithCustomer = gql`
  mutation Mutation($where: AppUserWhereUniqueInput!, $data: AppUserUpdateInput!) {
    updateAppUser(where: $where, data: $data) {
      customer {
        id
      }
    }
  }
`

const queries = {
  deleteInvoice,
  connectAppUserWithCustomer,
  updateLead,
  deleteLeadFile,
  readLead,
  createLead,
  connectCampaignWithLead,
  readTaskHistory,
  createOrderHistory,
  deleteVideoAsset,
  uploadInvoice,
  deletePreviewsImage,
  deletePreviewsVideo,
  deletePreviewsAudio,
  updateAppUser,
  customerDetails,
  updateCustomer,
  orderDetails,
  uploadPreviews,
  uploadImageAssets,
  deleteImageAsset,
  createImageAssets,
  createPreviews,
  createTask,
  customers,
  orders,
  customerNames,
  assignees,
  instagram,
}

export default queries
