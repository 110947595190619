import { InputBase, alpha, styled } from '@mui/material'
import Session from 'supertokens-auth-react/recipe/session'
import EmailVerification from 'supertokens-auth-react/recipe/emailverification'
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword'
import { ConfigProps } from './types/config'
import { LAYOUT_CONST } from './constants'

const supertokensStyle = `
  [data-supertokens~=container] {
        --palette-background: 255,255,255;
        --palette-inputBackground: 248, 248, 248;
        --palette-inputBorder: 34, 13, 119;
        --palette-textTitle: 34, 13, 119;
        --palette-textLabel: 34, 13, 119;
        --palette-textPrimary: 34, 13, 119;
        --palette-error: 244, 67, 54;
        --palette-textInput: 34, 13, 119;
        --palette-textLink: 30, 136, 229;
        --palette-primary: 34, 13, 119;
        --palette-primaryBorder: 34, 13, 119;
        margin-top: 0;
        margin: 0;
        }

        [data-supertokens~=superTokensBranding] {
          display: none;
        }
        [data-supertokens~=inputWrapper] {
          border-color: #A4A8B3;
          height: 45px;
        }
        [data-supertokens~=button] {
          background-color: 34, 13, 119;
          height: 38px;
        }

        [data-supertokens~=label] {
          display: none;
        }

        [data-supertokens~=formRow] {
          padding-bottom: 20px;
        }

        [data-supertokens~=row] {
          padding-top: 90px;
        }
                `

const verifyStyle = `
  [data-supertokens~=container] {
        --palette-background: 255,255,255;
        --palette-inputBackground: 248, 248, 248;
        --palette-inputBorder: 34, 13, 119;
        --palette-textTitle: 34, 13, 119;
        --palette-textLabel: 34, 13, 119;
        --palette-textPrimary: 34, 13, 119;
        --palette-error: 244, 67, 54;
        --palette-textInput: 34, 13, 119;
        --palette-textLink: 30, 136, 229;
        --palette-primary: 34, 13, 119;
        --palette-primaryBorder: 34, 13, 119;
        }

        [data-supertokens~=superTokensBranding] {
          display: none;
        }

        [data-supertokens~=button] {
          background-color: 34, 13, 119;
          height: 38px;
        }

        [data-supertokens~=row] {
          padding-top: 90px;
        }
                `

export const SuperTokensConfig = {
  appInfo: {
    appName: process.env.REACT_APP_APP_NAME as string,
    apiDomain: process.env.REACT_APP_SERVER_URL as string,
    websiteDomain: process.env.REACT_APP_FRONTEND_URL as string,
    apiBasePath: '/auth',
    websiteBasePath: '/auth',
  },

  recipeList: [
    EmailPassword.init({
      style: supertokensStyle,
      resetPasswordUsingTokenFeature: {
        disableDefaultUI: true,
      },
      getRedirectionURL: async (context) => {
        if (context.action === 'RESET_PASSWORD') {
          return '/reset-password'
        }
      },
    }),
    EmailVerification.init({
      mode: 'REQUIRED',
      style: verifyStyle,
    }),
    Session.init(),
  ],
}

export const HORIZONTAL_MAX_ITEM = 6

export const BASE_PATH = ''

const config: ConfigProps = {
  layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
  drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
  fontFamily: 'Roboto, sans-serif',
  borderRadius: 8,
  outlinedFilled: true,
  navType: 'light', // light, dark
  presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: false,
}

export const DASHBOARD_PATH = '/'

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}))

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}))

export default config
