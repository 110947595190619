import { TableRow, styled } from '@mui/material'

export const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': { border: 0 },
  cursor: 'pointer',

  '&:hover': {
    opacity: 0.8,
  },
}))
