import { Backdrop, Box, Button, Container, Fade, Modal, Typography } from '@mui/material'
import { IconAlertCircle } from '@tabler/icons-react'

export const modalStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '8px',
  p: 4,
}

interface IpropsAlertModal {
  alertModalOpen: boolean
  isAlert: boolean
  // setAlertModalOpen: (value: boolean) => void
  handleClose: () => void
  message: string
  setResult?: (value: boolean) => void
}
const AlertModal: React.FC<IpropsAlertModal> = ({
  handleClose,
  // setAlertModalOpen,
  alertModalOpen,
  isAlert = false,
  message,
  setResult = () => {},
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={alertModalOpen}
      onClose={() => {
        // setAlertModalOpen(false)
        handleClose()
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={alertModalOpen}>
        <Box sx={modalStyle}>
          <IconAlertCircle color="red" width={50} height={50} />
          <Typography
            className="successMsg"
            variant="h2"
            component="h2"
            color={isAlert ? 'red' : ''}
            sx={{ marginTop: '16px' }}
          >
            {message}
          </Typography>
          <Container sx={{ display: 'flex', justifyContent: 'space-around', marginTop: '16px' }}>
            {!isAlert && (
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    // setAlertModalOpen(false)
                    setResult(false)
                    handleClose()
                  }}
                  color="primary"
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    // setAlertModalOpen(false)
                    setResult(true)
                    handleClose()
                  }}
                  color="primary"
                >
                  Yes
                </Button>
              </>
            )}
          </Container>
        </Box>
      </Fade>
    </Modal>
  )
}

export default AlertModal
