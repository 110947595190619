import { Backdrop, Box, Button, Fade, Modal } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { modalStyle } from './SuccessModal'

interface FormErrorsModalProps {
  errorsModalOpen: boolean
  setErrorsModalOpen: (value: boolean) => void
  formikErrors: string[]
}

const FormErrorsModal: React.FC<FormErrorsModalProps> = ({
  errorsModalOpen,
  setErrorsModalOpen,
  formikErrors,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={errorsModalOpen}
      onClose={() => setErrorsModalOpen(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={errorsModalOpen}>
        <Box sx={modalStyle}>
          <ul>
            {formikErrors.map((i: string) => (
              <li key={i}>{i}</li>
            ))}
          </ul>
          <Button
            variant="contained"
            onClick={() => {
              setErrorsModalOpen(false)
            }}
            sx={{ mt: 2 }}
            color="primary"
          >
            <FormattedMessage id="close" />
          </Button>
        </Box>
      </Fade>
    </Modal>
  )
}

export default FormErrorsModal
