/* eslint-disable @typescript-eslint/no-explicit-any */
// material-ui
import { useEffect, useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import EuroIcon from '@mui/icons-material/Euro'
import { FormattedMessage, useIntl } from 'react-intl'
import useSWR from 'swr'
import NoteAltIcon from '@mui/icons-material/NoteAlt'
import EventIcon from '@mui/icons-material/Event'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import dayjs, { OpUnitType } from 'dayjs'
import { useSelector } from 'react-redux'
import { InfoCard } from '../../ui-component'
import YearlyGraph from './YearlyGraph'
import BoxWrapper from '../../components/BoxWrapper'
import OpenTasks from './OpenTasks'
import MonthlyTasks from './MonthlyTasks'
import TasksChart from './TasksChart'
import axiosInstance from '../../utils/axiosInstance'
import Loading from '../../ui-component/loading/Loading'

interface Logo {
  __typename: string
  url: string
}
export interface Customer {
  __typename?: string
  id?: string
  name?: string
  type?: string
  logo?: Logo
}
export interface Iorder {
  id: string
  customer: Customer
  price: string
  jobType: string
  jobName: string
  channel: string[]
  budget: string
  runtime: number
  createdAt: string
}
export interface OrdersProps {
  monthlyTurnOver: number
  yearlyTurnOver: number
  activeOrders: Iorder[]
  monthlyOrders: Iorder[]
  createInvoice: Iorder[]
  yearlyOrders: Iorder[]
  openOrders: Iorder[]
  orders: Iorder[]
  todoOrders: Iorder[]
  inProgressOrders: Iorder[]
  waitingForApprovalOrders: Iorder[]
  approvedOrders: Iorder[]
  materialCompleteOrders: Iorder[]
  size: number
}

const Dashboard = () => {
  const intl = useIntl()
  const { data: fetchedData, mutate, isLoading } = useSWR('/orders', axiosInstance)
  const data = fetchedData?.data?.orders

  const { triggerOrdersRefetch } = useSelector((state: any) => state.general)
  const [filter, setFilter] = useState()

  const [formatData, setFormatData] = useState<OrdersProps>({
    monthlyTurnOver: 0,
    yearlyTurnOver: 0,
    activeOrders: [],
    monthlyOrders: [],
    yearlyOrders: [],
    openOrders: [],
    todoOrders: [],
    inProgressOrders: [],
    materialCompleteOrders: [],
    createInvoice: [],
    approvedOrders: [],
    waitingForApprovalOrders: [],
    orders: [],
    size: 0,
  })

  function filterDate(items: any[], filterBy: OpUnitType | undefined) {
    const currentDate = dayjs()

    const filteredItems = items.filter((item) => {
      const itemDate = dayjs(item?.createdAt)
      return itemDate.isSame(currentDate, filterBy)
    })

    return filteredItems
  }

  useEffect(() => {
    if (triggerOrdersRefetch) mutate()
  }, [triggerOrdersRefetch, mutate])
  const sumPrice = (data: Iorder[]) => {
    return data.reduce((accumulator, currentValue) => {
      return accumulator + parseInt(currentValue.price, 10)
    }, 0)
  }
  useMemo(() => {
    if (!data) return

    const monthlyOrders = filterDate(data, 'month') || []
    const yearlyOrders = filterDate(data, 'year') || []

    const yearlyTurnOver = sumPrice(yearlyOrders)
    const monthlyTurnOver = sumPrice(monthlyOrders)

    const openOrders =
      monthlyOrders.filter(({ status }) =>
        ['inProgress', 'todo', 'materialComplete', 'waitingForApproval', 'createInvoice'].includes(
          status
        )
      ) || []

    const todoOrders = data.filter(({ status }) => status === 'todo') || []
    const active = data.filter(({ status }) => status === 'active') || []
    const createInvoice = data.filter(({ status }) => status === 'createInvoice') || []
    const activeOrders = data.filter(({ status }) => status === 'active') || []
    const inProgressOrders = data.filter(({ status }) => status === 'inProgress') || []
    const approvedOrders = data.filter(({ status }) => status === 'approved') || []
    const waitingForApprovalOrders =
      data.filter(({ status }) => status === 'waitingForApproval') || []
    const materialCompleteOrders = data.filter(({ status }) => status === 'materialComplete') || []
    const orders = data || []

    setFormatData((prev) => ({
      ...prev,
      active,
      createInvoice,
      monthlyOrders,
      yearlyOrders,
      monthlyTurnOver,
      yearlyTurnOver,
      openOrders,
      activeOrders,
      todoOrders,
      materialCompleteOrders,
      approvedOrders,
      waitingForApprovalOrders,
      inProgressOrders,
      orders,
      size: data.length,
    }))
  }, [data])

  const onFilterChange = (value) => {
    if (filter !== value) {
      setFilter(value)
    }
  }

  const totalOrdersValue =
    // eslint-disable-next-line no-nested-ternary
    filter === 'Y' ? formatData.yearlyOrders : filter === 'M' ? formatData.monthlyOrders : null

  isLoading && <Loading />

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <BoxWrapper>
            <InfoCard
              Icon={EuroIcon}
              graph={false}
              currency="€"
              subtitle={intl.formatMessage({ id: 'monthly-turnover' })}
              value={formatData.monthlyTurnOver}
            />
          </BoxWrapper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <BoxWrapper>
            <InfoCard
              Icon={AssignmentTurnedInIcon}
              graph
              subtitle={intl.formatMessage({ id: 'total-orders' })}
              data={formatData}
              value={totalOrdersValue?.length || 0}
              onFilterChange={onFilterChange}
            />
          </BoxWrapper>
        </Grid>
        <Grid
          item
          container
          direction={{ xs: 'row', md: 'row', lg: 'column' }}
          spacing={2}
          xs={12}
          md={12}
          lg={4}
        >
          <Grid item xs={6} md={6} lg={6}>
            <BoxWrapper padding="16px">
              <InfoCard
                Icon={NoteAltIcon}
                size="s"
                subtitle={<FormattedMessage id="active-orders" />}
                value={formatData.activeOrders.length}
              />
            </BoxWrapper>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <BoxWrapper padding="16px">
              <InfoCard
                Icon={EventIcon}
                size="s"
                subtitle={<FormattedMessage id="monthly-orders" />}
                value={formatData.monthlyOrders.length}
              />
            </BoxWrapper>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={8}>
          <BoxWrapper
            header
            title={<FormattedMessage id="yearly-turnover" />}
            value={formatData.yearlyTurnOver}
            currency="€"
            height="100"
          >
            <YearlyGraph data={formatData.yearlyOrders} />
          </BoxWrapper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <BoxWrapper
            header
            title={<FormattedMessage id="open-monthly-orders" />}
            height="100%"
            maxHeight={{ xs: '510px', md: '510px', xl: 'initial' }}
          >
            <OpenTasks tasks={formatData.openOrders} />
          </BoxWrapper>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          sx={{
            display: { xs: 'block', md: 'block', lg: 'none' },
          }}
        >
          <BoxWrapper header title={<FormattedMessage id="orders" />} height="100%">
            <TasksChart data={formatData} />
          </BoxWrapper>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2} sx={{ height: '100%', marginTop: '0px' }}>
        <Grid item xs={12} md={12} lg={8}>
          <BoxWrapper
            header
            title={<FormattedMessage id="monthly-orders" />}
            height="100%"
            maxHeight={{ md: '810px' }}
          >
            <MonthlyTasks data={formatData.monthlyOrders} />
          </BoxWrapper>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          sx={{
            display: { xs: 'none', md: 'none', lg: 'block' },
          }}
        >
          <BoxWrapper header title={<FormattedMessage id="orders" />} height="100%">
            <TasksChart data={formatData} />
          </BoxWrapper>
        </Grid>
      </Grid>
    </>
  )
}

export default Dashboard
