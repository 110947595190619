import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  FormControl,
  Alert,
  Autocomplete,
  DialogActions,
  Button,
  Box,
  CircularProgress,
  TextField,
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import { useFormik } from 'formik'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { AutocompleteChangeReason } from '@mui/base'
import DialogHeader from '../../../components/Dialog/header'
import axiosInstance from '../../../utils/axiosInstance'
import { TunsolvedAny } from '../../../types/unsolved'
import { RecruspinMetaCampaign } from '../../../types/metaTypes'
import { MetaCampaignStatuses } from '../../../constants/metaCampaign'

type Props = {
  open: boolean
  setOpen: (state: boolean) => unknown
  onMergeSuccess: () => void
}

const MergeModal: React.FC<Props> = ({ open, setOpen, onMergeSuccess }) => {
  const onClose = () => setOpen(false)
  const [error, setError] = useState<string>('')
  const { data: activeCampaigns, isLoading: activeCampaignsLoading } = useSWR(
    `/campaigns?status=${MetaCampaignStatuses.ACTIVE}&mergedTo=`,
    axiosInstance
  )
  const { data: archivedCampaigns, isLoading: archivedCampaignsLoading } = useSWR(
    `/campaigns?status=${MetaCampaignStatuses.ARCHIVED},${MetaCampaignStatuses.DELETED}&mergedTo=`,
    axiosInstance
  )
  const { trigger, isMutating } = useSWRMutation(
    '/campaigns/merge',
    async (url: string, { arg }: { arg: TunsolvedAny }) => {
      try {
        await axiosInstance.post(url, arg)
        setError('')
      } catch (error: TunsolvedAny) {
        setError(`${error?.message}. ${error.response.data.message}`)
      }
    },
    {
      onSuccess: () => {
        onMergeSuccess()
        onClose()
      },
    }
  )

  const intl = useIntl()
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      activeCampaign: null,
      archivedCampaign: null,
    },
    validationSchema: yup.object({
      activeCampaign: yup.string().required(),
      archivedCampaign: yup.string().required(),
    }),
    onSubmit: async (values) => {
      await trigger({
        sourceCampaign: values.archivedCampaign,
        targetCampaign: values.activeCampaign,
      })
    },
  })

  const onSubmit = () => {
    formik.handleSubmit()
  }

  const getOptionLabel = (option: RecruspinMetaCampaign) => option.name
  const isOptionEqualToValue = (option: RecruspinMetaCampaign, value: RecruspinMetaCampaign) =>
    option.id === value.id

  const onChange =
    (name: string) =>
    (_, value: RecruspinMetaCampaign | null, reason: AutocompleteChangeReason) => {
      switch (reason) {
        case 'selectOption':
          formik.setFieldValue(name, value?.id)
          break
        case 'clear':
          formik.setFieldValue(name, null)
          break

        default:
          break
      }
    }

  return (
    <>
      <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
        <DialogHeader handleClose={onClose} title={intl.formatMessage({ id: 'merge-campaigns' })} />
        {(!activeCampaignsLoading || !archivedCampaignsLoading) && (
          <>
            <DialogContent>
              <Box marginBottom="16px">
                <FormControl fullWidth>
                  <Autocomplete
                    options={activeCampaigns?.data}
                    getOptionLabel={getOptionLabel}
                    onChange={onChange('activeCampaign')}
                    isOptionEqualToValue={isOptionEqualToValue}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="activeCampaign"
                        label={intl.formatMessage({ id: 'active-campaign' })}
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <Box marginBottom="16px">
                <FormControl fullWidth>
                  <Autocomplete
                    options={archivedCampaigns?.data}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={isOptionEqualToValue}
                    onChange={onChange('archivedCampaign')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="archivedCampaign"
                        label={intl.formatMessage({ id: 'archived-campaign' })}
                      />
                    )}
                  />
                </FormControl>
              </Box>
              {error && <Alert severity="error">{error}</Alert>}
            </DialogContent>
            <DialogActions>
              <Button variant="text" onClick={onClose}>
                <FormattedMessage id="exit" />
              </Button>
              <Button
                onClick={onSubmit}
                variant="contained"
                disabled={!formik.isValid || isMutating}
              >
                <Box display="flex" gap="8px" alignItems="center">
                  <FormattedMessage id="merge" />
                  {isMutating && <CircularProgress color="inherit" size={12} />}
                </Box>
              </Button>
            </DialogActions>
          </>
        )}
        {activeCampaignsLoading && archivedCampaignsLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" p={2}>
            <CircularProgress size={48} />
          </Box>
        )}
      </Dialog>
    </>
  )
}

export default MergeModal
