import { useState } from 'react'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { useFormik } from 'formik'
import useSWRMutation from 'swr/mutation'
import { FormattedMessage, useIntl } from 'react-intl'
import { TunsolvedAny } from '../../../../types/unsolved'
import axiosInstance from '../../../../utils/axiosInstance'
import DialogHeader from '../../../../components/Dialog/header'

const TEMPLATES = [
  { value: 'new-order', label: 'New Order Created' },
  { value: 'preview-approval', label: 'Waiting For Approval' },
  { value: 'order-invoice-created', label: 'Order Invoice Created' },
  { value: 'order-archived', label: 'Order Archived' },
]

const TransactionMailModal = ({ open, setOpen, orderId }) => {
  const intl = useIntl()
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')

  const { trigger, isMutating } = useSWRMutation(
    '/manually-notify-customer',
    async (url: string, { arg }: { arg: TunsolvedAny }) => {
      try {
        const res = await axiosInstance.post(url, arg)
        setSuccess(res.data.message)
        setError('')
      } catch (error: TunsolvedAny) {
        setSuccess('')
        setError(`${error?.message}. ${error.response.data.message}`)
      }
    }
  )

  const onClose = () => setOpen(false)
  const formik = useFormik({
    initialValues: {
      mailTemplate: null,
    },
    onSubmit: async (values) => trigger({ ...values, orderId }),
  })

  const onSubmit = () => {
    formik.handleSubmit()
  }

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogHeader handleClose={onClose} title={<FormattedMessage id="which-transactional" />} />
      <DialogContent>
        <Box marginBottom="16px">
          <FormControl fullWidth>
            <InputLabel id="mailTemplate">
              <FormattedMessage id="template" />
            </InputLabel>
            <Select
              fullWidth
              labelId="mailTemplate"
              id="mailTemplate"
              name="mailTemplate"
              value={formik.values.mailTemplate}
              label={intl.formatMessage({ id: 'template' })}
              onChange={formik.handleChange}
            >
              {TEMPLATES.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {success && <Alert severity="success">{success}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          <FormattedMessage id="exit" />
        </Button>
        <Button
          onClick={onSubmit}
          variant="contained"
          disabled={!formik.values.mailTemplate || isMutating}
        >
          <Box display="flex" gap="8px" alignItems="center">
            <FormattedMessage id="send" />
            {isMutating && <CircularProgress color="inherit" size={12} />}
          </Box>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TransactionMailModal
