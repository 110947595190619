import React, { useCallback } from 'react'
import { Checkbox, TableRow } from '@mui/material'
import { IconUser } from '@tabler/icons-react'
import { convertValuesToLabels, fixImgSrc, hasPermission } from '../../../../utils/tools'
import EnhancedTableCell from '../EnhancedTableCell/EnhancedTableCell'
import classes from './style.module.scss'
import { customerTypes } from '../../../../views/customers/create/CreateCustomer'
import { setOpenCustomerModal } from '../../../../store/slices/general'
import { useDispatch, useSelector } from '../../../../store/store'

const CustomersRow = ({ labelId = '', row, isItemSelected = false, checkbox = false }) => {
  const { id, name, logoUrl, type, phoneNumber, address, emails } = row
  const defaultEmail = emails ? JSON.parse(emails)[0] : ''
  const { permissions } = useSelector((state) => state.user)
  const permission = hasPermission(permissions?.customersDetailsFields)

  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    if (permissions?.customersDetails) {
      dispatch(setOpenCustomerModal())
      window.history.pushState({}, '', `/customers/${id}`)
    }
  }, [dispatch, id, permissions?.customersDetails])

  const TableCellCustomer = (
    props: JSX.IntrinsicAttributes & {
      children: React.ReactNode
      action?: boolean | undefined
      checkbox?: boolean | undefined
      handleClick?: (() => void) | undefined
    }
  ) => {
    return (
      <EnhancedTableCell id={id} handleClick={handleClick} {...props}>
        {props.children}
      </EnhancedTableCell>
    )
  }

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={id}
      selected={isItemSelected}
      sx={{ cursor: 'pointer' }}
    >
      {checkbox && (
        <TableCellCustomer checkbox action>
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelId,
            }}
          />
        </TableCellCustomer>
      )}
      {permission('name') && (
        <TableCellCustomer>
          <div className={classes.nameAndLogo}>
            <div className={classes.logo}>
              {logoUrl ? (
                <img src={fixImgSrc(logoUrl)} alt="customer logo" width="35px" />
              ) : (
                <IconUser width="35px" />
              )}
            </div>

            <div className={classes.textContent}>
              <p>{name}</p>
            </div>
          </div>
        </TableCellCustomer>
      )}

      {permission('type') && (
        <TableCellCustomer>{convertValuesToLabels(customerTypes, type)}</TableCellCustomer>
      )}
      {permission('emails') && <TableCellCustomer>{defaultEmail}</TableCellCustomer>}
      {permission('phoneNumbers') && <TableCellCustomer>{phoneNumber}</TableCellCustomer>}
      {permission('address') && <TableCellCustomer>{address}</TableCellCustomer>}
    </TableRow>
  )
}

export default CustomersRow
