import React, { useMemo } from 'react'

import DEFAULT from './icons/DEFAULT.svg'
import JPG from './icons/JPG.svg'
import MP3 from './icons/MP3.svg'
import MP4 from './icons/MP4.svg'
import SVG from './icons/SVG.svg'
import PNG from './icons/PNG.svg'

interface FileExtensionImgProps {
  className?: string
  fileType: string
}

export const FileExtensionImg: React.FC<FileExtensionImgProps> = ({ className, fileType }) => {
  const ICON_ENUM = useMemo(
    () => ({
      'image/jpeg': JPG,
      'image/jpg': JPG,
      'image/png': PNG,
      'image/svg+xml': SVG,
      'audio/mpeg': MP3,
      'video/mp4': MP4,
      jpeg: JPG,
      jpg: JPG,
      png: PNG,
      svg: SVG,
      mp3: MP3,
      mp4: MP4,
    }),
    []
  )

  const src = ICON_ENUM[fileType] || DEFAULT

  return <img className={className} src={src} />
}
