import { store } from '../../../../store/store'
import { HeadCell } from '../../../../types'
import { hasPermission } from '../../../../utils/tools'

export const headCellsLeads: HeadCell[] = [
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'status',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'created-at',
  },
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'first-name',
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: 'last-name',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'phone',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'email',
  },
  {
    id: 'portal',
    numeric: false,
    disablePadding: false,
    label: 'portal',
  },
  {
    id: 'channel',
    numeric: false,
    disablePadding: false,
    label: 'channel',
  },
]

store.subscribe(() => {
  const { permissions } = store.getState().user
  const permission = hasPermission(permissions?.leadsAndTalentsDetailsFields)
  if (permissions) {
    !permission('status') && delete headCellsLeads[0]
    !permission('createdTime') && delete headCellsLeads[1]
    !permission('firstName') && delete headCellsLeads[2]
    !permission('lastName') && delete headCellsLeads[3]
    !permission('phone') && delete headCellsLeads[4]
    !permission('email') && delete headCellsLeads[5]
    !permission('platform') && delete headCellsLeads[6]
    !permission('channel') && delete headCellsLeads[7]
  }
})
