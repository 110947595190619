import { useEffect, useState } from 'react'
import { Button, Container, Drawer, IconButton, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dayjs } from 'dayjs'
import { IconX } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { setCampaignsFilterQueryParams } from '../../../../../store/slices/filters/filters'
import { useDispatch } from '../../../../../store/store'

interface Iprops {
  drawerOpen: boolean
  setDrawerOpen: (drawerOpen: boolean) => void
}

export function CampaignFilterDrawer({ drawerOpen = false, setDrawerOpen }: Iprops) {
  return (
    <Drawer
      className="filterDrawer"
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <Container disableGutters className="drawerContainer" style={{ padding: 16 }}>
        <Container className="header" style={{ padding: 0 }}>
          <Typography variant="h3">Filter</Typography>
          <IconButton
            size="medium"
            edge="start"
            color="inherit"
            onClick={() => setDrawerOpen(false)}
            aria-label="close"
          >
            <IconX />
          </IconButton>
        </Container>
        <Container style={{ padding: 0 }}>
          <Filters />
        </Container>
      </Container>
    </Drawer>
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  // const { data: campaignsData } = useSWR('/campaign-filter-values', axiosInstance)
  // const campaigns = campaignsData?.data

  // interface LabelValue {
  //   label: string
  //   value: string
  // }

  const [date, setDate] = useState<Dayjs | null>(null)
  // const [reseller, setReseller] = useState<LabelValue | null>(null)

  useEffect(() => {
    const raw = date?.toDate()
    const createdAt = raw?.toDateString()

    const params = {
      createdAt,
      // reseller: reseller?.value,
    }
    dispatch(setCampaignsFilterQueryParams(params))
  }, [date, dispatch])

  function clearFilters() {
    setDate(null)
    // setReseller(null)
  }

  return (
    <form>
      <Typography>
        <FormattedMessage id="date-created" />
      </Typography>
      <DatePicker
        disableFuture
        sx={{ width: '100%' }}
        format="DD/MM/YYYY"
        value={date}
        onChange={(newValue) => setDate(newValue)}
        slotProps={{
          actionBar: {
            actions: ['clear'],
          },
        }}
      />
      {/* {campaigns?.resellers?.length && (
        <>
          <FormControl fullWidth>
            <Typography>
              <FormattedMessage id="reseller" />
            </Typography>
            <Autocomplete
              id="reseller"
              options={campaigns?.resellers}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
              value={reseller}
              onChange={(_event, newValue) => setReseller(newValue || null)}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
            />
          </FormControl>
        </>
      )} */}
      <div className="clear">
        <Button onClick={() => clearFilters()} className="clearButt" fullWidth variant="outlined">
          Clear all
        </Button>
      </div>
    </form>
  )
}
