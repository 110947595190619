import { Card, styled } from '@mui/material'

export const StyledCard = styled(Card)(() => ({
  padding: '20px',
}))

export const IconWrapper = styled('div')(({ theme }) => ({
  padding: '8px',
  borderRadius: '8px',
  background: theme.palette.primary.main,
  color: '#fff',
  height: '24px',
  width: '24px',
  boxSizing: 'content-box',
}))
