import { FC } from 'react'
import {
  LinearProgress,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { TableProps } from './AdSetsTable.types'
import Row from './components/Row'

const formatNumber = (number: number | string) => Number(number).toFixed(3)

const AdSetsTable: FC<TableProps> = ({ columns, data, isLoading }) => (
  <MuiTable aria-label="Campaign table">
    <TableHead>
      <TableRow>
        <TableCell />
        {columns.map(({ id, name }) => (
          <TableCell key={id}>{name}</TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {isLoading && (
        <TableRow key="loader">
          <TableCell colSpan={10}>
            <LinearProgress style={{ width: '100%' }} />
          </TableCell>
        </TableRow>
      )}
      {data?.map(
        ({ id, name, clicks, ctr, frequency, impressions, avgPlaybackTime, linkClicks }) => {
          const linkCTR = linkClicks ? formatNumber((linkClicks / impressions) * 100) : 'N/A'

          return (
            <Row
              key={id}
              id={id}
              name={name ?? 'N/A'}
              clicks={clicks ?? 'N/A'}
              linkCTR={linkCTR ?? 'N/A'}
              linkClicks={linkClicks ?? 'N/A'}
              impressions={impressions ?? 'N/A'}
              ctr={(ctr && formatNumber(ctr)) ?? 'N/A'}
              frequency={(frequency && formatNumber(frequency)) ?? 'N/A'}
              devices={['N/A']}
              avgPlaybackTime={avgPlaybackTime ?? 'N/A'}
            />
          )
        }
      )}
    </TableBody>
  </MuiTable>
)

export default AdSetsTable
