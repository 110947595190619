import { FC, useState } from 'react'
import { ResponsiveContainer, Tooltip, PieChart, Pie, Cell } from 'recharts'
import { Grid, Skeleton, Stack, Typography } from '@mui/material'
import { Analytics, BrokenImage } from '@mui/icons-material'
import { FormattedMessage, useIntl } from 'react-intl'
import { Legend, LegendItem, StyledCard, Thumbnail } from './Dashboard.styles'
import { CreativePreview, InfoCard, InfoChart } from './components'
import useDashboard from './hooks/useDashboard'
import { filterEmptyAndSort } from '../../../../utils/meta'

const colors = ['#220D77', '#FFC107', '#FFBAAE', '#D84315', '#00A43B', '#1e88e5', '#697586']

type Creative = { src: string; name: string }

const Dashboard: FC = () => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const [creative, setCreative] = useState<Creative>()
  const { isLoading, campaignData, chartData, pieChartSectionHeight, ref } = useDashboard()

  const handleOpenCreativePreview = ({ src, name }: Creative) => {
    setCreative({ src, name })
    setOpen(true)
  }
  const handleCloseCreativePreview = () => setOpen(false)

  return (
    <>
      <Grid container spacing={2} sx={{ mt: '20px' }}>
        <Grid ref={ref} item xs={12} md={4}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <InfoCard
                icon={<Analytics />}
                value={isLoading ? <Skeleton width="50px" /> : campaignData?.frequency}
                title={intl.formatMessage({ id: 'frequency' })}
                growth
              />
            </Grid>
            <Grid item>
              <InfoCard
                icon={<Analytics />}
                value={isLoading ? <Skeleton width="50px" /> : campaignData.uniqClicks}
                title={intl.formatMessage({ id: 'unique-clicks' })}
                growth={false}
              />
            </Grid>
            <Grid item>
              <InfoCard
                icon={<Analytics />}
                value={isLoading ? <Skeleton width="50px" /> : campaignData.impressions}
                title={intl.formatMessage({ id: 'impressions' })}
                growth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <InfoCard
                icon={<Analytics />}
                value={isLoading ? <Skeleton width="50px" /> : campaignData.clicks}
                title={intl.formatMessage({ id: 'clicks' })}
                growth
              />
            </Grid>
            <Grid item>
              <InfoCard
                icon={<Analytics />}
                value={isLoading ? <Skeleton width="50px" /> : campaignData.leadsNumber}
                title={intl.formatMessage({ id: 'leads' })}
                growth
              />
            </Grid>
            <Grid item>
              <InfoCard
                icon={<Analytics />}
                value={isLoading ? <Skeleton width="50px" /> : campaignData.ctr}
                title="CTR"
                growth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} sx={{ height: `${pieChartSectionHeight}px` }}>
          <StyledCard sx={{ height: '100%' }}>
            <Typography variant="h3">
              <FormattedMessage id="link-clicks" />
            </Typography>
            <ResponsiveContainer height="40%">
              <PieChart>
                {chartData.length && (
                  <Pie
                    data={chartData}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={45}
                    outerRadius={60}
                    fill="#8884d8"
                    paddingAngle={5}
                  >
                    {chartData?.map((_, index) => (
                      <Cell key={index} fill={colors[index % colors.length]} />
                    ))}
                  </Pie>
                )}
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
            <Legend>
              {chartData?.map(({ id, name, value, thumbnailURL, imageURL }) => (
                <LegendItem key={id}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Stack direction="row" alignItems="center" gap="20px">
                      {thumbnailURL ? (
                        <Thumbnail
                          src={thumbnailURL}
                          alt={name ?? 'Ad Thumbnail'}
                          onError={(e) => {
                            e.currentTarget.style.display = 'none'
                          }}
                          onClick={() =>
                            handleOpenCreativePreview({
                              src: imageURL || thumbnailURL,
                              name: name ?? 'Ad Thumbnail',
                            })
                          }
                          loading="lazy"
                        />
                      ) : (
                        <BrokenImage style={{ width: '25px', height: 'auto' }} />
                      )}
                      <Typography>{name}</Typography>
                    </Stack>
                    <Typography>{value}</Typography>
                  </Stack>
                </LegendItem>
              ))}
            </Legend>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack gap="20px">
            {campaignData.leadsByGender.some(({ value }) => !!value) && (
              <Grid item>
                <InfoChart
                  title={intl.formatMessage({ id: 'leads' })}
                  data={filterEmptyAndSort(campaignData.leadsByGender)}
                />
              </Grid>
            )}
            {campaignData.platformClicks.some(({ value }) => !!value) && (
              <Grid item>
                <InfoChart
                  title={intl.formatMessage({ id: 'fb-vs-instagram' })}
                  data={filterEmptyAndSort(campaignData.platformClicks)}
                />
              </Grid>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack gap="20px">
            {campaignData.clicksByGender.some(({ value }) => !!value) && (
              <Grid item>
                <InfoChart
                  title={intl.formatMessage({ id: 'clicks' })}
                  data={filterEmptyAndSort(campaignData.clicksByGender)}
                />
              </Grid>
            )}
            {campaignData.deviceClicks.some(({ value }) => !!value) && (
              <Grid item>
                <InfoChart
                  title={intl.formatMessage({ id: 'device-platform' })}
                  data={filterEmptyAndSort(campaignData.deviceClicks)}
                />
              </Grid>
            )}
          </Stack>
        </Grid>
      </Grid>
      {creative && (
        <CreativePreview open={open} onClose={handleCloseCreativePreview} {...creative} />
      )}
    </>
  )
}

export default Dashboard
