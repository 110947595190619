import axios from 'axios'
import { useMockedData } from '../../config/configs'

const mockApiUrl = 'https://j2vwy.wiremockapi.cloud/'
const baseURL = useMockedData ? mockApiUrl : process.env.REACT_APP_SERVER_URL

const axiosInstance = axios.create({
  baseURL,
})

export default axiosInstance
