import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useTheme } from '@mui/material/styles'
import { IconMailX } from '@tabler/icons-react'

interface Props {
  buttonSize?: 'small' | 'medium' | 'large'
  iconSize?: string | number
}

const NoProvidedCustomerEmail: React.FC<Props> = ({ buttonSize = 'small', iconSize = '16px' }) => {
  const theme = useTheme()
  return (
    <Tooltip title={<FormattedMessage id="no-mail-address-was-provided" />}>
      <IconButton size={buttonSize}>
        <IconMailX color={theme.palette.error.main} size={iconSize} />
      </IconButton>
    </Tooltip>
  )
}

export default NoProvidedCustomerEmail
