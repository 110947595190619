import { IconButton, TextField, Tooltip } from '@mui/material'
import { IconCheck, IconX } from '@tabler/icons-react'
import React from 'react'

interface Iprops {
  setContact: (e: string) => void
  type?: 'email' | 'phone'
  setItemClicked: (e: boolean) => void
  handleApprove: () => void
}

const CreateContactRow: React.FC<Iprops> = ({
  setContact,
  type = 'email',
  setItemClicked,
  handleApprove,
}) => {
  const submit = () => {
    handleApprove()
    setItemClicked(false)
  }

  return (
    <div className="CreateContactRow">
      <TextField
        className="textField"
        margin="none"
        sx={{ marginBottom: 0 }}
        fullWidth
        id="contact"
        name="contact"
        type={type}
        onChange={(e) => setContact(e.currentTarget.value)}
        onKeyDown={(e) => e.key === 'Enter' && submit()}
        autoFocus
      />
      <div className="decide">
        <div className="iconsCont">
          <Tooltip title="Cancel" onClick={() => setItemClicked(false)}>
            <IconButton>
              <IconX className="icon" />
            </IconButton>
          </Tooltip>
          <div></div>
          <Tooltip title="Approve" onClick={submit}>
            <IconButton>
              <IconCheck className="icon" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default CreateContactRow
