import {
  Button,
  Container,
  Drawer,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import commonCodebase from 'common-recruspin-codebase'
import { IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from '../../../../../../../store/store'
import { setInternalUsersFilterQueryParams } from '../../../../../../../store/slices/filters/filters'
import { InternalUserRoles } from '../../../../../../../store/slices/filters/type'

const { internalUserRoles } = commonCodebase.appUser

interface Iprops {
  drawerOpen: boolean
  setDrawerOpen: (drawerOpen: boolean) => void
}

export function UsersFilterDrawer({ drawerOpen = false, setDrawerOpen }: Iprops) {
  return (
    <Drawer
      className="filterDrawer"
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <Container disableGutters className="drawerContainer" style={{ padding: 16 }}>
        <Container className="header" style={{ padding: 0 }}>
          <Typography variant="h3">Filter</Typography>
          <IconButton
            size="medium"
            edge="start"
            color="inherit"
            onClick={() => setDrawerOpen(false)}
            aria-label="close"
          >
            <IconX />
          </IconButton>
        </Container>
        <Container style={{ padding: 0 }}>
          <Filters />
        </Container>
      </Container>
    </Drawer>
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const [userType, setUserType] = useState<InternalUserRoles | null>(null)

  useEffect(() => {
    const params = {
      role: userType,
    }
    dispatch(setInternalUsersFilterQueryParams(params))
  }, [dispatch, userType])

  function clearFilters() {
    setUserType(null)
  }

  return (
    <form>
      <FormControl fullWidth>
        <Typography>
          <FormattedMessage id="user-types" />
        </Typography>
        <Select
          fullWidth
          labelId="type"
          id="type"
          name="type"
          onChange={(e) => {
            setUserType(e.target.value as InternalUserRoles)
          }}
          value={userType}
          label="customerType"
        >
          {internalUserRoles.map((i: { label: string; value: string }) => (
            <MenuItem key={i.value} value={i.value}>
              {i.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="clear">
        <Button onClick={() => clearFilters()} className="clearButt" fullWidth variant="outlined">
          Clear all
        </Button>
      </div>
    </form>
  )
}
