import { CircularProgress } from '@mui/material'
import './style.scss'

const Loading = () => {
  return (
    <div className="loadingContainer">
      <CircularProgress />
    </div>
  )
}

export default Loading
