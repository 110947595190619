import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lead: [],
  trigger: false,
}

const lead = createSlice({
  name: 'lead',
  initialState,
  reducers: {
    setLead(state, action) {
      state.lead = action.payload
    },
    setTrigger(state, action) {
      state.trigger = action.payload
    },
    removeLead(state) {
      state.lead = initialState.lead
    },
  },
})

export default lead.reducer

export const { setLead, setTrigger, removeLead } = lead.actions
