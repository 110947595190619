import { Link as RouterLink } from 'react-router-dom'
import { Link, Typography } from '@mui/material'
import './styles.scss'
import { FormattedMessage } from 'react-intl'

const Page404 = () => {
  return (
    <div className="page404">
      <div>
        <Typography fontSize="68px" variant="h1" color="#b0bec5">
          404
        </Typography>
        <Typography>
          <FormattedMessage id="page-not-found" />
        </Typography>
        <Link component={RouterLink} to="/">
          <FormattedMessage id="go-homepage" />
        </Link>
      </div>
    </div>
  )
}

export default Page404
