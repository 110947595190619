import { Modal, Typography, IconButton } from '@mui/material'
import { FC, useState } from 'react'
import { BrokenImage, Close } from '@mui/icons-material'
import { CreativePreviewProps } from './CreativePreview.types'
import { Container, Header } from './CreativePreview.styles'

const imgStyle = {
  width: '500px',
  height: 'auto',
}

const CreativePreview: FC<CreativePreviewProps> = ({
  open,
  onClose,
  src,
  name = 'Creative preview',
}) => {
  const [isError, setError] = useState(false)

  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <Header>
          <Typography variant="h3" component="h2">
            {name}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Header>
        {!isError ? (
          <img
            src={src}
            alt={name}
            onError={() => setError(true)}
            loading="lazy"
            style={imgStyle}
          />
        ) : (
          <BrokenImage style={imgStyle} />
        )}
      </Container>
    </Modal>
  )
}

export default CreativePreview
