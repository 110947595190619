import { isCustomer, USER_ROLES } from '../../../../constants'
import { store } from '../../../../store/store'
import { HeadCell } from '../../../../types'
import { hasPermission } from '../../../../utils/tools'

export const headCellsCampaigns: HeadCell[] = [
  {
    id: 'customer',
    numeric: false,
    disablePadding: true,
    label: 'customer',
  },
  {
    id: 'page',
    numeric: false,
    disablePadding: false,
    label: 'page-name',
  },
  {
    id: 'campaign',
    numeric: false,
    disablePadding: false,
    label: 'campaign',
  },
  {
    id: 'createdTime',
    numeric: false,
    disablePadding: false,
    label: 'date',
  },
  {
    id: 'totalLeads',
    numeric: true,
    disablePadding: false,
    label: 'total-leads',
  },
]

store.subscribe(() => {
  const { permissions } = store.getState().user
  const { role } = store.getState().user.userInfo

  const permission = hasPermission(permissions?.campaignsDetailsFields)

  // for customer types
  if (role === USER_ROLES.EXTERNAL_DIRECT_CUSTOMER.value) {
    delete headCellsCampaigns[0]
    delete headCellsCampaigns[1]
  }

  // for defined permissions
  if (permissions) {
    !permission('page') || (isCustomer(role) && delete headCellsCampaigns[1])
    !permission('name') && delete headCellsCampaigns[2]
    !permission('createdTime') && delete headCellsCampaigns[3]
    !permission('totalLeadsCount') && delete headCellsCampaigns[4]
  }
})
