let shouldUseMockedData = false
let useSessionWrapper = false

const userRole = 'admin'
// const userRole = 'externalDirectCustomer'

const userInfo = {
  id: '1',
  email: 'test@test.de',
  firstName: 'Leo',
  lastName: 'Messi',
  logo: '',
  address: '',
  phoneNumber: '',
  role: userRole,
  status: 200,
  message: '',
}

export const env = process.env.NODE_ENV || 'development'
export const isDev = env === 'development'
!isDev && (shouldUseMockedData = false)

useSessionWrapper = !isDev
useSessionWrapper = true

export const useMockedData = shouldUseMockedData

export default {
  useSessionWrapper,
  userRole,
  userInfo,
  isDev,
  shouldUseMockedData,
}
