import commonCodebase from 'common-recruspin-codebase'

import { FormattedMessage, useIntl } from 'react-intl'
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import './style.scss'
import { Ipermission } from 'common-recruspin-codebase/types/permission'
import {
  CampaignsDetailsFieldValues,
  CustomerDetailsFieldValues,
  LeadsAndTalentsDetailsFieldValues,
  OrderDetailsFieldValues,
} from 'common-recruspin-codebase/src/permission'

const {
  customerTableFieldsInDb,
  orderTableFieldsInDb,
  leadsAndTalentsTableFieldsInDb,
  campaignsFieldsInDb,
} = commonCodebase.permission

interface Iprops {
  data: Ipermission
  setData: Dispatch<SetStateAction<Ipermission>>
}

type AllCheckBoxValues =
  | keyof Ipermission
  | CampaignsDetailsFieldValues
  | OrderDetailsFieldValues
  | CustomerDetailsFieldValues
  | LeadsAndTalentsDetailsFieldValues

export function PermissionSettings({ data, setData }: Iprops) {
  const intl = useIntl()
  const Check = ({ value, label }: { value: AllCheckBoxValues; label?: string }) => {
    return (
      <FormGroup className="checkboxes">
        <FormControlLabel
          control={
            <Checkbox
              checked={data[value]}
              // defaultChecked={data[value]}
              onChange={(e) => {
                setData((state) => ({ ...state, [value]: e.target.checked }))
              }}
            />
          }
          label={label || String(value).charAt(0).toUpperCase() + String(value).slice(1)}
        />
      </FormGroup>
    )
  }

  return (
    <div className="PermissionSettings">
      <div className="optionsContainer">
        <Typography className="desc" variant="body1" fontWeight="500">
          <FormattedMessage id="check-box-dashboard" />
        </Typography>

        <Check value="dashboard" />
      </div>
      <div className="optionsContainer">
        <Typography className="desc" variant="body1" fontWeight="500">
          <FormattedMessage id="check-box-orders" />
        </Typography>
        <Check value="orders" />
        {data.orders && (
          <div>
            <Check value="ordersTable" label={intl.formatMessage({ id: 'orders-table' })} />
            <Check value="ordersDetails" label={intl.formatMessage({ id: 'orders-details' })} />

            {data.ordersDetails &&
              orderTableFieldsInDb.map((field) => (
                <FormGroup key={field.value} className="checkboxes subCheckBox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data.orderDetailsFields.includes(field.value)}
                        onChange={(e) => {
                          setData((state) => {
                            return {
                              ...state,
                              orderDetailsFields: e.target.checked
                                ? [...state.orderDetailsFields, field.value]
                                : state.orderDetailsFields.filter(
                                    (item: OrderDetailsFieldValues) => item !== field.value
                                  ),
                            }
                          })
                        }}
                        size="small"
                      />
                    }
                    label={field.label}
                  />
                </FormGroup>
              ))}
          </div>
        )}
      </div>
      <div className="optionsContainer">
        <Typography className="desc" variant="body1" fontWeight="500">
          <FormattedMessage id="check-box-customers" />
        </Typography>
        <Check value="customers" />
        {data.customers && (
          <div>
            <Check value="customersTable" label={intl.formatMessage({ id: 'customers-table' })} />
            <Check
              value="customersDetails"
              label={intl.formatMessage({ id: 'customers-details' })}
            />

            {data.customersDetails &&
              customerTableFieldsInDb.map((field) => (
                <FormGroup key={field.value} className="checkboxes subCheckBox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data.customersDetailsFields.includes(field.value)}
                        onChange={(e) => {
                          setData((state) => {
                            return {
                              ...state,
                              customersDetailsFields: e.target.checked
                                ? [...state.customersDetailsFields, field.value]
                                : state.customersDetailsFields.filter(
                                    (item: CustomerDetailsFieldValues) => item !== field.value
                                  ),
                            }
                          })
                        }}
                        size="small"
                      />
                    }
                    label={field.label}
                  />
                </FormGroup>
              ))}
          </div>
        )}
      </div>
      <div className="optionsContainer">
        {/* Campaigns */}
        <Typography className="desc" variant="body1" fontWeight="500">
          <FormattedMessage id="check-box-campaigns" />
        </Typography>
        <Check value="campaigns" />
        {data.campaigns && (
          <div>
            <Check value="campaignsTable" label={intl.formatMessage({ id: 'campaigns-table' })} />
            <Check
              value="campaignsDetailsFields"
              label={intl.formatMessage({ id: 'campaigns-details' })}
            />

            {data.campaignsTable &&
              campaignsFieldsInDb.map((field) => (
                <FormGroup key={field.value} className="checkboxes subCheckBox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data.campaignsDetailsFields.includes(field.value)}
                        onChange={(e) => {
                          setData((state) => {
                            return {
                              ...state,
                              campaignsDetailsFields: e.target.checked
                                ? [...state.campaignsDetailsFields, field.value]
                                : state.campaignsDetailsFields.filter(
                                    (item: CampaignsDetailsFieldValues) => item !== field.value
                                  ),
                            }
                          })
                        }}
                        size="small"
                      />
                    }
                    label={field.label}
                  />
                </FormGroup>
              ))}
          </div>
        )}
      </div>

      <div className="optionsContainer">
        <Typography className="desc" variant="body1" fontWeight="500">
          <FormattedMessage id="check-box-leads" />
        </Typography>
        <Check value="leadsAndTalents" label={intl.formatMessage({ id: 'leads-n-talents' })} />
        {data.leadsAndTalents && (
          <Check
            value="leadsAndTalentsDetailsFields"
            label={intl.formatMessage({ id: 'leads-n-talents-details' })}
          />
        )}
        {data.leadsAndTalents && data.leadsAndTalentsDetailsFields && (
          <div>
            {leadsAndTalentsTableFieldsInDb.map((field) => (
              <FormGroup key={field.value} className="checkboxes subCheckBox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.leadsAndTalentsDetailsFields.includes(field.value)}
                      onChange={(e) => {
                        setData((state) => {
                          return {
                            ...state,
                            leadsAndTalentsDetailsFields: e.target.checked
                              ? [...state.leadsAndTalentsDetailsFields, field.value]
                              : state.leadsAndTalentsDetailsFields.filter(
                                  (item: LeadsAndTalentsDetailsFieldValues) => item !== field.value
                                ),
                          }
                        })
                      }}
                      size="small"
                    />
                  }
                  label={field.label}
                />
              </FormGroup>
            ))}
          </div>
        )}
      </div>
      <div className="optionsContainer">
        {/* analytics */}
        <Typography className="desc" variant="body1" fontWeight="500">
          <FormattedMessage id="check-box-analytics" />
        </Typography>
        <Check value="analytics" />
      </div>
    </div>
  )
}
