import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../../../utils/axiosInstance'

const useDashboard = () => {
  const intl = useIntl()
  const ref = useRef<HTMLDivElement>(null)
  const { id: campaignId } = useParams()
  const { data, isLoading } = useSWR(`/meta/campaign/${campaignId}`, axiosInstance)
  const [pieChartSectionHeight, setPieChartSectionHeight] = useState(0)

  useEffect(() => {
    if (ref.current) {
      setPieChartSectionHeight(ref.current.clientHeight)
    }
  }, [ref])

  const chartData = useMemo(() => {
    if (data?.data.ads) {
      return data.data.ads.map(({ id, name, clicks, creative }) => ({
        id,
        name,
        imageURL: creative?.imageURL,
        thumbnailURL: creative?.thumbnailURL,
        value: clicks,
      }))
    }

    return []
  }, [data])

  const campaignData = useMemo(() => {
    const result = {
      clicks: 'N/A',
      impressions: 'N/A',
      frequency: 'N/A',
      ctr: 'N/A',
      uniqClicks: 'N/A',
      leadsNumber: 'N/A',
      platforms: { 'N/A': 0 },
      clicksByGender: [],
      leadsByGender: [],
      platformClicks: [],
      deviceClicks: [],
    }

    if (data?.data) {
      const {
        clicks,
        impressions,
        ctr,
        uniqClicks,
        frequency,
        ads,
        femaleClicks,
        maleClicks,
        unknownClicks,
        femaleLeads,
        maleLeads,
        unknownLeads,
        facebookClicks,
        instagramClicks,
        androidSmartphoneClicks,
        androidTabletClicks,
        desktopClicks,
        ipadClicks,
        ipodClicks,
        iphoneClicks,
        otherDeviceClicks,
      } = data.data

      return {
        ...result,
        ...(clicks && { clicks }),
        ...(impressions && { impressions }),
        ...(ctr && { ctr }),
        ...(uniqClicks && { uniqClicks }),
        ...(frequency && { frequency }),
        clicksByGender: [
          { name: intl.formatMessage({ id: 'female' }), value: femaleClicks },
          { name: intl.formatMessage({ id: 'male' }), value: maleClicks },
          { name: intl.formatMessage({ id: 'unknown' }), value: unknownClicks },
        ],
        leadsByGender: [
          { name: intl.formatMessage({ id: 'female' }), value: femaleLeads },
          { name: intl.formatMessage({ id: 'male' }), value: maleLeads },
          { name: intl.formatMessage({ id: 'unknown' }), value: unknownLeads },
        ],
        platformClicks: [
          { name: intl.formatMessage({ id: 'facebook' }), value: facebookClicks },
          { name: intl.formatMessage({ id: 'instagram' }), value: instagramClicks },
        ],
        deviceClicks: [
          { name: intl.formatMessage({ id: 'desktop' }), value: desktopClicks },
          {
            name: intl.formatMessage({ id: 'android-smartphone' }),
            value: androidSmartphoneClicks,
          },
          { name: intl.formatMessage({ id: 'android-tablet' }), value: androidTabletClicks },
          { name: intl.formatMessage({ id: 'iphone' }), value: iphoneClicks },
          { name: intl.formatMessage({ id: 'ipad' }), value: ipadClicks },
          { name: intl.formatMessage({ id: 'ipod' }), value: ipodClicks },
          { name: intl.formatMessage({ id: 'other' }), value: otherDeviceClicks },
        ],
        leadsNumber: ads.reduce((acc, value) => {
          if (value.leads) {
            acc += value.leads
          }

          return acc
        }, 0),
      }
    }

    return result
  }, [data, intl])

  return {
    ref,
    chartData,
    pieChartSectionHeight,
    campaignData,
    isLoading,
  }
}

export default useDashboard
