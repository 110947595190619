import { SessionAuth, useSessionContext } from 'supertokens-auth-react/recipe/session'
import { SessionContainer } from './SessionContainer'
import configs from './config/configs'
import NavigationScroll from './layout/NavigationScroll'

export const AppChilds = () => {
  const { useSessionWrapper } = configs

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const sessionContext = useSessionWrapper ? useSessionContext() : { loading: false }
  if (sessionContext.loading === true) return null

  return (
    <NavigationScroll>
      {useSessionWrapper ? (
        <SessionAuth>
          <SessionContainer />
        </SessionAuth>
      ) : (
        <SessionContainer />
      )}
    </NavigationScroll>
  )
}
