import { Box } from '@mui/material'
import {
  IconBrandInstagram,
  IconBrandMeta,
  IconBrandFacebook,
  IconBrandSnapchat,
  IconBrandGoogle,
  IconBrandReddit,
  IconBrandTwitter,
  IconBrandCampaignmonitor,
} from '@tabler/icons-react'
import { Channel } from '../../types/task'

const PLATFORM_ICON = {
  meta: IconBrandInstagram,
  facebook: IconBrandMeta,
  instagram: IconBrandFacebook,
  tiktok: () => <img src={'/socials/tiktok.svg'} alt="Social icon" />,
  snapchat: IconBrandSnapchat,
  googleDisplay: IconBrandGoogle,
  youtube: () => <img src={'/socials/youtube.svg'} alt="Social icon" />,
  spotify: () => <img src={'/socials/spotify.svg'} alt="Social icon" />,
  reddit: IconBrandReddit,
  twitter: IconBrandTwitter,
  campaign: IconBrandCampaignmonitor,
  fb: () => <img src={'/socials/fb.svg'} alt="Social icon" />,
  ig: () => <img src={'/socials/ig.svg'} alt="Social icon" />,
}

export interface Props {
  platform?: keyof typeof PLATFORM_ICON | Channel
}

const PlatformIcon: React.FC<Props> = ({ platform }) => {
  const Icon = platform ? PLATFORM_ICON[platform] : null

  return Icon ? (
    <Box
      borderRadius="50%"
      border="1px solid"
      width="24px"
      height="24px"
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
    >
      <Icon size="16px" />
    </Box>
  ) : null
}

export default PlatformIcon
