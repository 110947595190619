import React, { useState } from 'react'
import { Checkbox, TableRow, Typography } from '@mui/material'
import commonCodeBase from 'common-recruspin-codebase'
import EnhancedTableCell from '../EnhancedTableCell'
import classes from './style.module.scss'
import { convertValuesToLabels } from '../../../../../../utils/tools'
import AuthenticateButton from '../../../common/authenticateButton/AuthenticateButton'
import { ExternalAppUserDialog } from './externalAppUserDialog/ExternalAppUserDialog'

const { roles } = commonCodeBase.appUser

const UsersRow = ({ labelId = '', row, isItemSelected = false, checkbox = false }) => {
  const [open, setOpen] = useState(false)

  const { id, email, name, role, isAuthenticated } = row

  const TableCellOrder = (
    props: JSX.IntrinsicAttributes & {
      children: React.ReactNode
      action?: boolean | undefined
      checkbox?: boolean | undefined
      handleClick?: (() => void) | undefined
    }
  ) => {
    return (
      <EnhancedTableCell id={id} handleClick={() => setOpen(true)} {...props}>
        {props.children}
      </EnhancedTableCell>
    )
  }

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={id}
      selected={isItemSelected}
      sx={{ cursor: 'pointer' }}
    >
      {checkbox && (
        <TableCellOrder checkbox action>
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelId,
            }}
          />
        </TableCellOrder>
      )}

      <>
        <TableCellOrder>
          <div className={classes.nameAndLogo}>
            <div className={classes.logo}>
              <img src={`https://ui-avatars.com/api/?name=${name}&rounded=true&size=35`} alt="" />
            </div>
            <div className={classes.textContent}>
              <Typography>{name || email}</Typography>
            </div>
          </div>
        </TableCellOrder>
      </>

      <TableCellOrder>{email}</TableCellOrder>

      <TableCellOrder>{convertValuesToLabels(roles, role)}</TableCellOrder>
      <EnhancedTableCell id={id}>
        <AuthenticateButton id={id} isAuthenticated={isAuthenticated} />
      </EnhancedTableCell>
      <ExternalAppUserDialog open={open} setOpen={setOpen} id={id} />
    </TableRow>
  )
}

export default UsersRow
