import React from 'react'
import { AreaChart, ResponsiveContainer, Area } from 'recharts'
import './styles.scss'
import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { FormattedMessage } from 'react-intl'
import PlaceHolderRender from '../../../components/PlaceHolderRender'
import { Iorder } from '../InternalDashboard'

const TaskNoDataRender = () => {
  return (
    <>
      <div className="order">
        <div className="company noData"></div>
        <div className="taskInfo">
          <PlaceHolderRender width={'95%'} height={'10px'} marginBottom="5px" />
          <PlaceHolderRender width={'95%'} height={'10px'} />
        </div>
        <p className="taskValue">
          <PlaceHolderRender width="32px" height="16px" />
        </p>
      </div>
    </>
  )
}
interface DayProps {
  name: string
  value: number
}

const OpenTasks = ({ tasks }) => {
  const [viewAll, setViewAll] = React.useState(false)
  const [graph, setGraph] = React.useState<DayProps[]>()

  const countItemsByDay = (items: Iorder[]) => {
    if (!items) {
      return
    }

    const countsByMonth: DayProps[] | undefined = []

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const date = new Date(item.createdAt)
      const day = date.toLocaleString('de-DE', { day: 'numeric' })
      const index = countsByMonth.length ? countsByMonth.findIndex((d) => d.name === day) : -1

      if (index >= 0) {
        countsByMonth[index] = { ...countsByMonth[index], value: countsByMonth[index].value + 1 }
      } else {
        countsByMonth.push({ name: day, value: 1 })
      }
    }

    return countsByMonth
  }

  React.useEffect(() => {
    setGraph(countItemsByDay(tasks))
  }, [tasks])

  const hasTasks = tasks.length

  const ColorButton = styled(Button)<ButtonProps>(() => ({
    marginTop: '16px',
    color: hasTasks ? '#2196F3' : '#757575',
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
    },
  }))

  const handleViewAll = () => {
    setViewAll((old) => !old)
  }

  return (
    <>
      {tasks?.length ? (
        <div className="openTaskGraph">
          <ResponsiveContainer width="100%" height={140}>
            <AreaChart
              width={200}
              height={200}
              data={graph}
              margin={{
                top: 50,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient
                  id="gradient"
                  x1="164"
                  y1="3"
                  x2="168"
                  y2="154"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#220D77" stopOpacity="0.8" />
                  <stop offset="1" stopColor="#220D77" stopOpacity="0.1" />
                </linearGradient>
              </defs>

              <Area type="monotone" dataKey="value" stroke="#220D77" fill="url(#gradient)" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <PlaceHolderRender box text={<FormattedMessage id="no-open-orders" />} />
      )}

      <div className="taskWrapper">
        {hasTasks ? (
          (viewAll ? tasks : tasks.slice(0, 3)).map(
            ({ jobName, budget, customer: { name, type } }) => {
              const formatNumber = budget ? budget.toLocaleString('de-DE') : '0,00'

              return (
                <div className="order" key={jobName}>
                  <div className="company">{name ? name.charAt(0) : ''}</div>
                  <div className="taskInfo">
                    <p className="taskTitle">{jobName}</p>
                    <p className="taskType">{type}</p>
                  </div>
                  <p className="taskValue">
                    <span>{formatNumber}€</span>
                  </p>
                </div>
              )
            }
          )
        ) : (
          <div className="noTasks">
            <TaskNoDataRender />
            <TaskNoDataRender />
            <TaskNoDataRender />
            <TaskNoDataRender />
            <div className="overlay"></div>
          </div>
        )}
        <ColorButton onClick={handleViewAll} disabled={!hasTasks}>
          {tasks.length > 3 &&
            (viewAll ? <FormattedMessage id="hide-all" /> : <FormattedMessage id="view-All" />)}
        </ColorButton>
      </div>
    </>
  )
}

export default OpenTasks
