import { useLocation } from 'react-router-dom'
import { Alert, AppBar, Box, Toolbar, Typography } from '@mui/material'
import useSWR from 'swr'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { FormattedMessage } from 'react-intl'
import axiosInstance from '../../utils/axiosInstance'
import Loading from '../../ui-component/loading/Loading'
import Logo from '../../ui-component/Logo'

const OrderPreviewsApproval = () => {
  const location = useLocation()
  const id = new URLSearchParams(location?.search).get('id')
  const { data, isLoading, error } = useSWR(`/approve-order?id=${id}`, axiosInstance)
  const order = data?.data

  if (error) console.error(error)
  if (isLoading) return <Loading />
  return (
    <div>
      <AppBar sx={{ position: 'sticky' }} color="default">
        <Toolbar>
          <Typography sx={{ ml: 3, flex: 1, textAlign: 'center' }} variant="h3" component="div">
            <Logo />
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        height="calc(100vh - 70px)"
      >
        {!error && (
          <>
            <Box mb="6px" display="flex">
              <CheckCircleIcon sx={{ fontSize: '58px', color: '#00A43B' }} />
            </Box>
            <Typography mb="16px" variant="h3">
              <FormattedMessage id="order-preview-approval" />
            </Typography>
            <Typography>
              <FormattedMessage id="order-approved" values={{ jobName: order.jobName }} />
            </Typography>
          </>
        )}
        {error && (
          <>
            <Box mb="6px" display="flex">
              <CancelIcon sx={{ fontSize: '58px' }} color="error" />
            </Box>
            <Alert severity="error">
              <div>{error?.message}</div>
              <div>{error?.response?.data?.message}</div>
            </Alert>
          </>
        )}
      </Box>
    </div>
  )
}

export default OrderPreviewsApproval
