import React from 'react'
import { OrderPreview } from '../../types/task'
import noteImg from '../../assets/images/icons/note.svg'

interface Cprops {
  slide: OrderPreview
}

const ImageSlide: React.FC<Cprops> = ({ slide }) => {
  return <img className="slide-img" src={slide.image.url} alt="preview" />
}

const AudioSlide: React.FC<Cprops> = ({ slide }) => {
  return (
    <div className="slide-audio-wrap">
      <img className="slide-audio-icon" src={noteImg} alt="icon" />
      <audio className="slide-audio" controls>
        <source src={slide.image.url} type="audio/mpeg" />
      </audio>
    </div>
  )
}

const VideoSlide: React.FC<Cprops> = ({ slide }) => {
  return (
    <video className="slide-video" controls>
      <source src={slide.image.url} type="video/mp4" />
    </video>
  )
}

const Slide: React.FC<Cprops> = ({ slide }) => {
  const fileType: string = slide.name.split('.').reverse()[0]

  if (fileType.toLocaleLowerCase() === 'mp4') {
    return <VideoSlide slide={slide} />
  }

  if (fileType.toLocaleLowerCase() === 'mp3') {
    return <AudioSlide slide={slide} />
  }

  if (['svg', 'jpg', 'jpeg', 'png'].includes(fileType.toLocaleLowerCase())) {
    return <ImageSlide slide={slide} />
  }

  return null
}

export default Slide
