import React from 'react'
import { Button, IconButton, Tooltip, Typography } from '@mui/material'
import { IconDownload } from '@tabler/icons-react'
import { DropzoneRootProps } from 'react-dropzone'
import { getObjectUrl, shortenFileName } from '../../utils/tools'
import { FileExtensionImg } from '../../ui-component/FileExtensionImg'

import './style.scss'

interface Iprops {
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
  getInputProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
  acceptedFiles: File[]
  isDragActive: boolean
  title: string
  withResults?: boolean
  showButton?: boolean
  supportedFormats?: string[]
}
const FileUploadArea: React.FC<Iprops> = ({
  getRootProps,
  isDragActive,
  getInputProps,
  acceptedFiles,
  withResults,
  showButton = true,
  supportedFormats,
}) => {
  const rootProps = getRootProps({ className: 'dropArea' })
  const inputProps = getInputProps()

  return (
    <div className="FileUploadArea">
      <div className="dropzone">
        <div className="container">
          <div {...rootProps}>
            <input {...inputProps} />
            <div className="rectangel">
              {isDragActive ? (
                <p>Drop the files here</p>
              ) : (
                <div>
                  <Typography variant="h6">Drag & Drop files to upload</Typography>
                  {showButton && (
                    <>
                      <p>or</p>
                      <Button variant="contained" color="secondary" className="button">
                        Select files
                      </Button>
                    </>
                  )}
                  {supportedFormats && (
                    <p className="supportedFormats">
                      Supported formats: {supportedFormats.join(', ')}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="results">
            {withResults && acceptedFiles.length ? (
              <div className="ResultFileList">
                <Typography variant="h5" sx={{ marginBottom: '8px' }}>
                  Uploaded Files
                </Typography>
                {acceptedFiles.map((i) => (
                  <ResultFile key={`preview-${i.name}`} fileName={i.name} file={i} />
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FileUploadArea

interface IresultFile {
  fileName: string
  file: File
}

const ResultFile = ({ fileName, file }: IresultFile) => {
  return (
    <div className="ResultFile FileViewRow">
      <div>{<FileExtensionImg fileType={file.type} />}</div>
      <div
        className="iconAndName"
        onClick={() => {
          const url = getObjectUrl(file)
          window.open(url, '_blank')
        }}
      >
        <Tooltip title={fileName}>
          <Typography className="fileName">{shortenFileName(fileName)}</Typography>
        </Tooltip>
      </div>
      <div className="icons">
        <Tooltip title="Download">
          <IconButton
            onClick={() => {
              const url = URL.createObjectURL(file)
              window.open(url, '_blank')
            }}
            className="iconOpenImage"
          >
            <IconDownload color="blue" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}
