import { FC } from 'react'

import FACEBOOK_IMG from '../../assets/images/icons/platforms/facebook.svg'
import YOUTUBE_IMG from '../../assets/images/icons/platforms/youtube.svg'
import TIKTOK_IMG from '../../assets/images/icons/platforms/tiktok.svg'
import INSTAGRAM_IMG from '../../assets/images/icons/platforms/instagram.svg'
import LINKEDIN_IMG from '../../assets/images/icons/platforms/linkedin.svg'

interface PlatformImageProps {
  className?: string
  platform: 'facebook' | 'instagram' | 'linkeding' | 'youtube' | 'tiktok'
}

const PLATFORM_ICON_MAP = {
  facebook: FACEBOOK_IMG,
  tiktok: TIKTOK_IMG,
  youtube: YOUTUBE_IMG,
  instagram: INSTAGRAM_IMG,
  linkeding: LINKEDIN_IMG,
}

export const PlatformImage: FC<PlatformImageProps> = ({ platform, ...rest }) => {
  return (
    <img
      src={PLATFORM_ICON_MAP[platform]}
      alt={`${platform} icon`}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null // prevents looping
        currentTarget.src = 'image_path_here'
      }}
      {...rest}
    />
  )
}
