import React, { useState } from 'react'
import { Tooltip, IconButton } from '@mui/material'
import { IconCheck, IconCopy } from '@tabler/icons-react'

interface Cprops {
  str: string
}

const CopyLinkBtn: React.FC<Cprops> = ({ str }) => {
  const [isCopied, setIsCopied] = useState(false)

  const onClick = () => {
    navigator.clipboard.writeText(`${window.location.origin}${str}`)
    setIsCopied(true)
  }
  return (
    <Tooltip title={!isCopied ? 'Copy Preview All Link' : 'Copied'}>
      <IconButton size="small" onClick={onClick}>
        {!isCopied && <IconCopy width={18} height={18} />}
        {isCopied && <IconCheck width={18} height={18} />}
      </IconButton>
    </Tooltip>
  )
}

export default CopyLinkBtn
