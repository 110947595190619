import { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import TransactionMailModal from './TransactionMailModal'

const JobDetailsMenu = ({ order }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [openModal, setOpenModal] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleOpenClick = () => {
    handleClose()
    setOpenModal(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        size="small"
        id="JobDetailsMenuBtn"
        aria-controls={open ? 'JobDetailsMenu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        id="JobDetailsMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'JobDetailsMenuBtn',
        }}
      >
        <MenuItem onClick={handleOpenClick}>Transactional Mail</MenuItem>
      </Menu>
      {openModal && (
        <TransactionMailModal open={openModal} setOpen={setOpenModal} orderId={order?.id} />
      )}
    </>
  )
}

export default JobDetailsMenu
