import { TableTypes } from './components'

export const COLUMNS: TableTypes.Column[] = [
  {
    id: 'name',
    name: 'Name',
  },
  {
    id: 'clicks',
    name: 'Clicks',
  },
  {
    id: 'link-clicks',
    name: 'Link Clicks',
  },
  {
    id: 'link-ctr',
    name: 'Link CTR',
  },
  {
    id: 'impressions',
    name: 'Impressions',
  },
  {
    id: 'ctr',
    name: 'CTR',
  },
  {
    id: 'frequency',
    name: 'Frequency',
  },
  {
    id: 'devices',
    name: 'Devices',
  },
  {
    id: 'playback-time',
    name: 'Average Playback Time',
  },
]
