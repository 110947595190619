import { Typography } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import dayjs from 'dayjs'
import classes from './style.module.scss'

export default function Timeline({ data }) {
  return (
    <>
      <Typography variant="h4">Timeline</Typography>
      {data.length ? (
        <div className={classes.timeline}>
          <div className={classes.timelineLine}>
            <CircleIcon className={`${classes.current} ${classes.dot}`} />
            <div className={classes.line} />
            <CircleIcon
              className={classes.dot}
              style={{ marginLeft: '10px', position: 'relative', top: '-10px' }}
            />
          </div>
          <div className={classes.activity}>
            <p>Lead updated:</p>
            <p>{dayjs(new Date()).format('DD.MM.YYYY HH:MM')}</p>
          </div>
        </div>
      ) : (
        'There are no activities.'
      )}
    </>
  )
}
