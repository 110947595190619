import { Box, Link, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

const CreateOrderDenied = () => {
  return (
    <Box padding="32px">
      <Typography variant="h2" component="h2" textAlign="center">
        <FormattedMessage id="coming-soon-until" />{' '}
        <Link href="mailto:ap_offers@aschenputtel.agency">ap_offers@aschenputtel.agency</Link>
      </Typography>
    </Box>
  )
}

export default CreateOrderDenied
