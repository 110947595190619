import React from 'react'
import mp3 from '../../assets/images/icons/files/MP3.svg'
import mp4 from '../../assets/images/icons/files/MP4.svg'
import { OrderPreview } from '../../types/task'

interface Cprops {
  thumb: OrderPreview
}

const ImageThumb: React.FC<Cprops> = ({ thumb }) => {
  return <img className="image" src={thumb.image.url} alt="preview" />
}

const VideoThumb = () => {
  return <img className="icon" src={mp4} alt="icon" />
}

const AudioThumb = () => {
  return <img className="icon" src={mp3} alt="icon" />
}

const Thumb: React.FC<Cprops> = ({ thumb }) => {
  const fileType: string = thumb.name.split('.').reverse()[0]

  if (fileType.toLocaleLowerCase() === 'mp4') {
    return <VideoThumb />
  }

  if (fileType.toLocaleLowerCase() === 'mp3') {
    return <AudioThumb />
  }

  if (['svg', 'jpg', 'jpeg', 'png'].includes(fileType.toLocaleLowerCase())) {
    return <ImageThumb thumb={thumb} />
  }

  return null
}

export default Thumb
