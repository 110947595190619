/* eslint-disable no-restricted-syntax */
import React, { useCallback } from 'react'
import { AreaChart, ResponsiveContainer, Area } from 'recharts'
import dayjs, { OpUnitType } from 'dayjs'
import PlaceHolderRender from '../../../components/PlaceHolderRender'
import './styles.scss'

interface Props {
  name: string
  value: number
}

const LeadsPerCampaign = ({ leads, campaignRows }) => {
  const [graph, setGraph] = React.useState<Props[]>()

  const countItemsByDay = useCallback(
    (items) => {
      if (!items) {
        return
      }

      const countsByMonth: Props[] = []

      for (const item of items) {
        const date = new Date(item.createdTime)
        const day = date.toLocaleString('de-DE', { day: 'numeric' })

        const index = countsByMonth.length
          ? countsByMonth.findIndex((days) => days.name === day)
          : -1

        if (index >= 0) {
          countsByMonth[index] = {
            ...countsByMonth[index],
            value: countsByMonth[index].value + 1,
          }
        } else {
          countsByMonth.push({ name: day, value: 1 })
        }
      }

      return countsByMonth.map((item) => ({
        ...item,
        value: Math.ceil((item.value / campaignRows.length) * 10),
      }))
    },
    [campaignRows.length]
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function filterDate(items: any, filterBy: OpUnitType | undefined) {
    const currentDate = dayjs()
    const filteredItems = items?.filter((item) => {
      const itemDate = dayjs(item?.createdTime)
      return itemDate.isSame(currentDate, filterBy)
    })

    return filteredItems
  }

  React.useEffect(() => {
    setGraph(countItemsByDay(filterDate(leads, 'month')))
  }, [countItemsByDay, leads])

  return (
    <>
      {graph?.length ? (
        <div className="leadPerCampaing">
          <ResponsiveContainer width="100%" height={140}>
            <AreaChart
              width={200}
              height={200}
              data={graph}
              margin={{
                top: 50,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient
                  id="gradient"
                  x1="164"
                  y1="3"
                  x2="168"
                  y2="154"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#220D77" stopOpacity="0.8" />
                  <stop offset="1" stopColor="#220D77" stopOpacity="0.1" />
                </linearGradient>
              </defs>

              <Area type="monotone" dataKey="value" stroke="#220D77" fill="url(#gradient)" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <PlaceHolderRender box text="No open tasks" />
      )}
    </>
  )
}

export default LeadsPerCampaign
