import { useLocation, useNavigate } from 'react-router-dom'
import {
  Button,
  Container,
  IconButton,
  ListItemButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { IconPlus } from '@tabler/icons-react'
import { FC, useEffect, useState } from 'react'
import axios, { AxiosError } from 'axios'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import './style.scss'
import useConfig from '../../hooks/useConfig'
import { store, useDispatch } from '../../store/store'
import { fixImgSrc, getObjectUrl } from '../../utils/tools'
import queries from '../../graphQL/query'
import FormErrorsModal from '../../ui-component/FormErrorsModal'
import SuccessModal from '../../ui-component/SuccessModal'
import Loading from '../../ui-component/loading/Loading'
import { setUserInfo } from '../../store/slices/user'
import { Security } from './components'
import axiosInstance from '../../utils/axiosInstance'
import { setOpenCustomerModal } from '../../store/slices/general'
import { USER_ROLES } from '../../constants'

const validationSchemaProfile = yup.object({
  email: yup.string().email('Invalid email address'),
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  profileImage: yup.object().nullable(),
})

const UserProfile: FC = () => {
  const intl = useIntl()
  const [isProfileSelected, setIsProfileSelected] = useState(true)
  const [isSecuritySelected, setIsSecuritySelected] = useState(false)
  const [isBusinessInformationSected, setIsBusinessInformationSelected] = useState(false)
  const [profileImageFile, setProfileImageFile] = useState<File | null>(null)
  const [errorsModalOpen, setErrorsModalOpen] = useState(false)
  const [formikErrors, setFormikErrors] = useState<string[]>([])
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [customerId, setCustomerId] = useState('')
  const [is404, setIs404] = useState(false)

  const dispatch = useDispatch()
  const theme = useTheme()
  const { borderRadius } = useConfig()
  const location = useLocation()
  const navigation = useNavigate()

  const listItemSx = {
    borderRadius: `${borderRadius}px`,
    mb: 0.5,
    alignItems: 'flex-start',
    py: 2.5,
    pl: 2,
    '&:hover': {
      background: theme.palette.secondary.light,
    },
  }

  const { userInfo } = store.getState().user
  const { id, email, firstName, lastName, profileImage, role } = userInfo

  const shouldShowBusinessInformation = role === USER_ROLES.EXTERNAL_DIRECT_CUSTOMER.value

  useEffect(() => {
    if (shouldShowBusinessInformation) {
      axiosInstance('businessInformation')
        .then((res) => {
          const { id } = res.data
          setCustomerId(id)
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 404) {
            setIs404(true)
            setErrorsModalOpen(true)
          }
        })
    }
  }, [navigation, shouldShowBusinessInformation])

  useEffect(() => {
    const pathEnding = location.pathname.split('/').slice(-1)[0]

    switch (pathEnding) {
      case 'security':
        setIsSecuritySelected(true)
        setIsProfileSelected(false)
        setIsBusinessInformationSelected(false)
        break
      case 'businessInformation':
        setIsBusinessInformationSelected(true)
        setIsSecuritySelected(false)
        setIsProfileSelected(false)
        break
      default:
        setIsProfileSelected(true)
        setIsSecuritySelected(false)
        setIsBusinessInformationSelected(false)
        break
    }
  }, [location.pathname])

  useEffect(() => {
    if (profileImage?.url) {
      fetch(fixImgSrc(profileImage.url))
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], 'profileImage', { type: 'image/png' })
          setProfileImageFile(file)
        })
    }
  }, [profileImage])

  const [mutateFunction, { loading: loadingMutation, error: mutationError }] = useMutation(
    queries.updateAppUser
  )

  useEffect(() => {
    if (mutationError) {
      setFormikErrors(mutationError.graphQLErrors.map((i) => i.message))
      setErrorsModalOpen(true)
    }
  }, [mutationError])

  useEffect(() => {
    if (shouldShowBusinessInformation && isBusinessInformationSected && customerId) {
      dispatch(setOpenCustomerModal())
      window.history.pushState({}, '', `/customers/${customerId}`)
    }
  }, [
    customerId,
    dispatch,
    id,
    isBusinessInformationSected,
    navigation,
    shouldShowBusinessInformation,
  ])

  const formikProfile = useFormik({
    initialValues: {
      email,
      firstName,
      lastName,
      profileImage,
    },
    validationSchema: validationSchemaProfile,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const { email, firstName, lastName, profileImage } = values
      const variables = {
        email,
        firstName,
        lastName,
        profileImage,
      }

      const isEmailChanged = email !== userInfo.email
      if (isEmailChanged) {
        const url = `${process.env.REACT_APP_SERVER_URL}/changeEmail`
        const changeEmailResult = await axios.post(url, {
          email,
        })
        if (setErrorsWithResponse(changeEmailResult.data)) return
      }

      const res = await mutateFunction({ variables: { data: variables, where: { id } } })

      if (res.data?.updateAppUser?.email) {
        setSuccessModalOpen(true)
        dispatch(setUserInfo({ ...res.data.updateAppUser, id }))
      }
    },
  })

  async function handleSubmitProfile(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const errors = Object.values(formikProfile.errors)
    if (errors.length) {
      setErrorsModalOpen(true)
      setFormikErrors(errors as string[])
      return
    }
    if (profileImageFile)
      await formikProfile.setFieldValue('profileImage', { upload: profileImageFile })

    formikProfile.handleSubmit()
  }

  if (is404)
    return (
      <FormErrorsModal
        formikErrors={[intl.formatMessage({ id: 'business-information-not-found' })]}
        errorsModalOpen={errorsModalOpen}
        setErrorsModalOpen={setErrorsModalOpen}
      />
    )

  if (email) {
    return (
      <div className="UserProfile">
        <div style={{ backgroundColor: theme.palette.background.paper }}>
          <div className="title">
            <Typography variant="h4">
              <FormattedMessage id="account-setting" />
            </Typography>
          </div>
          <div className="sideAndfields">
            <Container className="side">
              <ListItemButton
                onClick={() => {
                  setIsProfileSelected(true)
                  setIsSecuritySelected(false)
                  setIsBusinessInformationSelected(false)
                  navigation('/user/profile')
                }}
                sx={listItemSx}
                selected={isProfileSelected}
              >
                <Typography color="primary" variant="h5">
                  <FormattedMessage id="userProfile" />
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setIsSecuritySelected(true)
                  setIsProfileSelected(false)
                  setIsBusinessInformationSelected(false)
                  navigation('/user/security')
                }}
                sx={listItemSx}
                selected={isSecuritySelected}
              >
                <Typography color="primary" variant="h5">
                  <FormattedMessage id="userSecurity" />
                </Typography>
              </ListItemButton>
              {shouldShowBusinessInformation && (
                <ListItemButton
                  onClick={() => {
                    setIsBusinessInformationSelected(true)
                    setIsProfileSelected(false)
                    setIsSecuritySelected(false)
                    // navigation('/user/businessInformation')
                  }}
                  sx={listItemSx}
                  selected={isBusinessInformationSected}
                >
                  <Typography color="primary" variant="h5">
                    <FormattedMessage id="business-information" />
                  </Typography>
                </ListItemButton>
              )}
            </Container>
            {isProfileSelected && (
              <div className="profile">{loadingMutation ? <Loading /> : ProfileData()}</div>
            )}
            {isSecuritySelected && <Security />}
            {/* {shouldShowBusinessInformation && isBusinessInformationSected && customerId && (
              <ReadEditCustomer />
            )} */}
          </div>
        </div>
        <FormErrorsModal
          errorsModalOpen={errorsModalOpen}
          formikErrors={formikErrors}
          setErrorsModalOpen={setErrorsModalOpen}
        />
        <SuccessModal
          successModalOpen={successModalOpen}
          handleClose={() => setSuccessModalOpen(false)}
          setSuccessModalOpen={setSuccessModalOpen}
          message={intl.formatMessage({ id: 'edited-successfully' })}
        />
      </div>
    )
  }

  function ProfileData(): React.ReactNode {
    return (
      <Container className="fields">
        <form onSubmit={(e) => handleSubmitProfile(e)}>
          <Container disableGutters className="image">
            <div
              className="circleButton"
              // open input file on click
              onClick={() => {
                const input = document.querySelector('.userProfileInputFile') as HTMLInputElement
                input.click()
              }}
            >
              {profileImageFile ? (
                <img alt="profile" className="circleButton" src={getObjectUrl(profileImageFile)} />
              ) : (
                <Tooltip
                  className="circleButton"
                  title={intl.formatMessage({ id: 'upload-image' })}
                >
                  <IconButton>
                    <IconPlus />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div className="buttonWithCaption">
              <Button className="button" size="small" variant="contained" component="label">
                <FormattedMessage id="upload-image" />
                <input
                  onChange={(e) => {
                    if (e.target.files) {
                      setProfileImageFile(e.target.files[0])
                    }
                  }}
                  type="file"
                  hidden
                  className="userProfileInputFile"
                />
              </Button>
              <Typography className="caption" variant="caption">
                <FormattedMessage id="upload-change-image" />
              </Typography>
            </div>
          </Container>
          <div className="metadata">
            <TextField
              id="email"
              name="email"
              onChange={formikProfile.handleChange}
              defaultValue={formikProfile.values.email}
              fullWidth
              label={intl.formatMessage({ id: 'email' })}
              variant="outlined"
            />
            <div className="firstLastName">
              <TextField
                onChange={formikProfile.handleChange}
                defaultValue={formikProfile.values.firstName}
                id="firstName"
                name="firstName"
                fullWidth
                label={intl.formatMessage({ id: 'first-name' })}
              />
              <TextField
                onChange={formikProfile.handleChange}
                defaultValue={formikProfile.values.lastName}
                fullWidth
                label={intl.formatMessage({ id: 'last-name' })}
                id="lastName"
                name="lastName"
              />
            </div>
          </div>
          <Container className="submit">
            <Button type="submit" variant="contained">
              <FormattedMessage id="change-details" />
            </Button>
          </Container>
        </form>
      </Container>
    )
  }

  function setErrorsWithResponse(changeEmailResult: string) {
    switch (changeEmailResult) {
      case 'OK':
        break
      case 'email already exists':
        setFormikErrors([changeEmailResult])
        setErrorsModalOpen(true)
        return changeEmailResult
      case 'Error: Cannot update email or password of a user who signed up using third party login.':
        setFormikErrors([changeEmailResult])
        setErrorsModalOpen(true)
        return true
      default:
        setFormikErrors(['Unknown error'])
        setErrorsModalOpen(true)
        return true
    }
    return null
  }

  return null
}

export default UserProfile
