import { Suspense, ElementType } from 'react'
import Loader from './Loader'
import { TunsolvedAny } from '../types/unsolved'

// project imports

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

// eslint-disable-next-line react/display-name
const Loadable = (Component: ElementType) => (props: TunsolvedAny) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  )

export default Loadable
