import { IconButton, Toolbar, Tooltip, styled, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { IconFilter, IconBackspace, IconCirclePlus } from '@tabler/icons-react'
import SearchIcon from '@mui/icons-material/Search'
import { useDebounce } from 'use-debounce'
import { OrderFilterDrawer } from '../orderFilterDrawer/OrderFilterDrawer'
import { useDispatch, useSelector } from '../../../../store/store'
import { dialogToggle } from '../../../../store/slices/dialog'
import { Search, StyledInputBase } from '../../../../config'
import { setordersFilterQuerycustomerSearch } from '../../../../store/slices/filters/filters'

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const RemoveSearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const OrderEnhancedTableToolbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { ordersFilterQuerycustomerSearch } = useSelector((state) => state.filters)
  const dispatch = useDispatch()
  const theme = useTheme()
  const [searchValue, setSearchValue] = useState(ordersFilterQuerycustomerSearch || '')
  const [debouncedSearchValue] = useDebounce(searchValue, 300)

  useEffect(() => {
    dispatch(setordersFilterQuerycustomerSearch(debouncedSearchValue))
  }, [debouncedSearchValue, dispatch])

  const handleOpen = () => {
    dispatch(dialogToggle(true))
  }

  return (
    <>
      <Toolbar>
        <Search sx={{ flex: '1 1 100%', display: 'flex', alignContent: 'baseline' }}>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            autoFocus
          />
          {ordersFilterQuerycustomerSearch && (
            <RemoveSearchIconWrapper
              onClick={() => {
                dispatch(setordersFilterQuerycustomerSearch(''))
              }}
            >
              <IconBackspace size="24px" className="removeIcon" />
            </RemoveSearchIconWrapper>
          )}
        </Search>
        <Tooltip onClick={() => setDrawerOpen(!drawerOpen)} title="Filter list">
          <IconButton>
            <IconFilter size="20px" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add new" onClick={handleOpen}>
          <IconButton>
            <IconCirclePlus
              stroke="none"
              size="35px"
              fill={theme.palette.primary.main}
              color={theme.palette.primary.light}
            />
          </IconButton>
        </Tooltip>

        <OrderFilterDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      </Toolbar>
    </>
  )
}
