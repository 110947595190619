import { TgraphQlFile } from '../../../../types'
import { IdataSourceItem, IfileItem } from '../../../../types/other'
import { Iorder } from '../../../../types/task'
import { fixImgSrc } from '../../../../utils/tools'

type Tpreview = { id: string; name: string; image: { url: string; id: string } }

export async function handleFileDeletions(
  previewIdsToDelete: string[],
  deletePreviewImage: (id: string) => Promise<void>,
  deletePreviewAudio: (id: string) => Promise<void>,
  deletePreviewVideo: (id: string) => Promise<void>,
  assetsIdsToDelete: string[],
  deleteAssetImage: (id: string) => Promise<void>,
  invoiceIdsToDelete: string[],
  deleteInvoiceFile: (id: string) => Promise<void>,
  videoAssetsIdsToDelete: string[],
  deleteFileAssetFile: (id: string) => Promise<void>,
  fetchedOrderDetails
) {
  const previewImgIdsToDelete: string[] = []
  const previewAudioIdsToDelete: string[] = []
  const previewVideoIdsToDelete: string[] = []

  previewIdsToDelete.forEach((id) => {
    if (fetchedOrderDetails.task.previews.find((file) => file.id === id)) {
      previewImgIdsToDelete.push(id)
    }
    if (fetchedOrderDetails.task.previewsAudio.find((file) => file.id === id)) {
      previewAudioIdsToDelete.push(id)
    }
    if (fetchedOrderDetails.task.previewsVideo.find((file) => file.id === id)) {
      previewVideoIdsToDelete.push(id)
    }
  })

  if (previewImgIdsToDelete?.length)
    await Promise.all(previewImgIdsToDelete.map((id) => deletePreviewImage(id)))

  if (previewAudioIdsToDelete?.length)
    await Promise.all(previewAudioIdsToDelete.map((id) => deletePreviewAudio(id)))

  if (previewVideoIdsToDelete?.length)
    await Promise.all(previewVideoIdsToDelete.map((id) => deletePreviewVideo(id)))

  if (assetsIdsToDelete?.length)
    await Promise.all(assetsIdsToDelete.map((id) => deleteAssetImage(id)))

  if (invoiceIdsToDelete?.length)
    await Promise.all(invoiceIdsToDelete.map((id) => deleteInvoiceFile(id)))

  if (videoAssetsIdsToDelete?.length)
    await Promise.all(videoAssetsIdsToDelete.map((id) => deleteFileAssetFile(id)))
}

export async function setFormikFileFields(
  previewFiles: IfileItem[],
  formik,
  assetsFiles: IfileItem[],
  invoiceFiles: IfileItem[],
  videoAssets: IfileItem[]
) {
  if (previewFiles) {
    const previews: IfileItem[] = []
    const previewsAudio: IfileItem[] = []
    const previewsVideo: IfileItem[] = []

    previewFiles.forEach((file) => {
      if (file.file?.type.includes('audio')) {
        previewsAudio.push(file)
      }
      if (file.file?.type.includes('video')) {
        previewsVideo.push(file)
      }
      if (file.file?.type.includes('image')) {
        previews.push(file)
      }
    })

    previews.length &&
      (await formik.setFieldValue('previews', {
        create: previews.map((i: IfileItem) => ({
          name: i.file.name,
          image: {
            upload: i.file,
          },
        })),
      }))

    previewsAudio.length &&
      (await formik.setFieldValue('previewsAudio', {
        create: previewsAudio.map((i: IfileItem) => ({
          name: i.file.name,
          image: {
            upload: i.file,
          },
        })),
      }))

    previewsVideo.length &&
      (await formik.setFieldValue('previewsVideo', {
        create: previewsVideo.map((i: IfileItem) => ({
          name: i.file.name,
          image: {
            upload: i.file,
          },
        })),
      }))
  }

  if (assetsFiles) {
    await formik.setFieldValue('imageAssets', {
      create: assetsFiles.map((i: IfileItem) => ({
        name: i.file.name,
        image: {
          upload: i.file,
        },
      })),
    })
  }

  if (invoiceFiles) {
    await formik.setFieldValue('invoiceFiles', {
      create: invoiceFiles.map((i: IfileItem) => ({
        name: i.file.name,
        file: {
          upload: i.file,
        },
      })),
    })
  }

  if (videoAssets) {
    await formik.setFieldValue('videoAssets', {
      create: videoAssets.map((i: IfileItem) => ({
        name: i.file.name,
        file: {
          upload: i.file,
        },
      })),
    })
  }
}

export function setFilesDataSources(
  order: Iorder,
  setPreviewsDataSources: React.Dispatch<React.SetStateAction<IdataSourceItem[]>>,
  setAssetsDataSources: React.Dispatch<React.SetStateAction<IdataSourceItem[]>>,
  setInvoiceDataSources: React.Dispatch<React.SetStateAction<IdataSourceItem[]>>,
  setVideoAssetsDataSources: React.Dispatch<React.SetStateAction<IdataSourceItem[]>>
) {
  const taskPreviews: Tpreview[] = [
    ...order.previews,
    ...order.previewsAudio,
    ...order.previewsVideo,
  ]

  if (taskPreviews?.length) {
    const previews = taskPreviews.map((i: Tpreview) => ({
      id: i?.id,
      url: fixImgSrc(i.image.url),
      name: i.name,
    }))
    setPreviewsDataSources(previews)
  }

  if (order.imageAssets?.length) {
    const assets = order.imageAssets.map((i: Tpreview) => ({
      id: i?.id,
      url: fixImgSrc(i.image.url),
      name: i.name,
    }))
    setAssetsDataSources(assets)
  }

  if (order.invoices?.length) {
    const invoices = order.invoices.map((i: TgraphQlFile) => ({
      id: i?.id,
      url: fixImgSrc(i.file.url),
      name: i.name,
    }))
    setInvoiceDataSources(invoices)
  }

  if (order.videoAssets?.length) {
    const videoAssets = order.videoAssets.map((i: TgraphQlFile) => ({
      id: i?.id,
      url: fixImgSrc(i.file.url),
      name: i?.name,
    }))
    setVideoAssetsDataSources(videoAssets)
  }
}
