import { Ipermission } from 'common-recruspin-codebase/types/permission'
import { createSlice } from '@reduxjs/toolkit'

type TuserRoles =
  | 'admin'
  | 'internalUser'
  | 'internalUserLimited'
  | 'externalDirectCustomer'
  | 'externalReseller'
  | 'externalPartnerCustomer'
  | 'externalUserWhiteLabel'
  | undefined

interface IinitialState {
  userInfo: {
    id: string
    email: string
    firstName: string
    lastName: string
    profileImage: {
      url: string
    }
    role: TuserRoles
  }
  permissions?: Ipermission
}

const initialState: IinitialState = {
  userInfo: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    profileImage: {
      url: '',
    },
    role: undefined,
  },
  permissions: undefined,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.userInfo = action.payload
    },
    setPermissions(state, action) {
      state.permissions = action.payload
    },
  },
})

export const { setUserInfo, setPermissions } = userSlice.actions
export default userSlice.reducer
