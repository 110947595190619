import React, { useCallback, useState } from 'react'
import { IconButton, Tooltip, Typography } from '@mui/material'
import { IconCheck, IconCopy, IconDownload, IconX } from '@tabler/icons-react'
import { fixImgSrc } from '../../utils/tools'
import { FileExtensionImg } from '../../ui-component/FileExtensionImg'
import './style.scss'
import FilePreviewModal from './FilePreviewModal'

interface IFileViewRowProps {
  fileSource: string
  fileName: string
  onRemove?: () => void
  hasPreview?: boolean
  fixSrc?: boolean
}

const TooltipIconButton: React.FC<{
  title: string
  onClick: () => void
  children: React.ReactNode
  fixSrc?: boolean
}> = ({ title, onClick, children }) => (
  <Tooltip title={title}>
    <IconButton onClick={onClick}>{children}</IconButton>
  </Tooltip>
)

export const FileViewRow: React.FC<IFileViewRowProps> = ({
  fileSource,
  onRemove,
  fileName,
  hasPreview,
  fixSrc = true,
}) => {
  const [linkCopied, setLinkCopied] = useState(false)
  const [open, setOpen] = useState(false)
  const fixedFileSource = fixSrc ? fixImgSrc(fileSource) : fileSource

  const handleOpenImage = useCallback(() => {
    setOpen(true)
  }, [])
  const truncateFileName = useCallback(() => {
    const name = fileName
    if (name.length > 20) {
      return `${name.substring(0, 20)}...`
    }
    return name
  }, [fileName])

  const handleLinkCopy = useCallback(() => {
    navigator.clipboard.writeText(fixedFileSource)
    setLinkCopied(true)
  }, [fixedFileSource])
  const handleDownload = useCallback(
    () => window.open(fixedFileSource, '_blank'),
    [fixedFileSource]
  )

  const fileType = fileName.split('.').reverse()[0]

  return (
    <div className="FileViewRow">
      <div className="iconAndName">
        {hasPreview ? (
          <TooltipIconButton title="Preview" onClick={handleOpenImage}>
            <FileExtensionImg fileType={fileType} />
          </TooltipIconButton>
        ) : (
          <FileExtensionImg fileType={fileType} />
        )}
        <Typography
          onClick={() => {
            if (hasPreview) handleOpenImage()
          }}
          className="fileName"
        >
          {truncateFileName() || 'Image file'}
        </Typography>
      </div>
      <div className="icons">
        {linkCopied ? (
          <TooltipIconButton title="Copied" onClick={handleLinkCopy}>
            <IconCheck />
          </TooltipIconButton>
        ) : (
          <TooltipIconButton title="Copy to clipboard" onClick={handleLinkCopy}>
            <IconCopy className="iconCopy" />
          </TooltipIconButton>
        )}
        <TooltipIconButton title="Download" onClick={handleDownload}>
          <IconDownload color="blue" />
        </TooltipIconButton>
        {onRemove && (
          <TooltipIconButton title="Remove" onClick={onRemove}>
            <IconX color="red" className="removeIcon" />
          </TooltipIconButton>
        )}
      </div>
      <FilePreviewModal
        open={open}
        setOpen={setOpen}
        fixedFileSource={fixedFileSource}
        fileType={fileType}
      />
    </div>
  )
}
