import { FC } from 'react'
import {
  LinearProgress,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { PAGE } from '../../../../../../constants'
import { StyledTableRow } from './Table.styles'
import { PlatformImage } from '../../../../../../ui-component'

import { COLUMNS } from './Tablet.constants'
import { Campaign } from '../../../../../../types/campaign'
import { AnalyticsFilterDrawer } from './analyticsFilterDrawer/AnalyticsFilterDrawer'

const Table: FC<{
  data: Campaign[]
  isLoading: boolean
  drawerOpen: boolean
  setDrawerOpen: (drawerOpen: boolean) => void
}> = ({ data, isLoading, drawerOpen, setDrawerOpen }) => {
  const navigate = useNavigate()

  const handleOpenCampaign = (id: string) => navigate(`${PAGE.ANALYTICS.PATH}/dashboard/${id}`)

  return (
    <>
      <AnalyticsFilterDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <MuiTable aria-label="Campaign table">
        <TableHead>
          <TableRow>
            {COLUMNS?.map(({ id, name }) => (
              <TableCell key={id}>{name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow key="loader">
              <TableCell colSpan={10}>
                <LinearProgress style={{ width: '100%' }} />
              </TableCell>
            </TableRow>
          )}
          {!!data.length &&
            data?.map(({ id, name, createdTime }) => (
              <StyledTableRow key={id} onClick={() => handleOpenCampaign(id)}>
                <TableCell component="th" scope="row">
                  <PlatformImage platform="facebook" />
                </TableCell>
                <TableCell>{name}</TableCell>
                <TableCell>{dayjs(createdTime).format('DD/MM/YYYY')}</TableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </MuiTable>
    </>
  )
}

export default Table
