import { TableCell } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from '../../../../../store/store'
import { setSelected } from '../../../../../store/slices/table'

type Props = {
  id: string
  children: React.ReactNode
  action?: boolean
  checkbox?: boolean
  handleClick?: () => void
}

const EnhancedTableCell = ({
  id = '',
  children,
  checkbox = false,
  action = false,
  handleClick = () => {},
}: Props) => {
  const { selected } = useSelector((state) => state.table)
  const padding = checkbox ? 'checkbox' : 'normal'
  const dispatch = useDispatch()

  const onClick = () => {
    if (checkbox) {
      const selectedIndex = selected.indexOf(id)
      let newSelected: readonly string[] = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        )
      }
      dispatch(setSelected(newSelected))
    }

    if (action) {
      return
    }

    return handleClick()
  }

  return (
    <TableCell align="left" padding={padding} onClick={onClick} style={{ verticalAlign: 'middle' }}>
      {children}
    </TableCell>
  )
}

export default EnhancedTableCell
