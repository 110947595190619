import { isEndCustomer, USER_ROLES } from '../../../../../constants'
import { store } from '../../../../../store/store'
import { HeadCell } from '../../../../../types'
import { hasPermission } from '../../../../../utils/tools'

export const headCellsOrders: HeadCell[] = [
  {
    id: 'customerName',
    numeric: false,
    label: 'customer',
  },
  {
    id: 'connectedReseller',
    numeric: false,
    label: 'reseller',
  },
  {
    id: 'jobName',
    numeric: false,
    label: 'job-title',
  },
  {
    id: 'status',
    numeric: false,
    label: 'status',
  },
  {
    id: 'place',
    numeric: false,
    label: 'place',
  },
  {
    id: 'createdAt',
    numeric: false,
    label: 'date-created',
  },
  {
    id: 'assignee',
    numeric: false,
    label: 'assignee',
  },
  {
    id: 'preview',
    numeric: false,
    label: 'preview',
  },
  {
    id: 'channel',
    numeric: false,
    label: 'portal',
  },
  {
    id: 'budget',
    numeric: false,
    label: 'budget',
  },
  {
    id: 'price',
    numeric: false,
    label: 'price',
  },
  {
    id: 'runtime',
    numeric: false,
    label: 'runtime',
  },
]

store.subscribe(() => {
  const { permissions, userInfo } = store.getState().user
  const { isOrderTableOverview } = store.getState().general

  const { role } = userInfo

  const permission = hasPermission(permissions?.orderDetailsFields)
  if (permissions) {
    const shouldNotShowCustomer =
      isEndCustomer(role) || isOrderTableOverview || !permission('customer')

    shouldNotShowCustomer && delete headCellsOrders[0] && delete headCellsOrders[1]

    if (role === USER_ROLES.EXTERNAL_RESELLER.value) delete headCellsOrders[1]

    if (isEndCustomer(role)) delete headCellsOrders[1]

    !permission('jobName') && delete headCellsOrders[2]
    !permission('status') && delete headCellsOrders[3]
    !permission('place') && delete headCellsOrders[4]
    !permission('createdAt') && delete headCellsOrders[5]
    !permission('assignee') && delete headCellsOrders[6]
    !permission('channel') && delete headCellsOrders[8]
    !permission('budget') && delete headCellsOrders[9]
    !permission('price') && delete headCellsOrders[10]
    !permission('runtime') && delete headCellsOrders[11]
  }
})
