import * as React from 'react'
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts'
import './styles.scss'
import PlaceHolderRender from '../../../components/PlaceHolderRender'
import { OrdersProps } from '../InternalDashboard'

interface TasksChartProps {
  data: OrdersProps
}
interface ChartItem {
  name: string
  value: number
}

const TasksChart: React.FunctionComponent<TasksChartProps> = ({ data }) => {
  const {
    todoOrders,
    inProgressOrders,
    materialCompleteOrders,
    waitingForApprovalOrders,
    approvedOrders,
    activeOrders,
    createInvoice,
  } = data
  const todoTasksLength = todoOrders.length
  const inProgressTasksLength = inProgressOrders.length
  const materialCompleteTasksLength = materialCompleteOrders.length
  const approvedTasksLength = approvedOrders.length
  const activeTasksLength = activeOrders.length
  const waitingForApprovalTasksLength = waitingForApprovalOrders.length
  const createInvoiceLength = createInvoice.length

  const [chartData, setChartData] = React.useState<ChartItem[]>()
  const colors = ['#220D77', '#FFC107', '#FFBAAE', '#D84315', '#00A43B', '#1e88e5', '#697586']
  const newArray = Array.from({ length: 5 }, (_, index) => index * 2)

  React.useEffect(() => {
    const chartData = [
      { name: 'To do', value: todoTasksLength },
      { name: 'In progress', value: inProgressTasksLength },
      { name: 'Material complete', value: materialCompleteTasksLength },
      { name: 'Waiting for approval', value: waitingForApprovalTasksLength },
      { name: 'Approved', value: approvedTasksLength },
      { name: 'Active', value: activeTasksLength },
      { name: 'Create invoice', value: createInvoiceLength },
    ]

    setChartData(chartData)
  }, [
    todoTasksLength,
    inProgressTasksLength,
    materialCompleteTasksLength,
    approvedTasksLength,
    activeTasksLength,
    createInvoiceLength,
    waitingForApprovalTasksLength,
  ])

  return (
    <div className="piechartWrapper">
      {data.size > 0 ? (
        <>
          <ResponsiveContainer width="100%" aspect={1.7}>
            <PieChart>
              <Pie
                data={chartData}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={70}
                outerRadius={90}
                fill="#8884d8"
                paddingAngle={5}
              >
                {chartData?.map((entry, index) => (
                  <Cell key={index} fill={colors[index % colors.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          <div className="pieChartLegend">
            {chartData?.map(({ name, value }, index) => {
              return (
                <div className="colorItem" key={name}>
                  <div className="colorWrapper">
                    <div
                      className="colorCube"
                      style={{
                        background: colors[index],
                        height: 12,
                        width: 12,
                        borderRadius: 2,
                        marginRight: 8,
                      }}
                    />
                    <p className="name">{name}</p>
                  </div>
                  <p className="number">{value}</p>
                </div>
              )
            })}
          </div>
        </>
      ) : (
        <>
          <div className="piechartWrapperPlaceholder">
            <div
              style={{
                border: '20px solid #F5F5F5',
                borderRadius: '200px',
                height: '200px',
                width: '200px',
                position: 'relative',
              }}
            />
            <p style={{ position: 'absolute' }}>N/A</p>
          </div>
          <div className="pieChartLegend">
            {newArray.map((i, index) => {
              return (
                <div className="colorItem" key={index}>
                  <div className="colorWrapper">
                    <PlaceHolderRender width="12px" height="12px" />
                    <PlaceHolderRender width="30px" height="12px" marginLeft="6px" />
                  </div>
                  <PlaceHolderRender width="12px" height="12px" />
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default TasksChart
